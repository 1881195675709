import React from 'react'
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom'
import { Box, TextProps, Text, Flex } from 'rebass'
import {
  GetCurrentUserQueryHookResult,
  Identification,
  Tag as TagType
} from '../../types/queries'
type UserIdentification = GetCurrentUserQueryHookResult['data']['currentUser']['items'][0]['identification']

export const H1 = (props) => {
  return (
    <Text
      fontSize={[4, 5]}
      display="block"
      sx={{ fontFamily: 'display' }}
      {...props}
    />
  )
}

export const H2 = (props) => {
  return (
    <Text
      fontSize={[3, 4]}
      display="block"
      fontFamily="display"
      fontWeight="bold"
      {...props}
    />
  )
}

export const H3: React.FC<TextProps & { css?: any }> = (props) => {
  return (
    <Text
      fontSize={3}
      display="block"
      fontFamily="display"
      {...props}
    />
  )
}

export const H4: React.FC<TextProps & { css?: any }> = (props) => {
  return (
    <Text
      fontSize={[2, 3]}
      display="block"
      fontFamily="body"
      {...props}
    />
  )
}

export const PageTitle = (props) => {
  return (
    <H2
      px={3}
      py={2}
      {...props}
    />
  )
}

export const SectionTitle = (props) => {
  return (
    <Text
      fontSize={[2, 3]}
      py={2}
      px={3}
      mx={0}
      fontFamily="display"
      display="block"
      fontWeight="bold"
      {...props}
    />
  )
}


type CardTitleProps = {
  children: string
  renderContent?: () => React.ReactChild | React.ReactChildren | void
}
export const CardTitle: React.FC<CardTitleProps> = ({ renderContent, ...props}) => {
  return (
    <Flex sx={{
      // '&:not(:last-child)': { borderBottom: 'bold'},
      mx: -2,
      px: 2
    }} justifyContent="space-between">
      <SectionTitle
        {...props}
      />
      {renderContent()}
    </Flex>
  )
}
CardTitle.defaultProps = {
  renderContent: () => {}
}

type FormattedIdentificationProps = {
  identification: Identification | UserIdentification
} & TextProps & { css?: any }
export const FormattedIdentification: React.FunctionComponent<FormattedIdentificationProps> = (props) => {
  const { identification, ...restProps } = props
  const isSp = !identification.species && !identification.cultivar
  return (
    <Text {...restProps} >
      <i>{identification.genus.name} </i>
      {isSp && (<i>sp.</i>)}
      {identification.species && <i>{identification.species.name} </i>}
      {identification.subspecies && <>ssp. <i>{identification.subspecies} </i></>}
      {identification.variety && <>var. <i>{identification.variety} </i></>}
      {identification.subvariety && <>subvar. <i>{identification.subvariety} </i></>}
      {identification.form && <>f. <i>{identification.form} </i></>}
      {identification.subform && <>subf. <i>{identification.subform} </i></>}
      {identification.cultivar && <>'{identification.cultivar}'</>}
    </Text>
  )
}

export type LinkProps = {
  to: ReactRouterLinkProps['to']
} & TextProps & { css?: any }

// Can use <Button variant="text"> for non router links ?
export const Link: React.FunctionComponent<LinkProps> = (props) => {
  const { to, children, ...restProps } = props
  return (
    <Text
      tx="links"
      variant="primary"
      {...restProps}
    >
      <ReactRouterLink
        to={to}
      >
        {children}
      </ReactRouterLink>
    </Text>
  )
}

type TagProps = {
  tag: TagType
  color?: string
  onClick?: () => void
}

// Can use <Button variant="text"> for non router links ?
export const Tag: React.FunctionComponent<TagProps> = (props) => {
  const { tag, color = "blue.5", onClick = () => {} } = props
  return (
    <Box
      sx={{
        backgroundColor: color,
        display: 'inline-block',
        borderRadius: 2,
        p: 1,
        mr: 1,
        mb: 1,
        fontSize: 0
      }}
      onClick={onClick}
    >
      {tag.name}
    </Box>
  )
}

export const TabText: React.FunctionComponent<TextProps & { css?: any }> = (props) => {
  return (
    <Text
      tx="links"
      variant="tab"
      {...props}
    />
  )
}
