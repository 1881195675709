import * as React from 'react'
import { Flex, Image, Text } from 'rebass'
import { Modal } from '../Modal'
import { Image as IImage } from '../../types/queries'
import { Icon } from '../Icon'

interface ImageViewerProps {
  images: IImage[]
  imageIdx: number
  handleClose: () => void
  handleImageIdxChange: (idx: number) => void
}
export const ImageViewer: React.FC<ImageViewerProps> = (props) => {
  const { images, imageIdx, handleClose, handleImageIdxChange } = props
  const image = images[imageIdx]
  const nextIdx = (imageIdx + 1) === images.length ? 0 : imageIdx + 1
  const previousIdx = imageIdx === 0 ? images.length - 1 : imageIdx - 1
  const showIdxNav = images.length > 1
  return (
    <Modal isOpen={true} handleClose={handleClose}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="space-around"
      >
        <Image
          src={image.source}
          maxHeight="80vh"
          mb={3}
        />
        <Flex width="80%" justifyContent="space-around">
          {showIdxNav && (
            <Icon
              name="angle-left"
              size="20px"
              onClick={() => handleImageIdxChange(previousIdx)}
              mr={4}
            />
          )}
          <Text mb={3}>{`${imageIdx + 1} of ${images.length}`}</Text>
          {showIdxNav && (
            <Icon
              name="angle-right"
              size="20px"
              onClick={() => handleImageIdxChange(nextIdx)}
            />
          )}
        </Flex>
      </Flex>
      <Icon
        name="x"
        size="16px"
        variant="cancelButton"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
        }}
      />
    </Modal>
  )
}
