import * as React from 'react'
import { Box, Flex, Text, Button } from 'rebass'
import { Input } from '@rebass/forms'
import CreatableSelect from 'react-select/creatable'
import { FormattedIdentification } from '../../components/text'
import { FlexRow, LabelColumn } from '../../components/table'
import {
  Identification,
  IdentificationInput,
} from '../../types/queries'

const attributes = [
  {
    key: 'genus',
    label: 'Genus',
    placeholder: `i.e. Monstera`,
    isRequired: true
  },
  {
    key: 'species',
    placeholder: 'i.e. deliciosa',
    label: 'Specific epithet'
  },
  {
    key: 'subspecies',
    label: 'Subspecies',
    expandedOption: true
  },
  {
    key: 'variety',
    label: 'Variety',
    expandedOption: true
  },
  {
    key: 'subvariety',
    label: 'Subvariety',
    expandedOption: true
  },
  {
    key: 'form',
    label: 'Form',
    expandedOption: true
  },
  {
    key: 'subform',
    label: 'Subform',
    expandedOption: true
  },
  {
    key: 'cultivar',
    label: 'Cultivar',
    placeholder: 'i.e. Albo-Variegata'
  },
  {
    key: 'commonNames',
    label: 'Common Names',
    isMulti: true
  }
]

interface AttributeRowProps {
  attributeKey: string
  attributeLabel: string
  value: string | { value: string, label: string }[]
  isMulti: boolean
  isRequired?: boolean
  handleChange: (val: string | { value: string, label: string }[]) => void
  placeholder?: string
}
const AttributeRow: React.FunctionComponent<AttributeRowProps> = (props) => {
  const { attributeLabel, placeholder, handleChange, value, isMulti, isRequired } = props

  return (
    <FlexRow>
      <LabelColumn required={!!isRequired}>
        <Text>{attributeLabel}</Text>
      </LabelColumn>
      <Flex width={3/4}>
        {isMulti ? (
          <CreatableSelect
            isClearable
            isMulti
            placeholder="Type and press Enter to add"
            options={[]}
            value={value as {}[]}
            styles={{
              container: () => ({ width: '100%', position: 'relative' }),
            }}
            components={{
              DropdownIndicator: () => null,
              Menu: () => null,
            }}
            onChange={(val: any) => handleChange(val || [])}
          />
        ): (
          <Input
            type="text"
            value={value}
            spellcheck="false"
            placeholder={placeholder}
            onChange={e => handleChange(e.target.value)}
          />)}
      </Flex>
    </FlexRow>
  )
}

interface AddIdState {
  genus: string
  species?: string
  cultivar?: string
  commonNames?: { value: string, label: string }[]
  subspecies?: string
  variety?: string
  subvariety?: string
  form?: string
  subform?: string
}

type UpdateAttributeAction = {
  type: 'UPDATE_ATTRIBUTE'
  attribute: string
  value: string | { value: string, label: string }[]
}

type AddIdAction = UpdateAttributeAction

const addIdReducer = (state: AddIdState, action: AddIdAction): AddIdState => {
  switch (action.type) {
    case 'UPDATE_ATTRIBUTE':
      return { ...state, [action.attribute]: action.value }
    default:
      return state
  }
}
const initialState: AddIdState = {
  genus: '',
  species: '',
  cultivar: '',
  subspecies: '',
  variety: '',
  subvariety: '',
  form: '',
  subform: '',
  commonNames: [],
}

interface AddIdentificationProps {
  handleCancel: () => void
  handleSubmitIdentification: (identification: IdentificationInput) => void
  initialValues?: AddIdState
}

const getFormattedIdentificationFromState = (state: AddIdState): Identification | null => {
  if (!state.genus) return null
  const { genus, species, commonNames, ...restState } = state
  const previewGenus = { id: 'new', name: genus }
  return {
    id: 'newPreview',
    genus: previewGenus,
    species: species ? { id: 'newSp', name: species, genus: previewGenus } : undefined,
    ...restState
  }
}

export const AddIdentification: React.FunctionComponent<AddIdentificationProps> = (props) => {
  const { handleSubmitIdentification, initialValues, handleCancel } = props
  const [showExpandedOptions, setShowExpandedOptions] = React.useState(false)
  const [state, dispatch] = React.useReducer(addIdReducer, initialValues)
  const attrList = showExpandedOptions ? attributes : attributes.filter(a => !a.expandedOption)

  const formattedIdentification = getFormattedIdentificationFromState(state)


  const isSubmitDisabled = !(state.genus && state.genus.length > 2)
  const handleSubmit = () => {
    const commonNames = state.commonNames || []
    handleSubmitIdentification({
      genus: { name: state.genus },
      species: state.species && state.species.length ? { name: state.species } : null,
      cultivar: state.cultivar,
      subspecies: state.subspecies,
      variety: state.variety,
      subvariety: state.subvariety,
      form: state.form,
      subform: state.subform,
      commonNames: commonNames.map(val => val.value)
    })
  }

  return (
    <Flex flexDirection="column" width={1}>
      <Box sx={{
        borderBottom: 'standard',
        px: 3,
        py: 2,
      }}>
        <Text>Submit New Identification</Text>
      </Box>
      {attrList.map(attr => {
        return (
          <AttributeRow
            key={attr.key}
            attributeKey={attr.key}
            attributeLabel={attr.label}
            value={state[attr.key]}
            isMulti={attr.isMulti}
            isRequired={attr.isRequired}
            placeholder={attr.placeholder || ''}
            handleChange={(val) => dispatch({
              type: 'UPDATE_ATTRIBUTE',
              attribute: attr.key,
              value: val
            })}
          />
        )
      })}
      <Flex width="100%" px={3} py={2}>
        {!!state.genus && <Text as="span">Preview: <FormattedIdentification as="span" identification={formattedIdentification}/></Text>}
      </Flex>
      <Flex justifyContent="space-between" p={3} alignItems="center">
        <Box>
          {!showExpandedOptions && <Button variant="text" onClick={() => setShowExpandedOptions(true)}>Show More ID Options</Button>}
        </Box>
        <Box>
          <Button
            variant="cancel"
            onClick={handleCancel}
            sx={{ mr: 4 }}
          >Cancel</Button>
          <Button
            variant="primary"
            disabled={isSubmitDisabled}
            onClick={handleSubmit}
          >Submit</Button>
        </Box>
      </Flex>
    </Flex>
  )
}

AddIdentification.defaultProps = {
  initialValues: initialState
}