import * as React from 'react'
import { Box, BoxProps } from 'rebass'
import onClickOutside from "react-onclickoutside"


type MenuProps = {
  handleClose: () => void
  children: React.ReactElement | null
  stopPropagation?: boolean
  preventDefault?: boolean
} & BoxProps & { css?: any }
class BaseMenu extends React.Component<MenuProps> {
  handleClickOutside = () => {
    this.props.handleClose()
  }
  render() {
    const { handleClose, sx, children, ...restProps } = this.props
    return (
      <Box
        width="200px"
        sx={{
          border: 'standard',
          backgroundColor: 'white',
          position: 'absolute',
          zIndex: 10,
          ...sx
        }}
        {...restProps}
      >
        {children}
      </Box>
    )
  }
}

export const Menu: React.FC<MenuProps> = onClickOutside(BaseMenu)
