import { PageContent } from '../layout'
import { CardTitle, PageTitle, Link } from '../text'
import { LoggedIn } from '../LoggedIn'
import * as React from 'react';
import { History } from 'history'
import { Input } from '../Input';
import { fetchApi } from '../../utils/'
import { Formik } from 'formik'
import { client } from '../../client'
import { facebookValidation, emailPasswordValidation } from './validation'
import { Box, Flex, Text, Button } from 'rebass'
import { FlexRow, LabelColumn, ValueColumn } from '../table'
import { Redirect } from 'react-router-dom'

interface Props {
  className?: string
  history: History
  inviteKey?: string
  location: any
}
interface State {
  username: string
  email: string
  password: string
  passwordConfirm: string
  submitErrors?: string[]
  facebookLoaded: boolean
  fbAuthToken?: string
  isSubmitting: boolean
  success: boolean
}

export class SignUp extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      username: '',
      email: '',
      password: '',
      passwordConfirm: '',
      facebookLoaded: false,
      fbAuthToken: props.location.state && props.location.state.fbToken,
      isSubmitting: false,
      submitErrors: null,
      success: false
    } as State

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  // async componentWillMount() {
  //   if(this.state.fbAuthToken) {
  //     let FB = await loadFacebook()
  //     FB.getLoginStatus(({ authResponse }) => {
  //       this.setState({ fbAuthToken: authResponse.accessToken })
  //
  //       FB.api('/me', { fields: ['last_name', 'email', 'first_name'] }, (resp) => {
  //         this.setState({
  //           firstName: resp.first_name,
  //           lastName: resp.last_name,
  //           email: resp.email,
  //           facebookLoaded: true
  //         })
  //       })
  //     })
  //   }
  // }

  onInputChange(inputKey: string, value: string) {
    this.setState((current) => ({
      ...current,
      [inputKey]: value
    }))
  }

  handleSubmit = async (values) => {
    let body: any = {
      inviteKey: this.props.inviteKey,
      ...values
    }
    this.setState({ submitErrors: null, isSubmitting: true })

    let response = await fetchApi('/sign-up', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })

    if(response.status >= 200 && response.status < 300) {
      window.location.replace('/dashboard')
    } else {
      const responseData = await response.json()
      return this.setState({
        submitErrors: responseData.errors,
        isSubmitting: false,
      })
    }
  }

  // loginWithFacebook = async () => {
  //   let FB = await loadFacebook()
  //
  //   FB.login(async ({ status, authResponse }) => {
  //     if(status === 'connected') {
  //       this.setState({
  //         fbAuthToken: authResponse.accessToken
  //       })
  //       // Check if this user has already completed sign-in
  //       let resp = await fetchApi(`/auth/facebook/sign-in?access_token=${authResponse.accessToken}`, {
  //         method: 'POST',
  //         headers: {
  //           'Accept': 'application/json',
  //           'Content-Type': 'application/json'
  //         }
  //       })
  //       let result = await resp.json()
  //
  //       if(result.hasCompletedSignup) {
  //         return client.resetStore()
  //       }
  //
  //       FB.api('/me', { fields: ['last_name', 'email', 'first_name'] }, (resp) => {
  //         this.setState({
  //           firstName: resp.first_name,
  //           lastName: resp.last_name,
  //           email: resp.email,
  //           facebookLoaded: true
  //         })
  //       })
  //     }
  //   }, { scope: ['public_profile','email'] })
  // }

  render() {
    const { className } = this.props

    const { submitErrors, success, isSubmitting } = this.state

    if (success) {
      return <Redirect to="/dashboard" />
    }

    const initialValues = {
      username: '',
      email: '',
      password: '',
      confirmPassword: ''
    }

    return (
      <PageContent>
        <PageTitle>Sign Up</PageTitle>
        <LoggedIn render={() => <Redirect to="/dashboard" />} />
        <Formik
          initialValues={initialValues}
          validationSchema={emailPasswordValidation}
          onSubmit={this.handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
        >{({ handleSubmit, handleChange, values, errors }) => (
          <Box>
            <Text p={2}>Enter your information to create an account.</Text>
            {!!submitErrors?.length && (
              <Text variant="error">{`Error: ${submitErrors.join(', ')}`}</Text>
            )}
            <FlexRow>
              <LabelColumn>
                Email
              </LabelColumn>
              <ValueColumn>
                <Input
                  type="email"
                  value={values.email}
                  error={errors.email as string}
                  name="email"
                  onChange={handleChange}
                />
              </ValueColumn>
            </FlexRow>
            <FlexRow>
              <LabelColumn>
                Username
              </LabelColumn>
              <ValueColumn>
                <Input
                  value={values.username}
                  error={errors.username as string}
                  name="username"
                  onChange={handleChange}
                />
              </ValueColumn>
            </FlexRow>
            <FlexRow>
              <LabelColumn>
                Password
              </LabelColumn>
              <ValueColumn>
                <Input
                  type="password"
                  value={values.password}
                  error={errors.password as string}
                  name="password"
                  onChange={handleChange}
                />
              </ValueColumn>
            </FlexRow>
            <FlexRow>
              <LabelColumn>
                Confirm Password
              </LabelColumn>
              <ValueColumn>
                <Input
                  type="password"
                  value={values.confirmPassword}
                  error={errors.confirmPassword as string}
                  name="confirmPassword"
                  onChange={handleChange}
                />
              </ValueColumn>
            </FlexRow>
            <Flex justifyContent="center" alignItems="center" p={2}>
              <Button
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >Sign Up!</Button>
            </Flex>

            <Flex justifyContent="center" alignItems="center" p={2}>
              <Text p={2}>Already have an account? <Link to="/login">Log in</Link></Text>
            </Flex>
          </Box>
        )}</Formik>
      </PageContent>
    )
  }
}
