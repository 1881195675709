import * as React from 'react';
import { Redirect } from 'react-router-dom'
import { Button, Flex, Text } from 'rebass'
import { Link } from 'react-router-dom'
import { PageContent } from '../components/layout'
import { LoggedIn } from '../components/LoggedIn'

export const Main: React.FC<any> = (props) => {
  return (
    <PageContent>
      <LoggedIn render={() => <Redirect to="/dashboard" />} />
      <Flex
        flexDirection="column"
        alignItems="center"
      >
        <Text
          variant="h2"
          textAlign="center"
          p={4}
        >Welcome to Greenrie</Text>
        <Link to="/login">
          <Button mb={3}>
            Log In
          </Button>
        </Link>
        <Link to="/signup">
          <Button>
            Sign Up
          </Button>
        </Link>
      </Flex>
    </PageContent>
  )
}