import * as React from 'react'
import { Flex, Button, Text } from 'rebass'
import { Textarea } from '@rebass/forms'
import { useTracking } from 'react-tracking'
import {
  useGetCurrentUserQuery,
  useUpdateUserMutation,
} from 'types/queries'
import { FlexRow, LabelColumn, ValueColumn } from 'components/table'
import { EditIcon } from 'components/EditIcon'
import { EDIT_PROFILE } from 'analytics/events'

const maxBioLength = 500

export const EditProfile: React.FC<{ handleClose: () => void }> = (props) => {
  const { handleClose } = props
  const { data } = useGetCurrentUserQuery()
  const { trackEvent } = useTracking()
  const [profileImage, setProfileImage] = React.useState(null)
  const [bio, setBio] = React.useState(data?.currentUser.bio || '')
  const [updateUser, { loading: isSaving }] = useUpdateUserMutation({
    onCompleted: () => {
      handleClose()
    }
  })

  const handleSave = async () => {
    trackEvent({
      event: EDIT_PROFILE,
    })
    await updateUser({
      variables: {
        user: {
          bio,
          profileImage,
        }
      }
    })
  }

  const handleSetBio = (e) => {
    let val = e.target.value
    if (val.length >= maxBioLength) {
      val = val.slice(0, maxBioLength)
    }
    setBio(val)
  }
  return (
    <>
      <Text variant="h2" p={3}>Edit Your Profile</Text>
      <FlexRow>
        <LabelColumn>Profile Image</LabelColumn>
        <ValueColumn>
          <EditIcon handleImageUpdate={setProfileImage} />
        </ValueColumn>
      </FlexRow>
      <FlexRow>
        <Flex justifyContent="space-between" alignItems="center">
          <LabelColumn>Bio</LabelColumn>
          <Text fontSize={1} color="grey.7">{`${bio.length}/${maxBioLength}`}</Text>
        </Flex>
        <ValueColumn>
          <Textarea
            value={bio}
            onChange={handleSetBio}
            height="auto"
            rows={10}
          />
        </ValueColumn>
      </FlexRow>
      <Flex justifyContent="flex-end" p={2}>
        <Button mr={3} onClick={handleClose} variant="cancel">Cancel</Button>
        <Button onClick={handleSave} disabled={isSaving}>Save</Button>
      </Flex>
    </>
  )
}
