import * as React from 'react'
import { Box, Button, Flex, Image, Text } from 'rebass'
import { Checkbox, Label } from '@rebass/forms'
import Select from 'react-select'
import { Card } from '../../components/Card'
import { Icon } from '../../components/Icon'
import { Panel } from '../../components/layout'
import { Modal } from '../../components/Modal'
import { LabelCheckbox } from '../../components/Checkbox'
import { FormattedIdentification, H4, Tag, Link } from '../../components/text'
import {
  AddEntryMutationOptions,
  GetCurrentUserDocument,
  GetCurrentUserQueryHookResult,
  Item,
  Tag as TagType,
  UpdateItemMutationOptions, useAddEntryMutation,
  useUpdateItemMutation
} from '../../types/queries'
import { ImagePlaceholder } from '../../components/ImagePlaceholder'
import { Search } from '../../components/Input'
import { Menu } from '../../components/Menu'
import * as d3 from 'd3'
import Fuse from 'fuse.js'
import moment from 'moment'
import { AddEntryContent } from './add-entry'
import { sortBy, reverse, uniqBy } from 'lodash'
import { AddItem } from './add-item'
import { EditItem } from './edit-item'
import {
  FERTILIZED_ACTION,
  TREATED_ACTION,
  WATERED_ACTION,
} from 'utils/entry-tags'
import {
  FilterManager,
  useFilterReducer,
  getFilteredItems,
} from '../../components/Filters'
import { PlantDetails } from '../../components/PlantDetails'

type Plant = GetCurrentUserQueryHookResult['data']['currentUser']['items'][0]

const TagColorContext = React.createContext<any>(() => {})
type SheetStateItem = Item & {
  selected?: boolean
}

interface CreateEntryBarProps {
  items: Item[]
  selectedItems: Item[]
  addEntry: (options: AddEntryMutationOptions) => void
}
const CreateEntryBar: React.FC<CreateEntryBarProps> = (props) => {
  const {
    items,
    selectedItems,
    addEntry,
  } = props
  const [isExpanded, setIsExpanded] = React.useState(false)
  const [wateredSelected, setWateredSelected] = React.useState(false)
  const [fertilizedSelected, setFertilizedSelected] = React.useState(false)
  const [treatedSelected, setTreatedSelected] = React.useState(false)

  const selectedActions: Array<any> = []
  if (wateredSelected) selectedActions.push(WATERED_ACTION)
  if (fertilizedSelected) selectedActions.push(FERTILIZED_ACTION)
  if (treatedSelected) selectedActions.push(TREATED_ACTION)
  return  (
    <>
      <Box
        px={4}
        py={2}
        backgroundColor="white"
        width={1}
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          zIndex: 5,
          borderTop: 'standard',
          maxHeight: '85vh',
          overflowY: 'scroll',
        }}
      >
        {isExpanded && (
          <Flex flexDirection="column" sx={{ backgroundColor: 'white' }}>
            <AddEntryContent
              addEntry={addEntry}
              handleCancel={() => setIsExpanded(false)}
              items={items}
              selectedItems={selectedItems}
              selectedActions={selectedActions}
            />
          </Flex>
        )}
        {!isExpanded && (
          <Flex sx={{ position: 'relative' }}>
            <Flex
              flexDirection="column"
              width={1}
              alignItems="center"
              justifySelf="center"
            >
              <Text
                color="grey.5"
                sx={{ display: 'block', mb: 2 }}>{`${selectedItems.length} plants selected`}</Text>
              <Flex flexWrap="wrap" justifyContent="center">
                <LabelCheckbox
                  text={"Watered"}
                  checked={wateredSelected}
                  handleChange={(checked) => setWateredSelected(checked)}
                  mr={3}
                />
                <LabelCheckbox
                  text={"Fertilized"}
                  checked={fertilizedSelected}
                  handleChange={(checked) => setFertilizedSelected(checked)}
                  mr={3}
                />
                <LabelCheckbox
                  text={"Treated"}
                  checked={treatedSelected}
                  handleChange={(checked) => setTreatedSelected(checked)}
                />
              </Flex>
            </Flex>
            <Button
              onClick={() => setIsExpanded(true)}
              disabled={!selectedItems.length}
              sx={{
                position: 'absolute',
                right: '10px',
                bottom: '10px',
                maxWidth: ['60px', 'fit-content']
              }}
            >
              Create Entry
            </Button>
          </Flex>
        )}
      </Box>
    </>
  )
}

type DataColumn = {
  key: string
  label: string
  // getValue: (item: Item) => string,
  render: React.FC<{
    item: Plant | Item
    key: string
    width: number | string | number[]
    openDetailView?: () => void
  }>
  sortable: boolean
  width: number | string | number[]
  hideOnSmall?: boolean
}

const columns: DataColumn[] = [
  {
    key: 'img',
    label: '',
    width: '60px',
    sortable: false,
    render: ({ key, item, width }) => {
      return (
        <Flex
          key={key}
          p={0}
          height="60px"
          width={width}
          justifyContent="center"
          alignItems="center"
        >
          <Link to={`/u/${item.user.username}/items/${item.id}`}>
            {item.coverImage ? (
              <Image
                src={item.coverImage}
                sx={{
                  objectFit: 'cover',
                  borderRadius: '2px',
                  maxHeight: '50px',
                }}/>
            ) : (
              <ImagePlaceholder iconSize="36px" minHeight="50px" />
            )}
          </Link>
        </Flex>
      )
    }
  },
  {
    key: 'name',
    label: 'Name',
    width: [0.25, 0.12, 0.12],
    sortable: true,
    render: ({ key, item, width, openDetailView }) => (
      <Flex key={key} alignItems="center" width={width} p={1}>
        <Button variant="text" onClick={openDetailView}>
          {item.name}
        </Button>
      </Flex>
    )
  },
  {
    key: 'id',
    label: 'ID',
    width: [0.3, 0.12, 0.12],
    sortable: true,
    render: ({ key, item, width }) => (
      <Flex key={key} alignItems="center" width={width}>
        <FormattedIdentification identification={item.identification}/>
      </Flex>
    )
  },
  {
    key: 'tags',
    label: 'Tags',
    width: [0.4, 0.2, 0.2],
    sortable: false,
    render: ({ key, item, width }) => {
      const { tags = [] } = item
      return (
        <TagColorContext.Consumer key={key}>
          {tagScale => (
            <Flex
              justifyContent="center"
              alignItems="center"
              width={width}
              flexWrap="wrap"
            >
              {tags.map(tag => (
                <Tag key={tag.id} tag={tag} color={tagScale(tag.id)} />
              ))}
            </Flex>
          )}
        </TagColorContext.Consumer>
      )
    }
  },
  {
    key: 'waterFreq',
    label: 'Water Every (days)',
    width: [0, 0.05, 0.05],
    sortable: true,
    render: ({ key, item, width }) => <Flex
      key={key}
      alignItems="center"
      sx={{ visibility: ['hidden', 'visible', 'visible']}}
      justifyContent="center" width={width}>{item.waterFrequency}</Flex>,
    hideOnSmall: true
  },
  {
    key: 'lastWater',
    label: 'Last Water',
    width: [0, 0.1, 0.1],
    sortable: true,
    render: ({ key, item, width }) => {
      const content = item.lastWater
        ? moment(item.lastWater).format('M/D')
        : null
      return (
        <Flex
          key={key}
          alignItems="center"
          justifyContent="center"
          sx={{ visibility: ['hidden', 'visible', 'visible']}}
          width={width}>{content}</Flex>
      )},
    hideOnSmall: true
  },
  {
    key: 'nextWater',
    label: 'Next Water',
    width: [0, 0.1, 0.1],
    sortable: true,
    render: ({ key, item, width }) => {
      const content = item.nextWater
        ? moment(item.nextWater).format('M/D')
        : null
      return (
        <Flex
          key={key}
          alignItems="center"
          justifyContent="center"
          sx={{ visibility: ['hidden', 'visible', 'visible']}}
          width={width}>{content}</Flex>
      )},
    hideOnSmall: true
  },
  {
    key: 'fertilizerFreq',
    label: 'Fert. Every (days)',
    width: [0, 0.05, 0.05],
    sortable: true,
    render: ({ key, item, width }) => (
      <Flex
        key={key}
        alignItems="center"
        sx={{ visibility: ['hidden', 'visible', 'visible']}}
        justifyContent="center" width={width}>{item.fertilizerFrequency}</Flex>),
    hideOnSmall: true
  },
  {
    key: 'lastFertilizer',
    label: 'Last Fert.',
    width: [0, 0.1, 0.1],
    sortable: true,
    render: ({ key, item, width }) => {
      const content = item.lastFertilizer
        ? moment(item.lastFertilizer).format('M/D')
        : null
      return (
        <Flex
          key={key}
          alignItems="center"
          sx={{ visibility: ['hidden', 'visible', 'visible']}}
          justifyContent="center"
          width={width}>{content}</Flex>
      )},
    hideOnSmall: true
  },
  {
    key: 'nextFertilizer',
    label: 'Next Fert.',
    width: [0, 0.1, 0.1],
    sortable: true,
    render: ({ key, item, width }) => {
      const content = item.nextFertilizer
        ? moment(item.nextFertilizer).format('M/D')
        : null
      return (
        <Flex
          key={key}
          sx={{ visibility: ['hidden', 'visible', 'visible']}}
          alignItems="center"
          justifyContent="center"
          width={width}>{content}</Flex>
      )},
    hideOnSmall: true
  },
]

const sortByOptions = [
  {
    value: 'name',
    label: 'Name',
    sortBy: (item) => item.name
  },
  {
    value: 'identification',
    label: 'Identification',
    sortBy: (item) => item.identification?.formatted
  },
  {
    value: 'added',
    label: 'Date Added',
    sortBy: (item) => item.createdAt
  },
  {
    value: 'waterFreq',
    label: 'Water Every',
    sortBy: (item) => item.waterFrequency
  },
  {
    value: 'lastWater',
    label: 'Last Water',
    sortBy: (item) => item.lastWater
  },
  {
    value: 'nextWater',
    label: 'Next Water',
    sortBy: (item) => item.nextWater
  },
  {
    value: 'fertFreq',
    label: 'Fert. Every',
    sortBy: (item) => item.fertilizerFrequency
  },
  {
    value: 'lastFert',
    label: 'Last Fert.',
    sortBy: (item) => item.lastFertilizer
  },
  {
    value: 'nextFert',
    label: 'Next Fert.',
    sortBy: (item) => item.nextFertilizer
  },
]

interface CollectionRowProps {
  item: SheetStateItem
  openDetailView: () => void
  handleSetEdit: (editId: string | null) => void
  toggleSelect: (itemId: string) => void
  selected: boolean
}
const CollectionRow: React.FunctionComponent<CollectionRowProps> = (props) => {
  const { item, handleSetEdit, toggleSelect, selected, openDetailView } = props
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  return (
    <Flex sx={{ borderBottom: 'standard', minHeight: 'fit-content' }}>
      <Flex
        key="chkbox"
        width="50px"
        onClick={() => toggleSelect(item.id)}
        justifyContent="center"
        alignItems="center"
        sx={{ cursor: 'pointer' }}
      >
        <Checkbox checked={selected} />
      </Flex>
      {columns.map(col => {
        return (
          col.render({
            key: col.key,
            item,
            width: col.width,
            openDetailView
          })
        )
      })}
      <Flex
        key="edit"
        width={.05}
        justifyContent="center"
        alignItems="center"
        sx={{
          cursor: 'pointer',
          position: 'relative'
        }}
      >
        <Icon name="ellipsis" size="15px" onClick={() => setIsMenuOpen(!isMenuOpen)}/>
        {isMenuOpen && (
          <Menu
            handleClose={() => setIsMenuOpen(false)}
          >
            <Button variant="text" onClick={() => handleSetEdit(item.id)} >Edit</Button>
          </Menu>
        )}
      </Flex>
    </Flex>
  )
}

interface SheetState {
  allSelected: boolean
  selected: { [itemId: string]: boolean }
  isEntryScreen: boolean
  editViewId: string | null
  sort?: {
    column: string
    direction: 'ASC' | 'DESC'
  }
}

const sheetReducer = (state: SheetState, action): SheetState => {
  const baseSelected = state.selected
  switch (action.type) {
    case 'SELECT_ALL':
      action.items.forEach(item => { baseSelected[item.id] = true })
      return {
        ...state,
        allSelected: true,
        selected: baseSelected
      }
    case 'DESELECT_ALL':
      return {
        ...state,
        allSelected: false,
        selected: {}
      }
    case 'TOGGLE_ITEM_SELECT':
      baseSelected[action.id] = !baseSelected[action.id]
      return {
        ...state,
        selected: baseSelected
      }
    case 'SET_ENTRY_SCREEN':
      return {
        ...state,
        isEntryScreen: true
      }
    case 'SET_SELECT_SCREEN':
      return {
        ...state,
        isEntryScreen: false
      }
    case 'SET_EDIT_VIEW':
      return {
        ...state,
        editViewId: action.itemId
      }
    case 'SET_SORT_BY':
      const isCurrentSortBy = state.sort?.column === action.column
      const currentSortDirection = isCurrentSortBy && state.sort.direction
      const nextSortDirection = currentSortDirection === 'ASC' ? 'DESC' : 'ASC'
      return {
        ...state,
        sort: {
          column: action.colKey,
          direction: nextSortDirection
        }
      }
  }
  return state
}

const fuseOptions = {
  shouldSort: false,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    'identification.formatted',
    'name',
  ]
}

interface CollectionSheetProps {
  items: Item[]
  tags: TagType[]
  addEntry: (options: AddEntryMutationOptions) => void
  updateItem: (options: UpdateItemMutationOptions) => void
}
const CollectionSheet: React.FunctionComponent<CollectionSheetProps> = (props) => {
  const [state, dispatch] = React.useReducer(sheetReducer, {
    allSelected: false,
    selected: {},
    editViewId: null,
    isEntryScreen: false,
  })
  const [searchValue, setSearchValue] = React.useState('')
  const [detailView, setDetailView] = React.useState<Plant | Item | null>(null)
  const [isCreateEntryOpen, setIsCreateEntryOpen] = React.useState(false)
  const [isAddItemOpen, setIsAddItemOpen] = React.useState(false)
  const [selectedSortBy, setSelectedSortBy] = React.useState(null)
  const { filtered, actions } = useFilterReducer()
  const { isEntryScreen } = state
  const toggleSelect = (itemId) => dispatch({
    type: 'TOGGLE_ITEM_SELECT',
    id: itemId
  })
  const selectAll = React.useCallback((itemId) => dispatch({
    type: 'SELECT_ALL',
    items: props.items
  }), [])
  const deselectAll = React.useCallback((itemId) => dispatch({
    type: 'DESELECT_ALL'
  }), [])
  const setEditViewId = React.useCallback((itemId) => dispatch({
    type: 'SET_EDIT_VIEW',
    itemId
  }), [])
  const setSortBy = React.useCallback((colKey) => dispatch({
    type: 'SET_SORT_BY',
    column: colKey
  }), [])

  const noUserItems = !props.items.length
  const filteredItems = getFilteredItems(props.items, filtered)

  const selectedItems = filteredItems.filter(item => !!state.selected[item.id])

  const editItem = state.editViewId && props.items.find(item => item.id === state.editViewId)

  const allUserGenera = props.items.map(item => item.identification?.genus).filter(x => x)
  const sortedGenera = sortBy(uniqBy(allUserGenera, 'id'), 'name')
  const sortedTags = sortBy(props.tags, tag => {
    return tag.name
  })

  const getVisibleItems = () => {
    const sorted = selectedSortBy ? sortBy(filteredItems, selectedSortBy.sortBy)
      : reverse(sortBy(filteredItems, 'createdAt'))
    if (!searchValue) {
      return sorted
    }
    const fuse = new Fuse(sorted, fuseOptions)
    return fuse.search(searchValue)
  }

  const selectedItemsText = () => {
    if (!selectedItems.length) {
      return (
        <Text
          color="grey.5"
          sx={{ display: 'block', mr: 2 }}
          fontSize={0}
        >
          No plants selected. Select 1 or more plants to create an entry
        </Text>
      )
    } else {
      const isPlural = selectedItems.length > 1
      return (
        <Text
          color="grey.5"
          sx={{ display: 'block', mr: 2 }}
          fontSize={[0, 1, 1]}
        >
          {isPlural ? `${selectedItems.length} plants selected` : '1 plant selected'}
        </Text>
      )
    }
  }
  return (
    <Flex
      width={1}
      flexDirection="column"
      sx={{
        position: 'relative',
      }}
    >
      <Flex
        width={1}
        flexDirection="column"
        backgroundColor="white"
        sx={{
          position: 'sticky',
          top: ['40px', '40px', '50px'],
          zIndex: 2,
        }}
      >
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          p={3}
        >
          {selectedItemsText()}
          <Button
            name="create-entry"
            disabled={!selectedItems.length}
            onClick={() => setIsCreateEntryOpen(true)}
            mr={3}
          >Create Entry
          </Button>
          <Button
            name="add-plant"
            onClick={() => setIsAddItemOpen(true)}
          >+ Add Plants
          </Button>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          p={3}
        >
          <Flex width={1/3}>
            <Search
              value={searchValue}
              handleChange={(val) => setSearchValue(val)}
              handleClear={() => setSearchValue('')}
              placeholder="Search by name or ID"
            />
          </Flex>
          <Flex width={1/3} alignItems="center">
            <Text
              pr={3}
              fontSize={[1, 1, 2]}
            >Filter:</Text>
            <FilterManager
              genera={sortedGenera}
              tags={sortedTags}
              addFilter={actions.addFilter}
              removeFilter={actions.removeFilter}
              removeAllFilters={actions.removeAllFilters}
              filtered={filtered}
            />
          </Flex>
          <Flex width={1/4} alignItems="center">
            <Text
              pr={3}
              fontSize={[1, 1, 2]}
            >Sort:</Text>
            <Select
              styles={{
                container: (styles) => ({ ...styles, width: '100%' }),
                control: (styles) => ({ ...styles, minHeight: 'fit-content', fontSize: '12px' }),
                clearIndicator: (styles) => ({ ...styles, padding: 0 }),
                dropdownIndicator: (styles) => ({ ...styles, padding: 0 }),
                option: (styles) => ({ ...styles, fontSize: '12px' }),
              }}
              placeholder="Sort..."
              options={sortByOptions}
              onChange={setSelectedSortBy}
            />
          </Flex>
        </Flex>
        <Flex
          backgroundColor="white"
          minHeight="fit-content"
          fontSize={[0, 0, 1]}
          sx={{
            position: 'sticky',
            top: '0',
          }}
        >
          <Flex
            width="50px"
            onClick={state.allSelected ? deselectAll : selectAll}
            justifyContent="center"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
          >
            <Checkbox
              checked={state.allSelected}
            />
          </Flex>
          {columns.map(col => (
            <Flex
              key={col.key}
              p={1}
              width={col.width}
              justifyContent={['name', 'id'].includes(col.key) ? 'flex-start' : 'center'}
              alignItems="center"
              sx={{ visibility: col.hideOnSmall ? ['hidden', 'visible', 'visible'] : 'visible'}}
            >
              {col.label}
            </Flex>
          ))}
          <Box
            width={.05}
          />
        </Flex>
      </Flex>

      <Card
        p={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 10,
          width: '100%',
          fontSize: [0, 0, 1],
          border: 'none',
          position: 'relative',
          mb: '60px',
        }}
      >
        {noUserItems && (
          <Flex flexDirection="column" alignItems="center" justifyContent="center" p={3} my={4}>
            <Text fontSize={3} mb={3}>No plants added yet!!</Text>
            <Text fontSize={2}>Click "Add Plants" in the top right corner to get started</Text>
          </Flex>
        )}
        {getVisibleItems().map(item => {
          return (
            <CollectionRow
              key={item.id}
              item={item}
              handleSetEdit={setEditViewId}
              toggleSelect={toggleSelect}
              selected={!!state.selected[item.id]}
              openDetailView={() => setDetailView(item)}
            />
          )
        })}
        {!!state.editViewId && (
          <Modal handleClose={() => setEditViewId(null)} isOpen={!!state.editViewId}>
            <EditItem
              item={editItem}
              handleClose={() => setEditViewId(null)}
              updateItem={props.updateItem}
            />
          </Modal>
        )}
      </Card>
      <Panel
        isOpen={isCreateEntryOpen}
        handleClose={() => setIsCreateEntryOpen(false)}
      >
        <AddEntryContent
          addEntry={props.addEntry}
          handleCancel={() => setIsCreateEntryOpen(false)}
          items={props.items}
          selectedItems={selectedItems}
        />
      </Panel>
      <Panel
        isOpen={!!detailView}
        handleClose={() => setDetailView(null)}
      >
        <PlantDetails
          plant={detailView as Plant}
          updateItem={props.updateItem}
        />
      </Panel>
      {!!isAddItemOpen && (
        <Modal handleClose={() => setIsAddItemOpen(false)} isOpen={!!isAddItemOpen}>
          <AddItem
            handleCancel={() => setIsAddItemOpen(false)}
            onComplete={() => setIsAddItemOpen(false)}
          />
        </Modal>
      )}
    </Flex>
  )
}

export const UserCollection: React.FunctionComponent<{ items: Item[], tags: TagType[] }> = (props) => {
  const [refreshIdx, setRefreshIdx] = React.useState(0)
  const tagColorScale = d3.scaleOrdinal(d3.schemePastel1)
  const [updateItem] = useUpdateItemMutation({
    refetchQueries: [{
      query: GetCurrentUserDocument
    }],
    onCompleted: () => {
      setRefreshIdx(refreshIdx + 1)
    }
  })
  const [addEntry] = useAddEntryMutation({
    refetchQueries: [{
      query: GetCurrentUserDocument
    }],
    onCompleted: () => {
      setRefreshIdx(refreshIdx + 1)
    }
  })

  return (
    <TagColorContext.Provider value={tagColorScale} >
      <CollectionSheet
        key={refreshIdx}
        items={props.items}
        tags={props.tags}
        addEntry={addEntry}
        updateItem={updateItem}
      />
    </TagColorContext.Provider>
  )
}
