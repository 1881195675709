import * as React from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { Box, Flex, FlexProps, Image } from 'rebass'
import { Item } from '../../types/queries'
import { Card } from '../Card'
import { Icon } from '../Icon'
import { FlexRow, LabelColumn, ValueColumn } from '../table'
import { Textarea } from '@rebass/forms'
import Select from 'react-select'

export interface ImageData {
  id: string
  file: File
  src: string
  text?: string
  items?: Item[]
}

const ImageSortHandle = SortableHandle(() => {
  return (
    <Icon name="handle" size="24px" style={{ cursor: 'grab' }} />
  )
})

interface ImageRowProps {
  image: ImageData
  deleteImage: () => void
  updateImage: (value: ImageData) => void
  itemOptions: Item[]
}
export const ImageRow = SortableElement<ImageRowProps>((props) => {
  const { image, deleteImage, updateImage, itemOptions } = props
  const { src, text, items }= image
  return (
    <Card
      variant="well"
      mb={2}
      sx={{ display: 'flex', flexDirection: 'column' }}>
      <Flex justifyContent="space-between" px={2}>
        <ImageSortHandle/>
        <Icon
          name="x"
          onClick={deleteImage}
        />
      </Flex>
      <FlexRow>
        <Flex width={[1, 1, 1/3]} sx={{ borderRight: 'standard', maxHeight: '300px' }}>
          <Image src={src} sx={{ maxHeight: '300px' }}/>
        </Flex>
        <Box width={[1, 1, 2/3]}>
          <FlexRow>
            <LabelColumn>Plants</LabelColumn>
            <ValueColumn >
              <Select
                isMulti
                name="entry-items"
                value={items}
                options={itemOptions}
                getOptionValue={opt => opt.id}
                getOptionLabel={opt => opt.name}
                onChange={(value, actionType) => {
                  console.log(value, actionType)
                  updateImage({ ...image, items: value })
                }}
                styles={{
                  container: () => ({ width: '100%', position: 'relative' }),
                }}
              />
            </ValueColumn>
          </FlexRow>
          <FlexRow>
            <LabelColumn>Caption</LabelColumn>
            <ValueColumn>
              <Textarea
                value={text}
                onChange={(e) => updateImage({ ...image, text: e.target.value })}
              />
            </ValueColumn>
          </FlexRow>
        </Box>
      </FlexRow>
    </Card>
  )
})

export const ImageContainer = SortableContainer<FlexProps>(ValueColumn)
