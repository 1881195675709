import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Flex, Text, Image, Button } from 'rebass'
import { useTracking } from 'react-tracking'
import moment from 'moment'
import { PageContent } from '../../components/layout'
import { LoadingEllipsisText } from '../../components/loading'
import { TabText } from '../../components/text'
import { useGetUserQuery } from '../../types/queries'
import { UserCollection } from './user-collection'
import { UserWishList } from './user-wish-list'
import { TAB_VIEW } from 'analytics/events'
import { ImagePlaceholder } from 'components/ImagePlaceholder'
import { IsOwner } from 'components/IsOwner'
import { Modal } from 'components/Modal'
import { EditProfile } from 'components/EditProfile'
import { CopyToClipboard } from 'react-copy-to-clipboard';

type UserView = 'collection' | 'wishlist'

interface UserPageProps {}

export const UserPage: React.FunctionComponent<UserPageProps> = (props) => {
  const [view, setView] = React.useState<UserView>('collection')
  const [isCopied, setIsCopied] = React.useState(false)
  const { username } = useParams<{ username: string }>()
  const { Track, trackEvent } = useTracking({
    page: 'User',
    usernameViewed: username,
  })
  const { data, loading } = useGetUserQuery({
    variables: {
      username
    }
  })
  const [isEditUserOpen, setIsEditUserOpen] = React.useState(false)

  const handleSwitchView = (view) => {
    trackEvent({ event: TAB_VIEW, label: view })
    setView(view)
  }

  const handleClickCopyShare = () => {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  const content = () => {
    if (loading) {
      return <LoadingEllipsisText />
    } else if (!data.user) {
      return (
        <Text variant="h2" sx={{ textAlign: 'center' }}>
          no user found
        </Text>
      )
    }
    const { user } = data
    return (
      <>
        <Flex p={3} minHeight="fit-content" alignItems="center" justifyContent="space-between">
          <Flex>
            <Flex flexDirection="column" justifyContent="center">
              {user.profileImage ? (
                <Image src={user.profileImage} width="100px" height="100px" />
              ) : (
                <ImagePlaceholder
                  iconSize="80px"
                  iconName="smile"
                  minHeight={0}
                  sx={{
                    border: 'standard',
                    width: '100px',
                    height: '100px',
                  }}
                />
              )}

              <IsOwner ownerId={user.id}>
                <Flex flexDirection="column" alignItems="center">
                  <Button
                    variant="text"
                    fontSize={1}
                    onClick={() => setIsEditUserOpen(true)}
                  >Edit</Button>
                  <CopyToClipboard
                    text={`https://greenrie.com/u/${username}`}
                    onCopy={handleClickCopyShare}
                  >
                    <Button
                      variant="text"
                      fontSize={1}
                    >{isCopied ? 'Copied!' : 'Copy Share Link'}</Button>
                  </CopyToClipboard>
                </Flex>
              </IsOwner>
            </Flex>
            <Flex
              flexDirection="column"
              m={3}
              flexGrow={3}
              maxWidth="40vw"
            >
              <Text sx={{ fontFamily: 'display', fontSize: [3, 3, 4]}} >{user.username}</Text>
              <Flex flexDirection="column" alignItems="flex-start" fontSize={[1, 2, 2]}>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text mr={3} sx={{ fontFamily: 'display' }}>Account Created:</Text>
                  <Text>{moment(user.createdAt).format('M/D/YYYY')}</Text>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text mr={3} sx={{ fontFamily: 'display' }}>Plant Count:</Text>
                  <Text>{user.items.length}</Text>
                </Flex>
                <Flex>
                  <Text
                    my={2}
                    sx={{
                      color: 'grey.7',
                      whiteSpace: 'pre-line',
                      fontSize: [0, 1, 1]
                    }}
                  >{user.bio}</Text>
                </Flex>
              </Flex>
              {isEditUserOpen && (
                <Modal isOpen={isEditUserOpen} handleClose={() => setIsEditUserOpen(false)}>
                  <EditProfile
                    handleClose={() => setIsEditUserOpen(false)}
                  />
                </Modal>
              )}
            </Flex>
          </Flex>
        </Flex>

        <Flex justifyContent="center">
          <TabText
            className={view === 'collection' ? 'selected' : ''}
            onClick={() => handleSwitchView('collection')}
            mr={4}
          >Collection</TabText>
          <TabText
            className={view === 'wishlist' ? 'selected' : ''}
            onClick={() => handleSwitchView('wishlist')}
          >Wish List</TabText>
        </Flex>
        {view === 'wishlist' ? <UserWishList user={user} />
          : <UserCollection user={user} />
        }
      </>
    )
  }

  return (
    <Track>
      <PageContent>
        {content()}
      </PageContent>
    </Track>
  )
}
