import * as React from 'react'
import { Box, Flex, Button } from 'rebass'
import { useLocation } from 'react-router-dom'
import { MobileNav, TitleWrapper } from '../layout'
import { Link, LinkProps } from '../text'
import { Icon } from '../Icon'
import { User } from '../../types/queries'
import { Menu } from '../Menu'
import { LoggedIn } from '../LoggedIn'
import { LoadingEllipsisText } from '../loading'

export const NavLink = (props: LinkProps & { css?: any, active?: boolean }) => {
  const { active, ...restProps } = props
  return (
    <Link
      display="block"
      variant={active ? 'navActive' : 'nav'}
      sx={{
        textAlign: 'center',
        m: 2,
      }}
      {...restProps}
    />
  )
}

interface NavItem {
  to: string
  key: string
  label: string
  shouldShow?: (currentUser?: User, loading?: boolean) => boolean
  subItems?: NavItem[]
}

const SubNav: React.FunctionComponent<{ items: NavItem[] }> = (props) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '200px',
        right: '-201px',
        backgroundColor: 'white',
        border: 'standard',
        top: 0,
      }}
    >
      {props.items.map(item => {
        return (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            sx={{
              '&:not(:last-child)': {
                borderBottom: 'standard',
              },
              position: 'relative'
            }}
          >
            <NavLink to={item.to}>{item.label}</NavLink>
          </Flex>
        )
      })}
    </Box>
  )
}

const NavElement: React.FunctionComponent<{ navItem: NavItem}> = (props) => {
  const { navItem: { to, label } } = props
  const subItems = false
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      sx={{ position: 'relative', px: 3 }}
    >
      <NavLink to={to}>{label}</NavLink>
      {subItems && <Icon name="angle-right" />}
      {subItems && (<SubNav items={subItems} />)}
    </Flex>
  )
}

export const NavContent = () => {
  // const { data, loading } = useGetCurrentUserQuery()
  // const currentUser = data && data.currentUser
  const { pathname } = useLocation()
  const renderLoading = React.useCallback(() => {
    return null
  }, [])
  const renderLoggedIn = React.useCallback(({ currentUser }) => {
    const userProfilePath = `/u/${currentUser.username}`
    return (
      <>
        <NavLink
          active={pathname === '/dashboard'}
          to="/dashboard"
        >
          Plant Dashboard
        </NavLink>
        <NavLink
          active={pathname === '/wishlist'}
          to="/wishlist"
        >
          Wish List
        </NavLink>
        <NavLink
          active={pathname === userProfilePath}
          to={userProfilePath}
        >
          Your Profile
        </NavLink>
      </>
    )
  }, [pathname])
  return (
    <LoggedIn
      renderLoading={renderLoading}
      render={renderLoggedIn}
    />
  )
}

export const SideBar = () => {
  return (
    <Box
      width={.2}
      height="100vh"
      display={['none', 'block', 'block']}
      sx={{
        borderRight: 'bold',
        maxWidth: '200px',
      }}
    >
      <NavContent />
    </Box>
  )
}

export const MobileMenu = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { pathname } = useLocation()
  React.useEffect(() => {
    if (isOpen) setIsOpen(false)
  }, [pathname])
  return (
    <MobileNav>
      <Icon name="bars" onClick={() => setIsOpen(true)} />
      {isOpen && (
        <Menu
          handleClose={() => setIsOpen(false)}
          sx={{
            left: '0',
            top: '100%',
          }}
        >

          <LoggedIn
            renderLoggedOut={() => {
              return <NavLink to="/" active={pathname === '/'}>Home</NavLink>
            }}
            renderLoading={() => null}
            render={NavContent}
          />
        </Menu>
      )}
    </MobileNav>
  )
}

export const AccountButton = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { pathname } = useLocation()
  React.useEffect(() => {
    if (isOpen) setIsOpen(false)
  }, [pathname])
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '100%',
        position: 'relative',
      }}
    >
      <Icon name="user" onClick={() => setIsOpen(true)} />
      {isOpen && (
        <Menu
          handleClose={() => setIsOpen(false)}
          sx={{
            right: '50%',
            top: '100%',
          }}
        >
          <Flex
            alignItems="center"
            flexDirection="column"
            sx={{
              position: 'relative',
            }}
          >
            <Button
              variant="text"
              sx={{ fontFamily: 'display' }}
              onClick={() => window.open('https://forms.gle/UY18qVj58Ycf12tR6', '_blank')}
            >Submit Feedback</Button>
            <NavLink to="/logout">Log Out</NavLink>
          </Flex>
        </Menu>
      )}
    </Flex>
  )
}
