import { EDIT_PLANT } from 'analytics/events'
import * as React from 'react'
import { Box, Flex, Button } from 'rebass'
import { useTracking } from 'react-tracking'
import { EditItem, editItemReducer, IEditItem } from '../../components/EditItem'
import { Item } from '../../types/queries'

interface EditItemDetailsProps {
  handleCancelEdit: () => void
  handleSubmit: (editedItem: IEditItem) => void
  isSubmitting: boolean
  item: Item
}

export const EditItemDetails: React.FC<EditItemDetailsProps> = (props) => {
  const { item, handleSubmit, isSubmitting, handleCancelEdit } = props
  const { trackEvent } = useTracking()
  const [state, dispatch] = React.useReducer(editItemReducer, {
    name: item.name,
    description: item.description,
    savedCoverImage: item.coverImage,
    acquiredDate: item.acquiredDate ? item.acquiredDate : undefined,
    originDate: item.originDate ? item.originDate : undefined,
    source: item.source,
    tags: item.tags ? item.tags.map(tag => ({ id: tag.id, label: tag.name })) : [],
    identification: item.identification,
    waterFrequency: item.waterFrequency,
    nextWater: item.nextWater ? item.nextWater : undefined,
    fertilizerFrequency: item.fertilizerFrequency,
    nextFertilizer: item.nextFertilizer ? item.nextFertilizer : undefined,

  })
  const [displayErrors, setDisplayErrors] = React.useState(false)

  const isSubmitDisabled = !item.name || !item.identification || isSubmitting ||
    (item.identification && item.identification.id === 'new')

  const onSubmit = () => {
    if (isSubmitDisabled) {
      setDisplayErrors(true)
    } else {
      trackEvent({
        event: EDIT_PLANT,
        plantId: item.id,
      })
      handleSubmit(state)
    }
  }
  return (
    <Box>
      <EditItem
        item={state}
        handleUpdateAttribute={(attribute, value) => {
          dispatch({
            type: 'UPDATE_ATTRIBUTE',
            value,
            attribute
          })
        }}
        displayErrors={displayErrors}
      />
      <Flex
        justifyContent="flex-end"
        alignItems="center"
        p={3}
      >
        <Button
          variant="cancel"
          sx={{ mr: 4 }}
          onClick={handleCancelEdit}
        >Cancel Edit</Button>
        <Button
          variant="primary"
          disabled={isSubmitting}
          onClick={onSubmit}
        >Save</Button>
      </Flex>
    </Box>
  )
}
