import arrayMove from 'array-move'
import * as React from 'react'
import moment from 'moment'
import { useDropzone } from 'react-dropzone'
import { Card } from '../Card'
import { LoadingEllipsisText } from '../loading'
import { Box, Flex, Button, Text } from 'rebass'
import { Input, Textarea } from '@rebass/forms'
import { IdDropdown, NewIdentification } from '../../pages/dashboard/id-dropdown'
import { FlexRow, LabelColumn, ValueColumn } from '../table'
import { DateInputs } from '../Input/date-inputs'
import { EditIcon } from '../EditIcon'
import CreatableSelect from 'react-select/creatable'
import {
  Action,
  Identification, Item,
  useGetCurrentUserQuery,
} from '../../types/queries'
import { get } from 'lodash'
import { CardTitle } from '../text'
import { ImageRow, ImageContainer, ImageData } from './image-row'

import { actionsFormatted, editEntryActions, editEntryReducer, defaultInitialState } from './reducer'
import Select from 'react-select'
import uuidv4 from 'uuid/v4'
export { editEntryActions, editEntryReducer, defaultInitialState }

type EntryTagState = Action & { checked: boolean }

export interface IEditEntry {
  items?: Item[]
  date?: Date
  tags?: EntryTagState[]
  text?: string
  images: ImageData[]
}

interface EditEntryProps {
  displayErrors: boolean
  entry: IEditEntry
  items: Item[]
  handleAddFiles: (files: ImageData[]) => void
  handleDeleteImage: (idx: number) => void
  handleSelectAction: (actionId: string, value: boolean) => void
  handleCancel?: () => void
  handleSubmit: () => void
  handleUpdateAttribute: (attr: string, value: any) => void
  handleUpdateImage: (idx: number, value: ImageData) => void
}

const Dropzone: React.FC<{ onDrop: any }> = ({ onDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxSize: 2700000,
    multiple: true
  } as any)
  return (
    <Card variant="well" width={1}>
      <Flex
        {...getRootProps() as any}
        justifyContent="center"
        p={4}
        width="100%"
      >
        <input {...getInputProps()} />
        <Text>Drag and drop images here or click to select files</Text>
      </Flex>
    </Card>
  )
}

const shouldComponentNotUpdate= (prevProps, nextProps) => {
  return prevProps.entry === nextProps.entry &&
    prevProps.displayErrors === nextProps.displayErrors
}

export const EditEntry: React.FunctionComponent<EditEntryProps> = React.memo((props) => {
  const { entry, items, handleUpdateAttribute, displayErrors, handleDeleteImage,
    handleUpdateImage, handleSelectAction, handleAddFiles, handleSubmit, handleCancel = () => {} } = props
  const { data, loading } = useGetCurrentUserQuery()

  if (loading) {
    return <LoadingEllipsisText />
  }
  // const items = get(data, 'currentUser.items', [])

  const errorMessages = []
  const noItemsSelected = !(entry.items && entry.items.length)
  const hasSelectedTags = !!(entry.tags && entry.tags.length && entry.tags.some(t => t.checked))
  const hasAddedImages = !!(entry.images && entry.images.length)
  const hasAddedText = !!(entry.text && entry.text.length)
  const isEmptyEntry = !hasSelectedTags && !hasAddedImages && !hasAddedText
  const isFutureDate = moment(entry.date).isAfter(moment())
  if (noItemsSelected) errorMessages.push('No items selected.')
  if (isEmptyEntry) errorMessages.push('No entry content.')
  if (isFutureDate) errorMessages.push('Invalid date.')

  const handleFileInputChange = (files: File[]) => {
    const formattedFiles: ImageData[] = []
    for (let idx = 0; idx < files.length; idx++) {
      const file = files[idx]
      formattedFiles.push({
        id: uuidv4(),
        file,
        src: URL.createObjectURL(file),
        items: entry.items || []
      })
    }
    if (formattedFiles.length) {
      handleAddFiles(formattedFiles)
    }
  }
  return (
    <Box maxWidth="900px" mx="auto">
      <FlexRow>
        <LabelColumn required>
          <Text>Plants</Text>
        </LabelColumn>
        <ValueColumn>
          <Select
            isMulti
            name="entry-items"
            value={entry.items}
            options={items}
            getOptionValue={opt => opt.id}
            getOptionLabel={opt => opt.name}
            onChange={(value, actionType) => {
              console.log(value, actionType)
              handleUpdateAttribute('items', value)
              }}
            styles={{
              container: () => ({ width: '100%', position: 'relative' }),
            }}
          />
        </ValueColumn>
      </FlexRow>
      <FlexRow>
        <LabelColumn>
          <Text>Date</Text>
        </LabelColumn>
        <ValueColumn>
          <DateInputs
            date={entry.date}
            onChange={(value) => {
              handleUpdateAttribute('date', value)
            }}
          />
        </ValueColumn>
      </FlexRow>
      <FlexRow>
        <LabelColumn>
          <Text>Actions</Text>
        </LabelColumn>
        <ValueColumn flexWrap="wrap">
          {actionsFormatted.map(({ id, label }) => {
            const name = `check-${id}`
            const entryAction = entry.tags.find(action => action.id === id)
            return (
              <Box key={id} width={[1/2, 1/3]} p={2}>
                <input
                  type="checkbox"
                  id={name}
                  name={name}
                  checked={entryAction.checked}
                  onChange={e => {
                    handleSelectAction(id, e.target.checked)
                  }}
                />
                <label htmlFor={name}>{label}</label>
              </Box>)
          })}
        </ValueColumn>
      </FlexRow>
      <FlexRow>
        <LabelColumn>
          <Text>Images</Text>
        </LabelColumn>
        <ImageContainer
          flexDirection="column"
          onSortEnd={({oldIndex, newIndex}) => {
            handleUpdateAttribute('images', arrayMove(entry.images, oldIndex, newIndex))
          }}
          useDragHandle
          helperClass="sortable-container"
        >
          {entry.images.map((img, idx) => (
            <ImageRow
              key={img.id}
              index={idx}
              image={img}
              itemOptions={items}
              deleteImage={() => {
                handleDeleteImage(idx)
              }}
              updateImage={(val: ImageData) => handleUpdateImage(idx, val)}
            />
          ))}
        </ImageContainer>
        <Dropzone onDrop={handleFileInputChange}/>
      </FlexRow>
      <FlexRow>
        <LabelColumn>
          <Text>Notes</Text>
        </LabelColumn>
        <ValueColumn>
          <Textarea
            value={entry.text}
            spellcheck="false"
            onChange={(e) => {
              handleUpdateAttribute('text', e.target.value)
            }}
          />
        </ValueColumn>
      </FlexRow>
      <Flex
        justifyContent="flex-end"
        alignItems="center"
        p={3}
      >
        {displayErrors && !!errorMessages.length && (
          <Text variant="error">{errorMessages.join(' ')}</Text>
        )}
        <Button
          variant="cancel"
          onClick={handleCancel}
          sx={{ mr: 4 }}
        >Cancel</Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
        >Save</Button>
      </Flex>
    </Box>
  )
}, shouldComponentNotUpdate)
