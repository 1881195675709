import { Card } from 'components/Card'
import * as React from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import { Flex, Text } from 'rebass'

type DropzoneProps = DropzoneOptions & {
  text: string
}
export const Dropzone: React.FC<DropzoneProps> = (props) => {
  const { text, ...restProps } = props
  const { getRootProps, getInputProps } = useDropzone(restProps)
  return (
    <Card variant="well" width={1}>
      <Flex
        {...getRootProps() as any}
        justifyContent="center"
        p={4}
        width="100%"
      >
        <input {...getInputProps()} />
        <Text>{text}</Text>
      </Flex>
    </Card>
  )
}
