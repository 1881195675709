import 'isomorphic-fetch'

export const fetchApi = (path: string, opts: any) => {
  let { ctx, headers = {} } = opts
  delete opts.ctx

  if(!(process as any).browser && !ctx)
    throw Error("Forgot to pass ctx for fetch")

  opts.credentials = 'include'

  if(ctx && ctx.req) {
    headers['Cookie'] = ctx.req.headers.cookie
  }

  opts.headers = headers

  const url = '/api' + path
  return fetch(url, opts)
}
