import * as React from 'react'
import { Card } from '../Card'
import { Image, Box, Flex, Text } from 'rebass'
import { FormattedIdentification, Link } from '../text'
import { Icon } from '../Icon'
import { Item } from '../../types/queries'

const CardLabel = (props) => (
  <Flex
    bg="white"
    opacity={0.75}
    flexDirection="column"
    justifyContent="center"
    alignItems="start"
    minHeight="40px"
    width={[4/5, 3/4]}
    p={2}
  >
    {props.children}
  </Flex>
)

const ImagePlaceholder = (props) => (
  <Flex
    minHeight={'300px'}
    justifyContent="center"
    alignItems="center"
    sx={{ borderRadius: 1, backgroundColor: 'white'}}
  >
    <Icon
      name="seedling"
      size="140px"
      color="#f0f0f0"
      sx={{
        '&:hover': {
          color: '#f0f0f0'
        }
      }}
    />
  </Flex>
)

interface Props {
  plant?: Item
  item: Item
}

export const PlantCard: React.FunctionComponent<Props> = ({ item }) => {
  const { coverImage, name, identification, user, id } = item
  return (
    <Box
      px={3}
      py={3}
      width={[1/3, 1/3, 1/4]}
    >
      <Link
        to={`/u/${user.username}/items/${id}`}
        width={'100%'}
      >
        <Card
          variant="secondary"
          p={0}
          height="100%"
          sx={{
            position: 'relative',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'link'
            },
            width: '100%'
          }}
        >
          {coverImage ? <Image src={coverImage} minHeight={'300px'} sx={{ objectFit: 'cover', borderRadius: '2px'}}/>
            : <ImagePlaceholder />
          }
          <CardLabel>
            <Text variant="h3" textAlign="left">{name}</Text>
            {identification && (
                <Text
                  key={id}
                  fontFamily="data"
                  fontSize={2}
                  color="grey.7"
                  textAlign="left"
                ><FormattedIdentification identification={identification}/></Text>
              )}
          </CardLabel>
        </Card>
      </Link>
    </Box>
  )
}
