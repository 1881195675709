import * as React from 'react'
import { Box, Flex, Button } from 'rebass'
// import { Input } from '@rebass/forms'
import { Card } from '../../components/Card'
import { CardTitle } from '../../components/text'
import { Input } from '../../components/Input'
import { useRequestPasswordResetMutation } from '../../types/queries'
import { Formik, ErrorMessage } from 'formik'
import * as yup from 'yup'

const passwordResetRequestValidation = yup.object().shape({
  email: yup.string().email('Invalid email address').required('Required'),
})

export const RequestPasswordReset: React.FC<{}> = (props) => {
  const [hasRequested, setHasRequested] = React.useState(false)
  const [requestPasswordReset] = useRequestPasswordResetMutation()

  const handleSubmitRequest = async (values) => {
    await requestPasswordReset({
      variables: {
        email: values.email
      }
    })
    setHasRequested(true)
  }
  return (
    <Box>
      <Card>
        <CardTitle>
          Request Password Reset Link
        </CardTitle>
        {hasRequested && (
          <Box>
            Request sent successfully. Reset instructions will be sent to the submitted email if an account registered with that email exists.
          </Box>
        )}
        <Formik
          initialValues={{ email: '' }}
          validationSchema={passwordResetRequestValidation}
          onSubmit={handleSubmitRequest}
          validateOnBlur={true}
          validateOnChange={false}
        >
          {({ handleSubmit, handleChange, values, errors }) => {
            return (
              <form onSubmit={handleSubmit}>
                <label htmlFor="email">
                  Email
                </label>
                <Box>
                  <ErrorMessage name="email"/>
                </Box>
                <Input
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                <Flex justifyContent="flex-end">
                  <Button>Submit</Button>
                </Flex>
              </form>
            )
          }}
        </Formik>
      </Card>

    </Box>
  )
}
