import * as React from 'react'
import { Flex } from 'rebass'
import { Checkbox, Label, LabelProps } from '@rebass/forms'
import { Icon } from '../Icon'

type LabelCheckboxProps = LabelProps & {
  checked: boolean
  text: string
  handleChange: (checked: boolean) => void
}
export const LabelCheckbox: React.FC<LabelCheckboxProps> = (props) => {
  const { text, handleChange, checked, ...restProps } = props
  return (
    <Label
      onClick={() => handleChange(!checked)}
      variant={checked ? 'labelChecked' : 'label'}
      sx={{
        position: 'relative',
        alignItems: 'center',
        width: 'fit-content',
      }}
      {...restProps}
    >
      {checked && (
        <Icon
          size="12px"
          name="check"
          mx={1}
          minWidth="fit-content"
          sx={{ position: 'relative', top: '2px' }}
        />)}
      <Flex
        minWidth="fit-content"
        ml={checked ? 1 : 0}
      >{text}</Flex>
    </Label>
  )
}
