import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from 'emotion-theming'
import { theme } from './theme'
import { client } from './client'
import { ApolloProvider } from '@apollo/client'
const mixpanel = require('mixpanel-browser')

mixpanel.init('c2ed7acfd383cacf3a190dc34b9bd199', {
  debug: process.env.NODE_ENV !== 'production'
})

const Root = () => {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </ThemeProvider>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
