import { IEditItem } from './'

export const editItemDefaultState: IEditItem = {
  name: '',
  description: '',
  source: '',
  tags: [],
  identification: null,
}

interface UpdateSingleAttributeAction {
  type: 'UPDATE_ATTRIBUTE'
  attribute: string
  value: any
}

interface ResetStateAction {
  type: 'RESET_STATE'
}

type AddItemSingleAction = UpdateSingleAttributeAction | ResetStateAction
export const editItemReducer = (state: IEditItem, action: AddItemSingleAction): IEditItem => {
  switch (action.type) {
    case 'UPDATE_ATTRIBUTE':
      return { ...state, [action.attribute]: action.value }
    case 'RESET_STATE':
      return { ...editItemDefaultState }
    default:
      return state
  }
}