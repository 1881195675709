import * as React from 'react'
import { Input as BaseInput, InputProps as RebassInputProps } from '@rebass/forms'
import { Text } from 'rebass'
export { Search } from './search'

type InputProps = Omit<React.HTMLProps<HTMLInputElement>, 'ref'> & {
  // label?: string
  placeholder?: string
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
  type?: string
  error?: string
  name?: string
  variant?: string
  // symbol?: 'money' | 'lock'
  // ref?: string | React.Ref<{}>
} & RebassInputProps


export const Input: React.FunctionComponent<InputProps> = ({ className, label, name, placeholder, onChange, value = '', type = 'text', error, ...rest }, ref) => {
  const hasError = !!error
  return (
    <>
      {hasError && <Text variant="error">{error}</Text>}
      <BaseInput
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        name={name}
        variant={hasError ? 'errorInput' : 'input'}
        type={type}
        {...rest}
      />
    </>
  )
}

// export const Input = React.forwardRef(BaseInput)
