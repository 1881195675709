import * as React from 'react'
import { useTracking } from 'react-tracking'
import { FlexRow } from '../../components/table'
import moment from 'moment'
import {
  Action,
  AddEntryMutationOptions,
  Item,
} from '../../types/queries'
import {
  EditEntry,
  editEntryActions,
  editEntryReducer,
  defaultInitialState
} from '../../components/EditEntry'
import { entryTagOptions as actions } from '../../utils/entry-tags'
import { ADD_ENTRY } from 'analytics/events'

interface AddEntryProps {
  addEntry: (options: AddEntryMutationOptions) => void
  handleCancel: () => void
  items: Item[]
  selectedItems?: Item[]
  selectedActions?: Action[]
}

export const AddEntryContent: React.FunctionComponent<AddEntryProps> = (props) => {
  const { addEntry, items, selectedItems = [], selectedActions = [], handleCancel } = props
  const { trackEvent } = useTracking()
  const [displayErrors, setDisplayErrors] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [state, dispatch] = React.useReducer(editEntryReducer, {
    ...defaultInitialState,
    tags: actions.map(action => ({
      ...action,
      checked: selectedActions.includes(action)
    })),
    items: selectedItems
  })

  const errorMessages = []
  const noItemsSelected = !(state.items && state.items.length)
  const hasSelectedTags = !!(state.tags && state.tags.length && state.tags.some(t => t.checked))
  const hasAddedImages = !!(state.images && state.images.length)
  const hasAddedText = !!(state.text && state.text.length)
  const isEmptyEntry = !hasSelectedTags && !hasAddedImages && !hasAddedText
  const isFutureDate = moment(state.date).isAfter(moment())
  if (noItemsSelected) errorMessages.push('No items selected.')
  if (isEmptyEntry) errorMessages.push('No entry content.')
  if (isFutureDate) errorMessages.push('Invalid date.')
  const isSubmitDisabled = !state.date || isFutureDate || noItemsSelected || isEmptyEntry
  const handleSubmit = async () => {
    if (isSubmitDisabled) {
      setDisplayErrors(true)
      return
    }
    setIsSubmitting(true)
    const images = await Promise.all(state.images.map(async img => {
      const file = await fetch(img.src).then(r => r.blob())
      const imgItems = img.items || []
      return {
        file,
        text: img.text,
        items: imgItems.map(item => ({ id: item.id }))
      }
    }))
    const selectedActions = state.tags.filter(tag => tag.checked)
    await addEntry({
      variables: {
        entry: {
          text: state.text,
          date: state.date.toString(),
          items: state.items.map(item => ({ id: item.id })),
          images,
          actions: selectedActions.map(tag => ({ id: tag.id, abbreviation: tag.abbreviation })),
        }
      }
    })
    trackEvent({
      event: ADD_ENTRY,
      plantCount: state.items.length,
      actionCount: selectedActions.length,
      imageCount: images.length,
      actions: selectedActions.map(a => a.label),
      notesLength: state.text.length,
    })
  }

  const handleAddFiles = React.useCallback((files) => {
    dispatch(editEntryActions.addFiles(files))
  }, [])
  const handleDeleteImage = React.useCallback((idx) => {
    dispatch(editEntryActions.deleteImage(idx))
  }, [])
  const handleSelectAction = React.useCallback((actionId, value) => {
    dispatch({
      type: 'TAG_CHECKED',
      tagId: actionId,
      checked: value
    })
  }, [])
  const handleUpdateAttribute = React.useCallback((attr, value) => {
    dispatch(editEntryActions.updateAttribute(attr, value))
  }, [])
  const handleUpdateImage = React.useCallback((idx, value) => {
    dispatch(editEntryActions.updateImage(idx, value))
  }, [])

  return (
    <>
      {isSubmitting && <FlexRow>Submitting....</FlexRow>}
      <EditEntry
        entry={state}
        items={items}
        displayErrors={displayErrors}
        handleAddFiles={handleAddFiles}
        handleDeleteImage={handleDeleteImage}
        handleSelectAction={handleSelectAction}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        handleUpdateAttribute={handleUpdateAttribute}
        handleUpdateImage={handleUpdateImage}
      />
    </>
  )
}
