import * as React from 'react'
import { Box, BoxProps} from 'rebass'

type CardProps = BoxProps & { css?: any }
export const Card: React.FC<CardProps> = props => {
  return (
    <Box
      tx="cards"
      variant="primary"
      p={1}
      {...props}
    />
  )
}
