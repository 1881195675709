import * as React from 'react';
import GraphiQL from 'graphiql'
import fetch from 'isomorphic-fetch'
import '../../../node_modules/graphiql/graphiql.css'
// const defaultQuery = `
//   {
//       currentUser {
//           firstName
//           lastName
//           email
//           teams {
//             name
//             description
//           }
//       }
//   }
// `
function graphQLFetcher(graphQLParams) {
  return fetch('/api/graphql', {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(graphQLParams),
  }).then(response => response.json());
}
class Admin extends React.Component {
  render() {
    return (
      <div style={{ height: '1000px' }}>
        <GraphiQL
          fetcher={graphQLFetcher}
          // defaultQuery={defaultQuery}
        />
      </div>
    );
  }
}

export default Admin