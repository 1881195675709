import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Box, Flex, Image, Text, Button } from 'rebass'
import * as d3 from 'd3'
import { useTracking } from 'react-tracking'
import { ImagePlaceholder } from '../../components/ImagePlaceholder'
import { Card } from '../../components/Card'
import { PageContent } from '../../components/layout'
import { LoadingEllipsisText } from '../../components/loading'
import { Modal } from '../../components/Modal'
import {
  PageTitle,
  CardTitle,
  FormattedIdentification,
  Link,
  SectionTitle,
  Tag,
} from '../../components/text'
import { IsOwner } from '../../components/IsOwner'
import { IEditItem } from '../../components/EditItem'
import { EditItemDetails } from './edit-item-details'
import { Entry } from '../../components/Entry'
import { FlexRow, LabelColumn } from '../../components/table'
import moment from 'moment'
import {
  Item,
  useGetItemQuery,
  useUpdateItemMutation,
} from '../../types/queries'

const ItemDetails: React.FC<{ item: Item }> = ({ item }) => {
  const {
    acquiredDate,
    identification,
    description,
    source,
    tags = [],
  } = item

  const tagColorScale = d3.scaleOrdinal(d3.schemePastel1)
  return (
    <Flex flexDirection="column" mx={-2}>
      <FlexRow>
        <LabelColumn>
          Identification
        </LabelColumn>
        <Flex width={3 / 4} alignItems="center" p={1}>
          {identification && (
            <FormattedIdentification identification={identification} />
          )}
        </Flex>
      </FlexRow>
      {description && (
        <FlexRow>
          <LabelColumn>
            Description
          </LabelColumn>
          <Flex width={3 / 4} alignItems="center" p={1}>
            {description}
          </Flex>
        </FlexRow>
      )}
      {acquiredDate && (
        <FlexRow>
          <LabelColumn>
            Date Acquired
          </LabelColumn>
          <Flex width={3 / 4} alignItems="center" p={1}>
            {moment(acquiredDate).format('MMMM YYYY')}
          </Flex>
        </FlexRow>
      )}
      {source && (
        <FlexRow>
          <LabelColumn>
            Source
          </LabelColumn>
          <Flex width={3 / 4} alignItems="center" p={1}>
            {source}
          </Flex>
        </FlexRow>
      )}
      {!!tags.length && (
        <FlexRow>
          <LabelColumn>
            Tags
          </LabelColumn>
          <Flex width={3 / 4} alignItems="center" p={1}>
            {tags.map(tag => {
              return (
                <Tag
                  key={tag.id}
                  color={tagColorScale(tag.id)}
                  tag={tag}
                />
              )
            })}
          </Flex>
        </FlexRow>
      )}
    </Flex>
  )
}

interface ItemPageProps {}
export const ItemPage: React.FunctionComponent<ItemPageProps> = (props) => {
  const { itemId, username } = useParams<{ itemId: string, username: string }>()
  const { Track } = useTracking({
    page: 'Plant detail',
    plantId: itemId,
    usernameViewed: username,
  })
  const { data, loading } = useGetItemQuery({
    variables: { itemId }
  })
  const [isEditing, setIsEditing] = React.useState(false)
  const [updateItem, { loading: updateItemLoading }] = useUpdateItemMutation({
    onCompleted: () => {
      setIsEditing(false)
    }
  })
  const handleSubmitUpdateItem = React.useCallback(async (itemEdit: IEditItem) => {
    const tags = itemEdit.tags || []
    await updateItem({
      variables: {
        item: {
          id: itemId,
          name: itemEdit.name,
          description: itemEdit.description,
          acquiredDate: itemEdit.acquiredDate ? itemEdit.acquiredDate.toString() : null,
          identification: {id: itemEdit.identification.id},
          source: itemEdit.source,
          tags: tags.map(t => ({id: t.id, name: t.label})),
          originDate: itemEdit.originDate ? itemEdit.originDate.toString() : null,
          coverImage: itemEdit.coverImage
        }
      }
    })
  }, [updateItem, itemId])
  const handleCancelEdit = React.useCallback(() => setIsEditing(false), [])



  if (loading) {
    return (
      <PageContent><LoadingEllipsisText /></PageContent>
    )
  } else if (!(data && data.item)) {
    return (
      <PageContent>
        <Card>
          <PageTitle>Page not found</PageTitle>
        </Card>
      </PageContent>
    )
  }

  const { item } = data
  const {
    entries,
    coverImage,
    user,
  } = item

  const noEntries = !(entries && entries.length)

  return (
    <Track>
      <PageContent>
        <Flex flexWrap="wrap">
          <Flex
            width={[1, 1, 1/3]}
            flexDirection="column"
            alignItems="center"
            sx={{ borderRight: 'standard' }}
            height="100%"
          >
            <Text
              variant="h2"
              p={2}
              sx={{ display: 'block', width: '100%' }}
            >
              <Link
                to={{ pathname: `/u/${username}` }}
                variant="breadcrumb"
                mr={2}
              >
                {username}
              </Link>
              &gt;
              <Link
                to={{ pathname: `/u/${username}` }}
                variant="breadcrumb"
                mx={2}
              >
                Collection
              </Link>
              &gt; {item.name}
            </Text>
            {coverImage ? (
              <Image
                src={coverImage}
                minHeight={'300px'}
                maxHeight={'500px'}
                sx={{
                  objectFit: 'cover',
                  borderRadius: '2px',
                  border: 'none',
                  mb: 3
                }}
              />
            ) : <ImagePlaceholder width="100%" iconSize="150px"/>}
            <Box width="100%" p={1}>
              <CardTitle
                renderContent={() => (
                  <IsOwner ownerId={user.id}>
                    <Button
                      variant="text"
                      onClick={() => setIsEditing(true)}
                    >
                      Edit
                    </Button>
                  </IsOwner>
                )}
              >
                Details
              </CardTitle>
              <ItemDetails
                item={item}
              />
            </Box>
          </Flex>
          <Box width={[1, 1, 2/3]}>
            <SectionTitle>Entries</SectionTitle>
            {noEntries && (
              <Card>
                <Flex justifyContent="center" p={3}>
                  <Text>No entries yet!</Text>
                </Flex>
              </Card>
            )}
            {!noEntries && entries.map(entry => (<Entry entry={entry} />))}
          </Box>
        </Flex>
        {!!isEditing && (
          <Modal isOpen={isEditing} handleClose={handleCancelEdit}>
            <EditItemDetails
              item={item}
              handleSubmit={handleSubmitUpdateItem}
              isSubmitting={updateItemLoading}
              handleCancelEdit={handleCancelEdit}
            />
          </Modal>
        )}
      </PageContent>
    </Track>
  )
}
