import { Identification } from '../../types/queries'
import { IEditItem } from '../../components/EditItem'
import { NewIdentification } from './id-dropdown'
import { get } from 'lodash'
import moment from 'moment'
import uuidv4 from 'uuid/v4'

interface ParseResultItem {
  Name: string
  Genus: string
  'Specific Epithet': string
  Subspecies: string
  Variety: string
  Subvariety: string
  Form: string
  Subform: string
  Cultivar: string
  Description: string
  'Date Acquired': string
  Source: string
  'Water Frequency': string
  'Fertilizer Frequency': string
  'Next Water': string
  'Next Fertilizer': string
  'Tag 1': string
  'Tag 2': string
  'Tag 3': string
  'Tag 4': string
  'Tag 5': string
}

interface ParseResult {
  data: ParseResultItem[]
}

const getIdentification = (resultItem: ParseResultItem, allIdentifications: Identification[]): Identification | NewIdentification => {
  const foundIdentification = allIdentifications.find(identification => {
    const genusMatches = resultItem.Genus === identification.genus.name
    const speciesMatches = resultItem['Specific Epithet'] ? resultItem['Specific Epithet'].toLowerCase() === get(identification, 'species.name')
      : !identification.species
    const subspeciesMatches = resultItem.Subspecies ? resultItem.Subspecies === identification.subspecies
      : !identification.subspecies
    const varietyMatches = resultItem.Variety ? resultItem.Variety === identification.variety
      : !identification.variety
    const subvarietyMatches = resultItem.Subvariety ? resultItem.Subvariety === identification.subvariety
      : !identification.subvariety
    const formMatches = resultItem.Form ? resultItem.Form === identification.form
      : !identification.form
    const subformMatches = resultItem.Subform ? resultItem.Subform === identification.subform
      : !identification.subform
    const cultivarMatches = resultItem.Cultivar ? resultItem.Cultivar === identification.cultivar
      : !identification.cultivar
    return genusMatches && speciesMatches && subspeciesMatches && varietyMatches && subvarietyMatches
      && formMatches && subformMatches && cultivarMatches
  })
  return foundIdentification ? foundIdentification : {
    id: 'new',
    genus: resultItem.Genus,
    species: resultItem['Specific Epithet'],
    subspecies: resultItem.Subspecies,
    variety: resultItem.Variety,
    subvariety: resultItem.Subvariety,
    form: resultItem.Form,
    subform: resultItem.Subform,
    cultivar: resultItem.Cultivar,
    commonNames: []
  }
}

export const parseCsvResults = (result: ParseResult, allIdentifications: Identification[]): IEditItem[] => {
  return result.data.map(resultItem => {
    const tags = [resultItem['Tag 1'], resultItem['Tag 2'], resultItem['Tag 3'], resultItem['Tag 4'], resultItem['Tag 5']]
      .filter(t => !!t)
      .map(tag => ({ label: tag }))
    return {
      name: resultItem.Name,
      description: resultItem.Description,
      acquiredDate: resultItem['Acquired Date'] ? moment(resultItem['Acquired Date'], 'M/YYYY').toDate() : undefined,
      originDate: resultItem['Origin Date'] ? moment(resultItem['Origin Date'], 'M/YYYY').toDate() : undefined,
      identification: getIdentification(resultItem, allIdentifications),
      source: resultItem.Source,
      waterFrequency: resultItem['Water Frequency'] ? parseInt(resultItem['Water Frequency']) : null,
      fertilizerFrequency: resultItem['Fertilizer Frequency'] ? parseInt(resultItem['Fertilizer Frequency']) : null,
      nextWater: resultItem['Next Water'] ? moment(resultItem['Next Water'], 'M/D/YYYY').toDate() : undefined,
      nextFertilizer: resultItem['Next Fertilizer'] ? moment(resultItem['Next Fertilizer'], 'M/D/YYYY').toDate() : undefined,
      tags,
      tempId: uuidv4()
    }
  })
}
