import * as React from 'react'
import moment from 'moment'
import Select from 'react-select'
import { Flex } from 'rebass'
import { Icon } from '../Icon'

interface MonthYearInputProps {
  date?: Date
  onChange: (val: Date) => void
  monthYearOnly?: boolean
  isClearable?: boolean
  isFuture?: boolean
}

const monthOptions = moment.months().map((month, idx) => ({
  value: idx,
  label: month
}))
const currentYear = moment().year()
const yearOptions = []
for (let yr = currentYear; yr > 1910; yr--) {
  yearOptions.push({
    value: yr,
    label: yr
  })
}
const futureYearOptions = [
  { value: currentYear, label: currentYear },
  { value: currentYear + 1, label: currentYear + 1 },
]

const dayOptions = []
for (let d = 1; d <= 31; d++) {
  dayOptions.push({
    value: d,
    label: d
  })
}

export const DateInputs: React.FunctionComponent<MonthYearInputProps> = (props) => {
  const { date, onChange, monthYearOnly, isClearable, isFuture } = props

  let monthValue = null
  let yearValue = null
  let dayValue = null

  const handleChangeMonthValue = (opt): void => {
    const newDate = moment(date).month(opt.value).toDate()
    onChange(newDate)
  }
  const handleChangeYearValue = (opt): void => {
    const newDate = moment(date).year(opt.value).toDate()
    onChange(newDate)
  }
  const handleChangeDayValue = (opt): void => {
    const newDate = moment(date).date(opt.value).toDate()
    onChange(newDate)
  }
  const handleClear = () => {
    onChange(null)
  }

  if (!!date) {
    const month = moment(date).month()
    monthValue = monthOptions.find(opt => opt.value === month)
    const year = moment(date).year()
    yearValue = yearOptions.find(opt => opt.value === year)
    const day = moment(date).date()
    dayValue = dayOptions.find(opt => opt.value === day)
  }
  return (
    <Flex width={1} alignItems="center">
      <Select
        options={monthOptions}
        value={monthValue}
        onChange={handleChangeMonthValue}
        styles={{
          container: () => ({
            width: '100%',
            position: 'relative',
            paddingRight: '5px'
          })
        }}
        placeholder="Month"
      />
      {!monthYearOnly && (
        <Select
          options={dayOptions}
          value={dayValue}
          onChange={handleChangeDayValue}
          styles={{
            container: () => ({
              width: '100%',
              position: 'relative',
              paddingRight: '5px'
            })
          }}
          placeholder="Day"
        />
      )}
      <Select
        options={isFuture ? futureYearOptions : yearOptions}
        value={yearValue}
        onChange={handleChangeYearValue}
        styles={{
          container: () => ({
            width: '100%',
            position: 'relative'
          })
        }}
        placeholder="Year"
      />
      {isClearable && (
        <Icon
          name="x"
          size="12px"
          m={2}
          variant="cancelButton"
          onClick={handleClear}
        />
        )}
    </Flex>
  )
}
