import { IEditEntry } from './'
import { entryTagOptions as actions } from '../../utils/entry-tags'
import { ImageData } from './image-row'


export const actionsFormatted = actions.map((tag) => ({
  ...tag,
  checked: false
}))

interface UpdateAttributeAction {
  type: 'UPDATE_ATTRIBUTE'
  attribute: string
  value: any
}
interface TagCheckedAction {
  type: 'TAG_CHECKED'
  tagId: string
  checked: boolean
}
interface AddFilesAction {
  type: 'ADD_FILES'
  files: ImageData[]
}
interface UpdateImageAction {
  type: 'UPDATE_IMAGE'
  idx: number
  value: ImageData
}
interface DeleteImageAction {
  type: 'DELETE_IMAGE'
  idx: number
}
interface ResetAttributesAction {
  type: 'RESET_ATTRIBUTES'
}

const addFiles = (files: ImageData[]): AddFilesAction => ({
  type: 'ADD_FILES', files
})
const deleteImage = (imageIdx: number): DeleteImageAction => ({
  type: 'DELETE_IMAGE',
  idx: imageIdx
})
const updateAttribute = (attr: string, value: any): UpdateAttributeAction => {
  return {
    type: 'UPDATE_ATTRIBUTE',
    value,
    attribute: attr
  }
}
const updateImage = (imageIdx: number, value: ImageData): UpdateImageAction => {
  return {
    type: 'UPDATE_IMAGE',
    value,
    idx: imageIdx
  }
}

export const editEntryActions = {
  addFiles,
  deleteImage,
  updateAttribute,
  updateImage
}

export const defaultInitialState: IEditEntry = {
  items: [],
  date: new Date(),
  tags: actionsFormatted,
  text: '',
  images: []
}

type AddEntryAction = UpdateAttributeAction | TagCheckedAction | AddFilesAction | UpdateImageAction
  | ResetAttributesAction | DeleteImageAction
export const editEntryReducer = (state: IEditEntry, action: AddEntryAction): IEditEntry => {
  switch (action.type) {
    case 'UPDATE_ATTRIBUTE':
      return { ...state, [action.attribute]: action.value }
    case 'TAG_CHECKED':
      return {
        ...state,
        tags: state.tags.map(tag => {
          if (tag.id !== action.tagId) return tag
          return { ...tag, checked: action.checked }
        })
      }
    case 'ADD_FILES':
      return { ...state, images: state.images.concat(action.files) }
    case 'UPDATE_IMAGE':
      return {
        ...state,
        images: state.images.map((img, idx) => {
          if (idx !== action.idx) return img
          return action.value
        })
      }
    case 'DELETE_IMAGE':
      return {
        ...state,
        images: state.images.filter((img, idx) => {
          return idx !== action.idx
        })
      }
    case 'RESET_ATTRIBUTES':
      return {
        ...defaultInitialState,
      }
    default:
      return state
  }
}