import * as React from 'react'
import { PageContent } from 'components/layout'
import { LoadingEllipsisText } from 'components/loading'
import { Redirect } from 'react-router'
import { Box, Flex, Button, Text } from 'rebass'
import { useTracking } from 'react-tracking'
import { CardTitle } from '../../components/text'
import { FlexRow, LabelColumn, ValueColumn } from '../../components/table'
import { FormattedIdentification } from '../../components/text'
import { Modal } from '../../components/Modal'
import { IdDropdown } from '../dashboard/id-dropdown'
import { Textarea } from '@rebass/forms'
import {
  GetCurrentUserDocument,
  GetCurrentUserQueryHookResult,
  Identification,
  useAddIdWishListItemMutation, useGetCurrentUserQuery,
} from '../../types/queries'
import moment from 'moment'
import { ADD_WISH_LIST_ID } from 'analytics/events'

type IdWishListItem = GetCurrentUserQueryHookResult['data']['currentUser']['idWishList'][0]

interface AddWishListIdProps {
  handleSubmit: (value: { identification: Identification, comment: string }) => void
}
const AddWishListId: React.FunctionComponent<AddWishListIdProps> = ({ handleSubmit }) => {
  const [selectedId, setSelectedId] = React.useState<Identification | null>(null)
  const [comment, setComment] = React.useState('')

  const onClickSubmit = () => {
    handleSubmit({ identification: selectedId, comment })
  }
  return (
    <Box p={3}>
      <Text variant="h3">Add Identification to Wish List</Text>
      <FlexRow>
        <LabelColumn required>Identification</LabelColumn>
        <ValueColumn>
          <IdDropdown
            handleIdChange={(idVal) => {
              setSelectedId(idVal)
            }}
            idValue={selectedId}
          />
        </ValueColumn>
      </FlexRow>
      <FlexRow>
        <LabelColumn>Comment</LabelColumn>
        <ValueColumn>
          <Textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </ValueColumn>
      </FlexRow>
      <Flex p={3} width="100%" justifyContent="flex-end">
        <Button disabled={!selectedId} onClick={onClickSubmit}>Add</Button>
      </Flex>


    </Box>
  )
}

const WishListItem: React.FC<{ item: IdWishListItem }> = ({ item }) => {
  return (
    <Flex p={2} sx={{ borderBottom: 'standard', minHeight: 'fit-content' }}>
      <Box width={1/3}>
        <FormattedIdentification identification={item.identification as Identification}/>
      </Box>
      <Box width={1/6}>
        Added {moment(item.createdAt).format('M/D/YYYY')}
      </Box>
      <Box width={1/2}>
        {item.comment}
      </Box>
    </Flex>
  )
}

interface WishListProps {
}

export const WishList: React.FunctionComponent<WishListProps> = (props) => {
  const { Track, trackEvent } = useTracking({
    page: 'Wish List',
  })
  const { data, loading, error } = useGetCurrentUserQuery()

  const [isAddingWishListItem, setIsAdding] = React.useState(false)
  const [addIdWishListItem] = useAddIdWishListItemMutation({
    refetchQueries: [{
      query: GetCurrentUserDocument
    }]
  })

  const handleItemSubmit = async (value: { identification: Identification, comment: string }) => {
    const { identification, comment } = value
    await addIdWishListItem({
      variables: {
        item: {
          identification: { id: identification.id },
          comment
        }
      },
    })
    trackEvent({
      event: ADD_WISH_LIST_ID,
    })
    setIsAdding(false)
  }


  if (loading) return <PageContent><LoadingEllipsisText/></PageContent>
  if (error) return <div>Error.....</div>
  if (data && !data.currentUser) return <Redirect to="/" />

  const wishList = data.currentUser.idWishList

  return (
    <Track>
      <PageContent>
        <CardTitle>Your Wish List</CardTitle>
        <Flex p={3} justifyContent="flex-end">
          <Button onClick={() => setIsAdding(true)}>+ Add Identification</Button>
        </Flex>
        <Flex flexDirection="column">
          <Flex p={3}>
            <Flex width={1/3}>Identification</Flex>
            <Flex width={1/6}>Date Added</Flex>
            <Flex width={1/2}>Comments</Flex>
          </Flex>
          {wishList.map(item => {
            return (
              <WishListItem key={item.id} item={item} />
            )
          })}
        </Flex>
        {isAddingWishListItem && (
          <Modal handleClose={() => setIsAdding(false)} isOpen={true}>
            <AddWishListId handleSubmit={handleItemSubmit}/>
          </Modal>
        )}
      </PageContent>
    </Track>
  )
}
