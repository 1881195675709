import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: Date;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  users?: Maybe<Array<User>>;
  user?: Maybe<User>;
  currentUser?: Maybe<User>;
  validatePasswordResetToken?: Maybe<PasswordResetResponse>;
  identification?: Maybe<Identification>;
  identifications?: Maybe<Array<Identification>>;
  item?: Maybe<Item>;
  items?: Maybe<Array<Item>>;
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
  username?: Maybe<Scalars['String']>;
};


export type QueryValidatePasswordResetTokenArgs = {
  token: Scalars['String'];
};


export type QueryIdentificationArgs = {
  id: Scalars['ID'];
};


export type QueryItemArgs = {
  id: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Item>>;
  idWishList?: Maybe<Array<Maybe<IdWishListItem>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  entries?: Maybe<Array<Maybe<Entry>>>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type Item = {
  __typename?: 'Item';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  identification?: Maybe<Identification>;
  acquiredDate?: Maybe<Scalars['Date']>;
  originDate?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  entries?: Maybe<Array<Maybe<Entry>>>;
  user?: Maybe<User>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  image?: Maybe<Image>;
  coverImage?: Maybe<Scalars['String']>;
  waterFrequency?: Maybe<Scalars['Int']>;
  lastWater?: Maybe<Scalars['Date']>;
  nextWater?: Maybe<Scalars['Date']>;
  fertilizerFrequency?: Maybe<Scalars['Int']>;
  lastFertilizer?: Maybe<Scalars['Date']>;
  nextFertilizer?: Maybe<Scalars['Date']>;
};

export type Identification = {
  __typename?: 'Identification';
  id: Scalars['ID'];
  genus?: Maybe<Genus>;
  species?: Maybe<Species>;
  cultivar?: Maybe<Scalars['String']>;
  subspecies?: Maybe<Scalars['String']>;
  variety?: Maybe<Scalars['String']>;
  subvariety?: Maybe<Scalars['String']>;
  form?: Maybe<Scalars['String']>;
  subform?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  commonNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  images?: Maybe<Array<Maybe<Image>>>;
  posts?: Maybe<Array<Maybe<Post>>>;
  items?: Maybe<Array<Maybe<Item>>>;
};

export type Genus = {
  __typename?: 'Genus';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Species = {
  __typename?: 'Species';
  id: Scalars['ID'];
  name: Scalars['String'];
  genus: Genus;
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID'];
  source: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  subject?: Maybe<Imageable>;
  items?: Maybe<Array<Maybe<Item>>>;
};

export type Imageable = Post | Entry | Item;

export type Post = {
  __typename?: 'Post';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  user?: Maybe<User>;
  content: Scalars['String'];
  comments?: Maybe<Array<Maybe<Comment>>>;
  subject?: Maybe<Postable>;
  images?: Maybe<Array<Maybe<Image>>>;
};


export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  createdAt: Scalars['Date'];
  user?: Maybe<User>;
  content?: Maybe<Scalars['String']>;
  subject?: Maybe<Commentable>;
};

export type Commentable = Entry | Post;

export type Entry = {
  __typename?: 'Entry';
  id: Scalars['ID'];
  date: Scalars['Date'];
  text?: Maybe<Scalars['String']>;
  actions?: Maybe<Array<Maybe<Action>>>;
  images?: Maybe<Array<Maybe<Image>>>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  items?: Maybe<Array<Maybe<Item>>>;
};

export type Action = {
  __typename?: 'Action';
  id: Scalars['ID'];
  label: Scalars['String'];
  abbreviation: Scalars['String'];
};

export type Postable = Identification | Family | Genus | Species;

export type Family = {
  __typename?: 'Family';
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name: Scalars['String'];
  items?: Maybe<Array<Maybe<Item>>>;
};

export type IdWishListItem = {
  __typename?: 'IdWishListItem';
  id: Scalars['ID'];
  identification: Identification;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type PasswordResetResponse = {
  __typename?: 'PasswordResetResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  updateUser?: Maybe<User>;
  addIdWishListItem?: Maybe<IdWishListItem>;
  requestPasswordReset?: Maybe<PasswordResetResponse>;
  updateUserPassword?: Maybe<PasswordResetResponse>;
  confirmEmail?: Maybe<PasswordResetResponse>;
  addIdentification?: Maybe<Identification>;
  updateIdentification?: Maybe<Identification>;
  addItem?: Maybe<Item>;
  addItems?: Maybe<Array<Maybe<Item>>>;
  updateItem?: Maybe<Item>;
  addPost?: Maybe<Post>;
  updatePost?: Maybe<Post>;
  addEntry?: Maybe<Entry>;
  updateEntry?: Maybe<Entry>;
};


export type MutationUpdateUserArgs = {
  user?: Maybe<UserInput>;
};


export type MutationAddIdWishListItemArgs = {
  item?: Maybe<IdWishListItemInput>;
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['String'];
};


export type MutationUpdateUserPasswordArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
};


export type MutationConfirmEmailArgs = {
  confirmationCode: Scalars['String'];
};


export type MutationAddIdentificationArgs = {
  identification?: Maybe<IdentificationInput>;
};


export type MutationUpdateIdentificationArgs = {
  identification?: Maybe<IdentificationInput>;
};


export type MutationAddItemArgs = {
  item: ItemInput;
};


export type MutationAddItemsArgs = {
  items: Array<ItemInput>;
};


export type MutationUpdateItemArgs = {
  item?: Maybe<ItemInput>;
};


export type MutationAddPostArgs = {
  post?: Maybe<PostInput>;
};


export type MutationUpdatePostArgs = {
  post?: Maybe<PostInput>;
};


export type MutationAddEntryArgs = {
  entry?: Maybe<EntryInput>;
};


export type MutationUpdateEntryArgs = {
  entry?: Maybe<EntryInput>;
};

export type UserInput = {
  id?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['Upload']>;
  bio?: Maybe<Scalars['String']>;
};


export type IdWishListItemInput = {
  id?: Maybe<Scalars['ID']>;
  identification?: Maybe<IdentificationInput>;
  comment?: Maybe<Scalars['String']>;
};

export type IdentificationInput = {
  id?: Maybe<Scalars['ID']>;
  genus?: Maybe<GenusInput>;
  species?: Maybe<SpeciesInput>;
  cultivar?: Maybe<Scalars['String']>;
  subspecies?: Maybe<Scalars['String']>;
  variety?: Maybe<Scalars['String']>;
  subvariety?: Maybe<Scalars['String']>;
  form?: Maybe<Scalars['String']>;
  subform?: Maybe<Scalars['String']>;
  commonNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GenusInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SpeciesInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ItemInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  identification?: Maybe<IdentificationInput>;
  acquiredDate?: Maybe<Scalars['String']>;
  originDate?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<TagInput>>>;
  coverImage?: Maybe<Scalars['Upload']>;
  waterFrequency?: Maybe<Scalars['Int']>;
  nextWater?: Maybe<Scalars['String']>;
  fertilizerFrequency?: Maybe<Scalars['Int']>;
  nextFertilizer?: Maybe<Scalars['String']>;
};

export type TagInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PostInput = {
  id?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['String']>;
  subject?: Maybe<PostableInput>;
  postableType?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<ImageInput>>>;
};

export type PostableInput = {
  id?: Maybe<Scalars['ID']>;
};

export type ImageInput = {
  id?: Maybe<Scalars['ID']>;
  source?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<ItemInput>>>;
  file?: Maybe<Scalars['Upload']>;
};

export type EntryInput = {
  id?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  actions?: Maybe<Array<Maybe<ActionInput>>>;
  images?: Maybe<Array<Maybe<ImageInput>>>;
  items?: Maybe<Array<Maybe<ItemInput>>>;
};

export type ActionInput = {
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  _?: Maybe<Scalars['Boolean']>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type AddEntryMutationVariables = Exact<{
  entry?: Maybe<EntryInput>;
}>;


export type AddEntryMutation = (
  { __typename?: 'Mutation' }
  & { addEntry?: Maybe<(
    { __typename: 'Entry' }
    & Pick<Entry, 'id' | 'date'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Item' }
      & ItemFragment
    )>>>, images?: Maybe<Array<Maybe<(
      { __typename: 'Image' }
      & Pick<Image, 'id' | 'source' | 'text'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Item' }
        & ItemFragment
      )>>> }
    )>>>, actions?: Maybe<Array<Maybe<(
      { __typename?: 'Action' }
      & Pick<Action, 'id' | 'abbreviation' | 'label'>
    )>>> }
  )> }
);

export type AddIdentificationMutationVariables = Exact<{
  identification?: Maybe<IdentificationInput>;
}>;


export type AddIdentificationMutation = (
  { __typename?: 'Mutation' }
  & { addIdentification?: Maybe<(
    { __typename?: 'Identification' }
    & IdentificationFragmentFragment
  )> }
);

export type AddItemMutationVariables = Exact<{
  item: ItemInput;
}>;


export type AddItemMutation = (
  { __typename?: 'Mutation' }
  & { addItem?: Maybe<(
    { __typename?: 'Item' }
    & ItemFragment
  )> }
);

export type AddItemsMutationVariables = Exact<{
  items: Array<ItemInput> | ItemInput;
}>;


export type AddItemsMutation = (
  { __typename?: 'Mutation' }
  & { addItems?: Maybe<Array<Maybe<(
    { __typename?: 'Item' }
    & ItemFragment
  )>>> }
);

export type AddPostMutationVariables = Exact<{
  post?: Maybe<PostInput>;
}>;


export type AddPostMutation = (
  { __typename?: 'Mutation' }
  & { addPost?: Maybe<(
    { __typename?: 'Post' }
    & PostFragment
  )> }
);

export type AddIdWishListItemMutationVariables = Exact<{
  item?: Maybe<IdWishListItemInput>;
}>;


export type AddIdWishListItemMutation = (
  { __typename?: 'Mutation' }
  & { addIdWishListItem?: Maybe<(
    { __typename: 'IdWishListItem' }
    & Pick<IdWishListItem, 'id' | 'comment'>
    & { identification: (
      { __typename?: 'Identification' }
      & IdentificationFragmentFragment
    ) }
  )> }
);

export type ConfirmEmailMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type ConfirmEmailMutation = (
  { __typename?: 'Mutation' }
  & { confirmEmail?: Maybe<(
    { __typename?: 'PasswordResetResponse' }
    & Pick<PasswordResetResponse, 'success'>
  )> }
);

export type IdentificationFragmentFragment = (
  { __typename: 'Identification' }
  & Pick<Identification, 'id' | 'cultivar' | 'subspecies' | 'variety' | 'subvariety' | 'form' | 'subform' | 'commonNames' | 'formatted'>
  & { genus?: Maybe<(
    { __typename?: 'Genus' }
    & Pick<Genus, 'id' | 'name'>
  )>, species?: Maybe<(
    { __typename?: 'Species' }
    & Pick<Species, 'id' | 'name'>
  )> }
);

export type ItemFragment = (
  { __typename: 'Item' }
  & Pick<Item, 'id' | 'name' | 'description' | 'acquiredDate' | 'originDate' | 'createdAt' | 'source' | 'coverImage' | 'waterFrequency' | 'nextWater' | 'lastWater' | 'fertilizerFrequency' | 'nextFertilizer' | 'lastFertilizer'>
  & { identification?: Maybe<(
    { __typename?: 'Identification' }
    & IdentificationFragmentFragment
  )>, tags?: Maybe<Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )>>>, user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  )> }
);

export type IdWishListItemFragment = (
  { __typename: 'IdWishListItem' }
  & Pick<IdWishListItem, 'id' | 'comment' | 'createdAt'>
  & { identification: (
    { __typename?: 'Identification' }
    & IdentificationFragmentFragment
  ) }
);

export type PostFragment = (
  { __typename: 'Post' }
  & Pick<Post, 'id' | 'createdAt' | 'content'>
  & { images?: Maybe<Array<Maybe<(
    { __typename: 'Image' }
    & Pick<Image, 'id' | 'source' | 'text'>
  )>>>, comments?: Maybe<Array<Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'content' | 'createdAt'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username'>
    )> }
  )>>>, user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  )> }
);

export type EntryFragment = (
  { __typename: 'Entry' }
  & Pick<Entry, 'id' | 'date' | 'text'>
  & { comments?: Maybe<Array<Maybe<(
    { __typename: 'Comment' }
    & Pick<Comment, 'id' | 'createdAt'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username'>
    )> }
  )>>>, actions?: Maybe<Array<Maybe<(
    { __typename?: 'Action' }
    & Pick<Action, 'id' | 'label' | 'abbreviation'>
  )>>>, images?: Maybe<Array<Maybe<(
    { __typename: 'Image' }
    & Pick<Image, 'id' | 'source' | 'text'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Item' }
      & Pick<Item, 'id' | 'name'>
    )>>> }
  )>>>, items?: Maybe<Array<Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'name'>
  )>>> }
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'firstName' | 'profileImage' | 'createdAt' | 'email' | 'bio' | 'ipAddress'>
    & { items?: Maybe<Array<(
      { __typename?: 'Item' }
      & { entries?: Maybe<Array<Maybe<(
        { __typename?: 'Entry' }
        & EntryFragment
      )>>> }
      & ItemFragment
    )>>, tags?: Maybe<Array<Maybe<(
      { __typename: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )>>>, idWishList?: Maybe<Array<Maybe<(
      { __typename?: 'IdWishListItem' }
      & IdWishListItemFragment
    )>>> }
  )> }
);

export type GetIdentificationQueryVariables = Exact<{
  identificationId: Scalars['ID'];
}>;


export type GetIdentificationQuery = (
  { __typename?: 'Query' }
  & { identification?: Maybe<(
    { __typename?: 'Identification' }
    & { posts?: Maybe<Array<Maybe<(
      { __typename?: 'Post' }
      & PostFragment
    )>>>, images?: Maybe<Array<Maybe<(
      { __typename: 'Image' }
      & Pick<Image, 'id' | 'source' | 'text'>
    )>>>, items?: Maybe<Array<Maybe<(
      { __typename?: 'Item' }
      & ItemFragment
    )>>> }
    & IdentificationFragmentFragment
  )> }
);

export type GetIdentificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIdentificationsQuery = (
  { __typename?: 'Query' }
  & { identifications?: Maybe<Array<(
    { __typename?: 'Identification' }
    & IdentificationFragmentFragment
  )>> }
);

export type GetItemQueryVariables = Exact<{
  itemId: Scalars['ID'];
}>;


export type GetItemQuery = (
  { __typename?: 'Query' }
  & { item?: Maybe<(
    { __typename?: 'Item' }
    & { entries?: Maybe<Array<Maybe<(
      { __typename?: 'Entry' }
      & EntryFragment
    )>>> }
    & ItemFragment
  )> }
);

export type GetUserQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  username?: Maybe<Scalars['String']>;
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username' | 'firstName' | 'createdAt' | 'profileImage' | 'bio'>
    & { items?: Maybe<Array<(
      { __typename?: 'Item' }
      & ItemFragment
    )>>, tags?: Maybe<Array<Maybe<(
      { __typename: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )>>>, idWishList?: Maybe<Array<Maybe<(
      { __typename?: 'IdWishListItem' }
      & IdWishListItemFragment
    )>>> }
  )> }
);

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username'>
  )>> }
);

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & { requestPasswordReset?: Maybe<(
    { __typename?: 'PasswordResetResponse' }
    & Pick<PasswordResetResponse, 'success'>
  )> }
);

export type UpdateItemMutationVariables = Exact<{
  item: ItemInput;
}>;


export type UpdateItemMutation = (
  { __typename?: 'Mutation' }
  & { updateItem?: Maybe<(
    { __typename?: 'Item' }
    & ItemFragment
  )> }
);

export type UpdateUserPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type UpdateUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & { updateUserPassword?: Maybe<(
    { __typename?: 'PasswordResetResponse' }
    & Pick<PasswordResetResponse, 'success'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  user: UserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'firstName' | 'profileImage' | 'createdAt' | 'email' | 'bio' | 'ipAddress'>
    & { items?: Maybe<Array<(
      { __typename?: 'Item' }
      & { entries?: Maybe<Array<Maybe<(
        { __typename?: 'Entry' }
        & EntryFragment
      )>>> }
      & ItemFragment
    )>>, tags?: Maybe<Array<Maybe<(
      { __typename: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )>>>, idWishList?: Maybe<Array<Maybe<(
      { __typename?: 'IdWishListItem' }
      & IdWishListItemFragment
    )>>> }
  )> }
);

export type ValidatePasswordTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type ValidatePasswordTokenQuery = (
  { __typename?: 'Query' }
  & { validatePasswordResetToken?: Maybe<(
    { __typename?: 'PasswordResetResponse' }
    & Pick<PasswordResetResponse, 'success'>
  )> }
);

export const IdentificationFragmentFragmentDoc = gql`
    fragment IdentificationFragment on Identification {
  __typename
  id
  genus {
    id
    name
  }
  species {
    id
    name
  }
  cultivar
  subspecies
  variety
  subvariety
  form
  subform
  commonNames
  formatted
}
    `;
export const ItemFragmentDoc = gql`
    fragment Item on Item {
  __typename
  id
  identification {
    ...IdentificationFragment
  }
  name
  description
  acquiredDate
  originDate
  createdAt
  source
  coverImage
  waterFrequency
  nextWater
  lastWater
  fertilizerFrequency
  nextFertilizer
  lastFertilizer
  tags {
    id
    name
  }
  user {
    id
    username
  }
}
    ${IdentificationFragmentFragmentDoc}`;
export const IdWishListItemFragmentDoc = gql`
    fragment IdWishListItem on IdWishListItem {
  __typename
  id
  identification {
    ...IdentificationFragment
  }
  comment
  createdAt
}
    ${IdentificationFragmentFragmentDoc}`;
export const PostFragmentDoc = gql`
    fragment Post on Post {
  __typename
  id
  createdAt
  images {
    __typename
    id
    source
    text
  }
  content
  comments {
    id
    user {
      id
      username
    }
    content
    createdAt
  }
  user {
    id
    username
  }
}
    `;
export const EntryFragmentDoc = gql`
    fragment Entry on Entry {
  __typename
  id
  date
  comments {
    __typename
    id
    createdAt
    user {
      id
      username
    }
  }
  text
  actions {
    id
    label
    abbreviation
  }
  images {
    __typename
    id
    source
    text
    items {
      id
      name
    }
  }
  items {
    id
    name
  }
}
    `;
export const AddEntryDocument = gql`
    mutation AddEntry($entry: EntryInput) {
  addEntry(entry: $entry) {
    __typename
    id
    date
    items {
      ...Item
    }
    images {
      __typename
      id
      source
      text
      items {
        ...Item
      }
    }
    actions {
      id
      abbreviation
      label
    }
  }
}
    ${ItemFragmentDoc}`;
export type AddEntryMutationFn = Apollo.MutationFunction<AddEntryMutation, AddEntryMutationVariables>;

/**
 * __useAddEntryMutation__
 *
 * To run a mutation, you first call `useAddEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEntryMutation, { data, loading, error }] = useAddEntryMutation({
 *   variables: {
 *      entry: // value for 'entry'
 *   },
 * });
 */
export function useAddEntryMutation(baseOptions?: Apollo.MutationHookOptions<AddEntryMutation, AddEntryMutationVariables>) {
        return Apollo.useMutation<AddEntryMutation, AddEntryMutationVariables>(AddEntryDocument, baseOptions);
      }
export type AddEntryMutationHookResult = ReturnType<typeof useAddEntryMutation>;
export type AddEntryMutationResult = Apollo.MutationResult<AddEntryMutation>;
export type AddEntryMutationOptions = Apollo.BaseMutationOptions<AddEntryMutation, AddEntryMutationVariables>;
export const AddIdentificationDocument = gql`
    mutation AddIdentification($identification: IdentificationInput) {
  addIdentification(identification: $identification) {
    ...IdentificationFragment
  }
}
    ${IdentificationFragmentFragmentDoc}`;
export type AddIdentificationMutationFn = Apollo.MutationFunction<AddIdentificationMutation, AddIdentificationMutationVariables>;

/**
 * __useAddIdentificationMutation__
 *
 * To run a mutation, you first call `useAddIdentificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIdentificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIdentificationMutation, { data, loading, error }] = useAddIdentificationMutation({
 *   variables: {
 *      identification: // value for 'identification'
 *   },
 * });
 */
export function useAddIdentificationMutation(baseOptions?: Apollo.MutationHookOptions<AddIdentificationMutation, AddIdentificationMutationVariables>) {
        return Apollo.useMutation<AddIdentificationMutation, AddIdentificationMutationVariables>(AddIdentificationDocument, baseOptions);
      }
export type AddIdentificationMutationHookResult = ReturnType<typeof useAddIdentificationMutation>;
export type AddIdentificationMutationResult = Apollo.MutationResult<AddIdentificationMutation>;
export type AddIdentificationMutationOptions = Apollo.BaseMutationOptions<AddIdentificationMutation, AddIdentificationMutationVariables>;
export const AddItemDocument = gql`
    mutation AddItem($item: ItemInput!) {
  addItem(item: $item) {
    ...Item
  }
}
    ${ItemFragmentDoc}`;
export type AddItemMutationFn = Apollo.MutationFunction<AddItemMutation, AddItemMutationVariables>;

/**
 * __useAddItemMutation__
 *
 * To run a mutation, you first call `useAddItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemMutation, { data, loading, error }] = useAddItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *   },
 * });
 */
export function useAddItemMutation(baseOptions?: Apollo.MutationHookOptions<AddItemMutation, AddItemMutationVariables>) {
        return Apollo.useMutation<AddItemMutation, AddItemMutationVariables>(AddItemDocument, baseOptions);
      }
export type AddItemMutationHookResult = ReturnType<typeof useAddItemMutation>;
export type AddItemMutationResult = Apollo.MutationResult<AddItemMutation>;
export type AddItemMutationOptions = Apollo.BaseMutationOptions<AddItemMutation, AddItemMutationVariables>;
export const AddItemsDocument = gql`
    mutation AddItems($items: [ItemInput!]!) {
  addItems(items: $items) {
    ...Item
  }
}
    ${ItemFragmentDoc}`;
export type AddItemsMutationFn = Apollo.MutationFunction<AddItemsMutation, AddItemsMutationVariables>;

/**
 * __useAddItemsMutation__
 *
 * To run a mutation, you first call `useAddItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemsMutation, { data, loading, error }] = useAddItemsMutation({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useAddItemsMutation(baseOptions?: Apollo.MutationHookOptions<AddItemsMutation, AddItemsMutationVariables>) {
        return Apollo.useMutation<AddItemsMutation, AddItemsMutationVariables>(AddItemsDocument, baseOptions);
      }
export type AddItemsMutationHookResult = ReturnType<typeof useAddItemsMutation>;
export type AddItemsMutationResult = Apollo.MutationResult<AddItemsMutation>;
export type AddItemsMutationOptions = Apollo.BaseMutationOptions<AddItemsMutation, AddItemsMutationVariables>;
export const AddPostDocument = gql`
    mutation AddPost($post: PostInput) {
  addPost(post: $post) {
    ...Post
  }
}
    ${PostFragmentDoc}`;
export type AddPostMutationFn = Apollo.MutationFunction<AddPostMutation, AddPostMutationVariables>;

/**
 * __useAddPostMutation__
 *
 * To run a mutation, you first call `useAddPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPostMutation, { data, loading, error }] = useAddPostMutation({
 *   variables: {
 *      post: // value for 'post'
 *   },
 * });
 */
export function useAddPostMutation(baseOptions?: Apollo.MutationHookOptions<AddPostMutation, AddPostMutationVariables>) {
        return Apollo.useMutation<AddPostMutation, AddPostMutationVariables>(AddPostDocument, baseOptions);
      }
export type AddPostMutationHookResult = ReturnType<typeof useAddPostMutation>;
export type AddPostMutationResult = Apollo.MutationResult<AddPostMutation>;
export type AddPostMutationOptions = Apollo.BaseMutationOptions<AddPostMutation, AddPostMutationVariables>;
export const AddIdWishListItemDocument = gql`
    mutation AddIdWishListItem($item: IdWishListItemInput) {
  addIdWishListItem(item: $item) {
    __typename
    id
    identification {
      ...IdentificationFragment
    }
    comment
  }
}
    ${IdentificationFragmentFragmentDoc}`;
export type AddIdWishListItemMutationFn = Apollo.MutationFunction<AddIdWishListItemMutation, AddIdWishListItemMutationVariables>;

/**
 * __useAddIdWishListItemMutation__
 *
 * To run a mutation, you first call `useAddIdWishListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIdWishListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIdWishListItemMutation, { data, loading, error }] = useAddIdWishListItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *   },
 * });
 */
export function useAddIdWishListItemMutation(baseOptions?: Apollo.MutationHookOptions<AddIdWishListItemMutation, AddIdWishListItemMutationVariables>) {
        return Apollo.useMutation<AddIdWishListItemMutation, AddIdWishListItemMutationVariables>(AddIdWishListItemDocument, baseOptions);
      }
export type AddIdWishListItemMutationHookResult = ReturnType<typeof useAddIdWishListItemMutation>;
export type AddIdWishListItemMutationResult = Apollo.MutationResult<AddIdWishListItemMutation>;
export type AddIdWishListItemMutationOptions = Apollo.BaseMutationOptions<AddIdWishListItemMutation, AddIdWishListItemMutationVariables>;
export const ConfirmEmailDocument = gql`
    mutation ConfirmEmail($code: String!) {
  confirmEmail(confirmationCode: $code) {
    success
  }
}
    `;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<ConfirmEmailMutation, ConfirmEmailMutationVariables>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useConfirmEmailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>) {
        return Apollo.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument, baseOptions);
      }
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  currentUser {
    id
    username
    firstName
    profileImage
    createdAt
    email
    bio
    ipAddress
    items {
      ...Item
      entries {
        ...Entry
      }
    }
    tags {
      __typename
      id
      name
    }
    idWishList {
      ...IdWishListItem
    }
  }
}
    ${ItemFragmentDoc}
${EntryFragmentDoc}
${IdWishListItemFragmentDoc}`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetIdentificationDocument = gql`
    query GetIdentification($identificationId: ID!) {
  identification(id: $identificationId) {
    ...IdentificationFragment
    posts {
      ...Post
    }
    images {
      __typename
      id
      source
      text
    }
    items {
      ...Item
    }
  }
}
    ${IdentificationFragmentFragmentDoc}
${PostFragmentDoc}
${ItemFragmentDoc}`;

/**
 * __useGetIdentificationQuery__
 *
 * To run a query within a React component, call `useGetIdentificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentificationQuery({
 *   variables: {
 *      identificationId: // value for 'identificationId'
 *   },
 * });
 */
export function useGetIdentificationQuery(baseOptions: Apollo.QueryHookOptions<GetIdentificationQuery, GetIdentificationQueryVariables>) {
        return Apollo.useQuery<GetIdentificationQuery, GetIdentificationQueryVariables>(GetIdentificationDocument, baseOptions);
      }
export function useGetIdentificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIdentificationQuery, GetIdentificationQueryVariables>) {
          return Apollo.useLazyQuery<GetIdentificationQuery, GetIdentificationQueryVariables>(GetIdentificationDocument, baseOptions);
        }
export type GetIdentificationQueryHookResult = ReturnType<typeof useGetIdentificationQuery>;
export type GetIdentificationLazyQueryHookResult = ReturnType<typeof useGetIdentificationLazyQuery>;
export type GetIdentificationQueryResult = Apollo.QueryResult<GetIdentificationQuery, GetIdentificationQueryVariables>;
export const GetIdentificationsDocument = gql`
    query GetIdentifications {
  identifications {
    ...IdentificationFragment
  }
}
    ${IdentificationFragmentFragmentDoc}`;

/**
 * __useGetIdentificationsQuery__
 *
 * To run a query within a React component, call `useGetIdentificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIdentificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetIdentificationsQuery, GetIdentificationsQueryVariables>) {
        return Apollo.useQuery<GetIdentificationsQuery, GetIdentificationsQueryVariables>(GetIdentificationsDocument, baseOptions);
      }
export function useGetIdentificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIdentificationsQuery, GetIdentificationsQueryVariables>) {
          return Apollo.useLazyQuery<GetIdentificationsQuery, GetIdentificationsQueryVariables>(GetIdentificationsDocument, baseOptions);
        }
export type GetIdentificationsQueryHookResult = ReturnType<typeof useGetIdentificationsQuery>;
export type GetIdentificationsLazyQueryHookResult = ReturnType<typeof useGetIdentificationsLazyQuery>;
export type GetIdentificationsQueryResult = Apollo.QueryResult<GetIdentificationsQuery, GetIdentificationsQueryVariables>;
export const GetItemDocument = gql`
    query GetItem($itemId: ID!) {
  item(id: $itemId) {
    ...Item
    entries {
      ...Entry
    }
  }
}
    ${ItemFragmentDoc}
${EntryFragmentDoc}`;

/**
 * __useGetItemQuery__
 *
 * To run a query within a React component, call `useGetItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useGetItemQuery(baseOptions: Apollo.QueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
        return Apollo.useQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, baseOptions);
      }
export function useGetItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
          return Apollo.useLazyQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, baseOptions);
        }
export type GetItemQueryHookResult = ReturnType<typeof useGetItemQuery>;
export type GetItemLazyQueryHookResult = ReturnType<typeof useGetItemLazyQuery>;
export type GetItemQueryResult = Apollo.QueryResult<GetItemQuery, GetItemQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: ID, $username: String) {
  user(id: $id, username: $username) {
    __typename
    id
    username
    firstName
    createdAt
    profileImage
    bio
    items {
      ...Item
    }
    tags {
      __typename
      id
      name
    }
    idWishList {
      ...IdWishListItem
    }
  }
}
    ${ItemFragmentDoc}
${IdWishListItemFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    __typename
    id
    username
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($email: String!) {
  requestPasswordReset(email: $email) {
    success
  }
}
    `;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, baseOptions);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export const UpdateItemDocument = gql`
    mutation UpdateItem($item: ItemInput!) {
  updateItem(item: $item) {
    ...Item
  }
}
    ${ItemFragmentDoc}`;
export type UpdateItemMutationFn = Apollo.MutationFunction<UpdateItemMutation, UpdateItemMutationVariables>;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *   },
 * });
 */
export function useUpdateItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemMutation, UpdateItemMutationVariables>) {
        return Apollo.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(UpdateItemDocument, baseOptions);
      }
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = Apollo.MutationResult<UpdateItemMutation>;
export type UpdateItemMutationOptions = Apollo.BaseMutationOptions<UpdateItemMutation, UpdateItemMutationVariables>;
export const UpdateUserPasswordDocument = gql`
    mutation UpdateUserPassword($token: String!, $password: String!) {
  updateUserPassword(token: $token, password: $password) {
    success
  }
}
    `;
export type UpdateUserPasswordMutationFn = Apollo.MutationFunction<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>) {
        return Apollo.useMutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(UpdateUserPasswordDocument, baseOptions);
      }
export type UpdateUserPasswordMutationHookResult = ReturnType<typeof useUpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationResult = Apollo.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($user: UserInput!) {
  updateUser(user: $user) {
    id
    username
    firstName
    profileImage
    createdAt
    email
    bio
    ipAddress
    items {
      ...Item
      entries {
        ...Entry
      }
    }
    tags {
      __typename
      id
      name
    }
    idWishList {
      ...IdWishListItem
    }
  }
}
    ${ItemFragmentDoc}
${EntryFragmentDoc}
${IdWishListItemFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const ValidatePasswordTokenDocument = gql`
    query ValidatePasswordToken($token: String!) {
  validatePasswordResetToken(token: $token) {
    success
  }
}
    `;

/**
 * __useValidatePasswordTokenQuery__
 *
 * To run a query within a React component, call `useValidatePasswordTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatePasswordTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatePasswordTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidatePasswordTokenQuery(baseOptions: Apollo.QueryHookOptions<ValidatePasswordTokenQuery, ValidatePasswordTokenQueryVariables>) {
        return Apollo.useQuery<ValidatePasswordTokenQuery, ValidatePasswordTokenQueryVariables>(ValidatePasswordTokenDocument, baseOptions);
      }
export function useValidatePasswordTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidatePasswordTokenQuery, ValidatePasswordTokenQueryVariables>) {
          return Apollo.useLazyQuery<ValidatePasswordTokenQuery, ValidatePasswordTokenQueryVariables>(ValidatePasswordTokenDocument, baseOptions);
        }
export type ValidatePasswordTokenQueryHookResult = ReturnType<typeof useValidatePasswordTokenQuery>;
export type ValidatePasswordTokenLazyQueryHookResult = ReturnType<typeof useValidatePasswordTokenLazyQuery>;
export type ValidatePasswordTokenQueryResult = Apollo.QueryResult<ValidatePasswordTokenQuery, ValidatePasswordTokenQueryVariables>;