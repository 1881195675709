import { ApolloClient, InMemoryCache } from '@apollo/client'
// import { onError } from 'apollo-link-error'
// import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client'

const cache = new InMemoryCache()

export const client = new ApolloClient({
  // uri: '/api/graphql',
  link: createUploadLink({
    uri: '/api/graphql',
    credentials: 'same-origin'
  }),
  cache
})
