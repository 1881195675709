import { LoggedIn } from '../LoggedIn'
import { History } from 'history'
import * as React from 'react';
import { Redirect } from 'react-router-dom'
import { Input } from '../Input';
import { fetchApi } from '../../utils'
import { client } from '../../client'
import { PageContent } from '../layout'
import { Card } from '../Card'
import { Box, Button, Text, Flex } from 'rebass'
import { CardTitle, Link, PageTitle } from '../text'

interface Props {
  className?: string
  history: History
}

interface State {
  email: string
  password: string
  error?: string
  isSubmitting: boolean
}

export class LogIn extends React.Component<Props, State> {
  state: State = {
    email: '',
    password: '',
    isSubmitting: false,
  }

  onInputChange = (inputKey: string, value: string) => {
    this.setState((current) => ({
      ...current,
      [inputKey]: value
    }))
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    if (this.state.isSubmitting) return
    this.setState({ isSubmitting: true })

    let body: { email?: string, password?: string } = {}
    const fields = ['email', 'password']
    fields.forEach(field => {
      body[field] = this.state[field]
    })

    body.email = body.email!.toLowerCase()

    let response = await fetchApi('/sign-in', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })

    if(response.status === 200) {
      window.location.replace('/dashboard')
    } else {
      let { error } = await response.json()
      this.setState({ error, isSubmitting: false })
    }
  }

  render() {
    const { email, password, error, isSubmitting } = this.state
    return (
      <PageContent>
        <Box>
          <CardTitle>
            Log In
          </CardTitle>

          <LoggedIn render={() => <Redirect to="/dashboard" />} />
          <Text p={2}>Enter your information to log in.</Text>

          {error && <Text p={2} variant="error">{error}</Text>}

          <Box p={2}>
            <form onSubmit={this.handleSubmit}>
              <Input
                type="email"
                placeholder="Email or username"
                value={email}
                onChange={(e) => this.onInputChange('email', e.currentTarget.value)}
                sx={{
                  border: 'standard',
                  borderRadius: 1,
                  p: 3,
                  mb: 2
                }}
              />
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => this.onInputChange('password', e.currentTarget.value)}
                sx={{
                  border: 'standard',
                  borderRadius: 1,
                  p: 3,
                }}
              />
              <Flex width="100%" justifyContent="center" p={2}>
                <Button onClick={this.handleSubmit} disabled={isSubmitting}>Log In</Button>
              </Flex>
            </form>
          </Box>

          <Text p={2}>Don't have an account? <Link to="/signup">Create one now</Link></Text>
          <Text p={2}>Forgot password? <Link to="/reset">Reset here</Link></Text>
        </Box>
      </PageContent>
    )
  }
}
