import * as React from 'react'
import { Box } from 'rebass'
import { Input as BaseInput, InputProps as RebassInputProps } from '@rebass/forms'
import { Icon } from '../Icon'

type SearchProps = {
  placeholder?: string
  handleChange: (val: string) => void
  handleClear: () => void
  name?: string
  variant?: string
} & RebassInputProps


export const Search: React.FC<SearchProps> = (props) => {
  const {
    handleChange,
    handleClear,
    placeholder = 'Search...',
    name = '',
    value = '',
    ...restProps
  } = props

  const onChange = React.useCallback((e) => {
    handleChange(e.currentTarget.value)
  }, [handleChange])
  return (
    <Box sx={{ position: 'relative' }} width="100%">
      <BaseInput
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        name={name}
        variant="searchInput"
        {...restProps}
      />
      {handleClear && !!value.length && (
        <Icon
          name="x"
          size="14px"
          onClick={handleClear}
          variant="cancelButton"
          sx={{
            position: 'absolute',
            right: '8px',
            top: '7px',
            fontSize: 0
          }}
        />
        )}
    </Box>
  )
}
