import * as React from 'react'
import { useGetCurrentUserQuery } from '../../types/queries'

interface IsOwnerProps {
  children: React.ReactChild | React.ReactChildren | null
  ownerId: string
}

export const IsOwner: React.FC<IsOwnerProps> = (props) => {
  const { children, ownerId } = props
  const { data } = useGetCurrentUserQuery()

  if (data && data.currentUser && data.currentUser.id === ownerId) {
    return (
      <>
       {children}
      </>
    )
  }

  return null
}
