import * as yup from 'yup'

function equalTo(ref: any, msg: any) {
  return yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || '',
    params: {
      reference: ref.path,
    },
    test: function(value: any) {
      return value === this.resolve(ref);
    },
  });
}
yup.addMethod(yup.string, 'equalTo', equalTo)

const signupValidation = yup.object().shape({
  username: yup.string().max(36).matches(/^[a-zA-Z0-9._]+$/).required('Required'),
  email: yup.string().email().required('Required'),
})

export const emailPasswordValidation = signupValidation.shape({
  password: yup.string().min(8).required('Required'),
  confirmPassword: yup.string().required('Required').equalTo(yup.ref('password'), 'Passwords must match')
})

export const facebookValidation = signupValidation
