import * as React from 'react'
import { useParams, Link } from 'react-router-dom'
import { Box, Flex, Button, Text } from 'rebass'
import { Input } from '@rebass/forms'
import { Card } from '../../components/Card'
import { CardTitle } from '../../components/text'
import {
  useValidatePasswordTokenQuery,
  useUpdateUserPasswordMutation
} from '../../types/queries'
import { Formik, ErrorMessage } from 'formik'
import * as yup from 'yup'

function equalTo(ref: any, msg: any) {
  return yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || '',
    params: {
      reference: ref.path,
    },
    test: function(value: any) {
      return value === this.resolve(ref);
    },
  });
}
yup.addMethod(yup.string, 'equalTo', equalTo)

const passwordResetValidation = yup.object().shape({
  password: yup.string().min(8, 'Must be at least 8 characters.').required('Required'),
  confirmPassword: yup.string().required('Required').equalTo(yup.ref('password'), 'Passwords must match')
})

export const ResetPasswordForm: React.FC<any> = (props) => {
  const { token } = useParams<any>()
  const [hasUpdated, setHasUpdated] = React.useState(false)
  const [updateUserPassword] = useUpdateUserPasswordMutation()

  const handleSubmitRequest = async (values) => {
    await updateUserPassword({
      variables: {
        token,
        password: values.password
      }
    })
    setHasUpdated(true)
  }
  return (
    <Box m={2}>
      {hasUpdated && (
        <Box m={2}>
          Password updated successfully. <Link to="/login">Log in here.</Link>
        </Box>
      )}
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={passwordResetValidation}
        onSubmit={handleSubmitRequest}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ handleSubmit, handleChange, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <label htmlFor="password">
                Password
              </label>
              <Text variant="error">
                <ErrorMessage name="password" />
              </Text>
              <Input
                name="password"
                type="password"
                value={values.email}
                onChange={handleChange}
                sx={{
                  border: 'standard'
                }}
              />
              <label htmlFor="confirmPassword">
                Confirm Password
              </label>
              <Text variant="error">
                <ErrorMessage name="confirmPassword" />
              </Text>
              <Input
                name="confirmPassword"
                type="password"
                value={values.confirmPassword}
                onChange={handleChange}
                sx={{
                  border: 'standard'
                }}
              />
              <Flex justifyContent="flex-end">
                <Button>Submit</Button>
              </Flex>
            </form>
          )
        }}
      </Formik>
    </Box>
  )
}

export const ResetPassword: React.FC<{}> = (props) => {
  const { token } = useParams<any>()
  const { loading, data } = useValidatePasswordTokenQuery({
    variables: {
      token
    }
  })

  const isInvalidToken = !loading && !(data && data.validatePasswordResetToken && data.validatePasswordResetToken.success)
  const getContent = () => {
    if (loading) {
      return <Box m={2}>Loading...</Box>
    } else if (isInvalidToken) {
      return <Box m={2}>Token invalid or expired. Check link or <Link to="/reset">request new reset link here</Link>.</Box>
    } else {
      return <ResetPasswordForm/>
    }
  }

  return (
    <Box>
      <Card>
        <CardTitle>
          Reset Password
        </CardTitle>
        {getContent()}
      </Card>
    </Box>
  )
}
