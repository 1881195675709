import * as React from 'react'
import { Redirect } from 'react-router'
import { useTracking } from 'react-tracking'
import { PageContent } from '../../components/layout'
import { LoadingEllipsisText } from '../../components/loading'
import { useGetCurrentUserQuery, Item } from '../../types/queries'
import { UserCollection } from './user-collection'

export const DashboardPage: React.FunctionComponent<{}> = (props) => {
  const { data, loading, error } = useGetCurrentUserQuery()
  const { Track } = useTracking({ page: 'Dashboard' })

  if (loading) return <PageContent><LoadingEllipsisText/></PageContent>
  if (error) return <div>Error.....</div>
  if (data && !data.currentUser) return <Redirect to="/" />

  return (
    <Track>
      <PageContent>
        <UserCollection
          items={data.currentUser.items}
          tags={data.currentUser.tags}
        />
      </PageContent>
    </Track>
  )
}
