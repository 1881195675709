import * as React from 'react'
import { IEditItem } from '../../components/EditItem'
import { UpdateItemMutationOptions } from '../../types/queries'
import { Item } from '../../types/queries'
import { EditItemDetails } from '../item/edit-item-details'
import moment from 'moment'

interface EditItemProps {
  item: Item
  handleClose: () => void
  updateItem: (options: UpdateItemMutationOptions) => void
}
export const EditItem: React.FunctionComponent<EditItemProps> = (props) => {
  const {item, handleClose, updateItem } = props
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const handleSubmitUpdateItem = React.useCallback(async (itemEdit: IEditItem) => {
    setIsSubmitting(true)
    const tags = itemEdit.tags || []
    const nextWaterDefined = !item.nextWater && !!itemEdit.nextWater
    const nextWaterChanged = item.nextWater && !moment(item.nextWater).isSame(itemEdit.nextWater, 'day')
    const shouldUpdateNextWater = nextWaterDefined || nextWaterChanged
    const nextFertilizerDefined = !item.nextFertilizer && !!itemEdit.nextFertilizer
    const nextFertilizerChanged = item.nextFertilizer && !moment(item.nextFertilizer).isSame(itemEdit.nextFertilizer, 'day')
    const shouldUpdateNextFertilizer = nextFertilizerDefined || nextFertilizerChanged
    await updateItem({
      variables: {
        item: {
          id: item.id,
          name: itemEdit.name,
          description: itemEdit.description,
          acquiredDate: itemEdit.acquiredDate ? itemEdit.acquiredDate.toString() : null,
          identification: {id: itemEdit.identification.id},
          source: itemEdit.source,
          tags: tags.map(t => ({id: t.id, name: t.label})),
          originDate: itemEdit.originDate ? itemEdit.originDate.toString() : null,
          coverImage: itemEdit.coverImage,
          waterFrequency: itemEdit.waterFrequency ? parseInt(itemEdit.waterFrequency as string) : null,
          nextWater: shouldUpdateNextWater && itemEdit.nextWater ? itemEdit.nextWater.toString() : undefined,
          fertilizerFrequency: itemEdit.fertilizerFrequency ? parseInt(itemEdit.fertilizerFrequency as string) : null,
          nextFertilizer: shouldUpdateNextFertilizer && itemEdit.nextFertilizer ? itemEdit.nextFertilizer.toString() : undefined,
        }
      }
    })
    setIsSubmitting(false)
    handleClose()
  }, [updateItem, item])
  return (
    <EditItemDetails
      item={item}
      handleSubmit={handleSubmitUpdateItem}
      isSubmitting={isSubmitting}
      handleCancelEdit={handleClose}
    />

  )
}
