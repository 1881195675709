import * as React from 'react';
import { BrowserRouter, Route, Switch, useLocation, useHistory } from 'react-router-dom'
import './App.css';
import { Box, Flex, Text } from 'rebass'
import track from 'react-tracking'
import {
  dispatchTrackingData,
  processTrackingData,
  Analytics,
} from './analytics'
import { H1 } from './components/text'
import { MobileTitleWrapper, PageContent } from './components/layout'
import { NavLink } from './components/Sidebar'
import { LogIn } from './components/Login'
import { LogOut } from './components/LogOut'
import { SignUp } from './components/SignUp'
import GraphiQL from './pages/admin/graphiql'
import { NavContent, MobileMenu, AccountButton } from './components/Sidebar'
// import { BrowsePage } from './pages/browse'
import { DashboardPage } from './pages/dashboard'
import { WishList } from './pages/wishlist'
// import { IdentificationPage } from './pages/identification'
import { ItemPage } from './pages/item'
import { Main } from './pages'
import { UserPage } from './pages/user'
import { ConfirmEmailPage } from './pages/auth/confirm-email'
import { RequestPasswordReset } from './pages/auth/request-password-reset'
import { ResetPassword } from './pages/auth/update-password'
import { LoggedIn } from './components/LoggedIn'

const routes = [
  {
    path: '/',
    exact: true,
    main: Main
  },
  {
    path: '/dashboard',
    main: (props) => <DashboardPage {...props} />
  },
  {
    path: '/wishlist',
    main: (props) => <WishList {...props} />
  },
  // {
  //   path: '/id/:identificationId',
  //   main: (props) => <IdentificationPage {...props} />
  // },
  {
    path: '/u/:username/items/:itemId',
    main: (props) => <ItemPage {...props} />
  },
  {
    path: '/u/:username',
    main: (props) => <UserPage {...props} />
  },
  // {
  //   path: '/explore',
  //   main: (props) => <BrowsePage {...props} />
  // },
  {
    path: '/confirm/:code',
    main: (props) => <ConfirmEmailPage {...props} />
  },
  {
    path: '/reset/:token',
    main: (props) => <ResetPassword {...props} />
  },
  {
    path: '/reset',
    exact: true,
    main: (props) => <RequestPasswordReset {...props} />
  },
  {
    path: '/login',
    main: (props) => <LogIn {...props} />
  },
  {
    path: '/signup',
    main: (props) => <SignUp {...props} />
  },
  {
    path: '/logout',
    main: (props) => <LogOut {...props} />
  },
]

const AppMain = (props) => {
  return (
    <Flex
      flexDirection="column"
      width={1}
    >
      <Flex
        backgroundColor="white"
        width={1}
        alignItems="center"
        justifyContent={['space-between', 'space-between', 'space-between']}
        px={4}
        sx={{
          borderBottom: 'standard',
          minHeight: 'fit-content',
          position: 'fixed',
          top: 0,
          zIndex: 5,
          height: ['40px', '40px', '50px']
        }}
      >
        <Text
          variant="h1"
          display={['none', 'initial', 'initial']}
        >Greenrie</Text>
        <MobileMenu />
        <MobileTitleWrapper>
          <H1>Greenrie</H1>
        </MobileTitleWrapper>
        <Flex alignItems="center">
          <Box
            display={['none', 'flex', 'flex']}
            mr={3}
            sx={{ alignItems: 'center' }}
          >
            <NavContent />
          </Box>
          <LoggedIn
            renderLoading={() => <NavLink to="/login">Log In</NavLink>}
            renderLoggedOut={() => <NavLink to="/login">Log In</NavLink>}
            render={() => <AccountButton />}
          />
        </Flex>
      </Flex>
      <Box
        backgroundColor="white"
        width={1}
        sx={{
          position: 'relative',
          top: ['40px', '40px', '50px'],
        }}
      >
        <Switch>
          {
            routes.map((route, idx) => {
              return (
                <Route
                  key={idx}
                  path={route.path}
                  render={route.main as any}
                  exact={route.exact}
                />
              )
            })
          }
        </Switch>
      </Box>
    </Flex>
  )
}

const ScrollToTop = () => {
  const { pathname } = useLocation()
  const history = useHistory()

  React.useEffect(() => {
    if (history.action === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [pathname, history])

  return null
}

@track({}, {
  dispatch: dispatchTrackingData,
  process: processTrackingData,
})
class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop />
        <Analytics />
        <Switch>
          {process.env.NODE_ENV === 'development' && <Route path="/admin/graphiql" component={GraphiQL}/>}
          <Route path="/" component={AppMain}/>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
