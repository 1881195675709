import * as React from 'react'
import ReactAvatarEditor from 'react-avatar-editor'
import { useDropzone } from 'react-dropzone'
import { Box, Flex, Text } from 'rebass'
import { debounce } from 'lodash'
import { Card } from '../Card'
import { Icon } from '../Icon'

const defaultPosition = { x: 0.5, y: 0.5 }

function b64ToUint8Array(b64Image) {
  var img = atob(b64Image.split(',')[1]);
  var img_buffer = [];
  var i = 0;
  while (i < img.length) {
    img_buffer.push(img.charCodeAt(i));
    i++;
  }
  return new Uint8Array(img_buffer);
}

interface EditIconProps {
  handleImageUpdate: (blob?: Blob) => void
}

// TODO: Make (Image?) Dropzone component
const Dropzone: React.FC<{ onDrop: any }> = ({ onDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
  } as any)
  return (
    <Card variant="well" width={1}>
      <Flex
        {...getRootProps() as any}
        justifyContent="center"
        p={4}
        width="100%"
      >
        <input {...getInputProps()} />
        <Text>Drag and drop image here or click to select file</Text>
      </Flex>
    </Card>
  )
}

export const EditIcon: React.FC<EditIconProps> = ({ handleImageUpdate }) => {
  const [file, setFile] = React.useState(null)
  const [position, setPosition] = React.useState(defaultPosition)
  const [scale, setScale] = React.useState(1.0)
  const [rotation, setRotation] = React.useState(0)
  const onDrop = React.useCallback(acceptedFiles => {
    setFile(acceptedFiles[0])
  }, [])
  const rotateRight = () => setRotation(rotation + 90)
  const rotateLeft = () => setRotation(rotation - 90)

  const editor = React.useRef(null)
  const onImageChange = React.useCallback(debounce(() => {
    const canvas = editor.current.getImage()
    canvas.crossOrigin = 'anonymous'
    const b64Image = canvas.toDataURL('image/jpeg');
    const u8Image  = b64ToUint8Array(b64Image);
    const blob = new Blob([ u8Image ], {type: "image/jpg"})
    handleImageUpdate(blob)
  }, 200), [file])

  const content = () => {
    if (!file) {
      return <Dropzone onDrop={onDrop} />
    } else {
      return (
        <Flex flexDirection="column">
          <Icon
            name="x"
            color="blue.5"
            size="16px"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: 'blue.3'
              }
            }}
            onClick={() => setFile(null)}
            alignSelf="flex-end"
          />
          <ReactAvatarEditor
            image={file}
            ref={editor}
            width={300}
            border={10}
            height={300}
            scale={scale}
            rotate={rotation}
            borderRadius={0}
            position={position}
            onPositionChange={setPosition}
            onImageChange={onImageChange}
            onImageReady={onImageChange}
          />
          <Flex p={3} justifyContent="space-between">
            <Box>
              Zoom:
              <input
                name="scale"
                type="range"
                onChange={e => setScale(parseFloat(e.target.value))}
                min={'1'}
                max="2"
                step="0.01"
                defaultValue="1"
              />
            </Box>
            <Flex>
              <Icon
                name="rotate-left"
                size="18px"
                onClick={rotateLeft}
                mr={2}
              />
              <Icon
                name="rotate-right"
                size="18px"
                onClick={rotateRight}
              />
            </Flex>
          </Flex>
        </Flex>
      )
    }
  }
  return (
    <Flex width={'100%'} justifyContent="center">
      {content()}
    </Flex>
  )
}