import * as React from 'react'
import { Box, BoxProps } from 'rebass'
import {
  AiOutlineRotateLeft,
  AiOutlineRotateRight,
} from 'react-icons/ai'
import {
  FaAngleLeft,
  FaAngleRight,
  FaArrowDown,
  FaBars,
  FaCaretUp,
  FaCaretDown,
  FaCheck,
  FaCircle,
  FaEllipsisH,
  FaList,
  FaWindowClose,
  FaSeedling,
  FaSort,
  FaPlusCircle,
  FaThLarge,
  FaTimes,
  FaUser,
} from 'react-icons/fa'
import {
  FiSmile,
} from 'react-icons/fi'
import {
  IoAdd,
  IoFilter,
} from 'react-icons/io5'
import {
  MdDragHandle,
} from 'react-icons/md'

const iconComponentRef = {
  'angle-left': FaAngleLeft,
  'angle-right': FaAngleRight,
  'arrow-down': FaArrowDown,
  'bars': FaBars,
  'caret-up': FaCaretUp,
  'caret-down': FaCaretDown,
  check: FaCheck,
  circle: FaCircle,
  close: FaWindowClose,
  'ellipsis': FaEllipsisH,
  filter: IoFilter,
  handle: MdDragHandle,
  'list': FaList,
  plus: IoAdd,
  'plus-circle': FaPlusCircle,
  'rotate-left': AiOutlineRotateLeft,
  'rotate-right': AiOutlineRotateRight,
  seedling: FaSeedling,
  smile: FiSmile,
  sort: FaSort,
  tiles: FaThLarge,
  user: FaUser,
  x: FaTimes,
}

export type IconProps = {
  name: keyof typeof iconComponentRef
  size?: string
} & BoxProps & { css?: any }
export const Icon: React.FunctionComponent<IconProps> = (props) => {
  const { name, size = '16px', ...restProps } = props
  const IconComponent = iconComponentRef[name]
  return (
    <Box
      tx="icons"
      variant="button"
      {...restProps}
    >
      <IconComponent size={size} />
    </Box>
    )
}
