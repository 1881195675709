import { Card } from '../../components/Card'
import { Icon } from '../../components/Icon'
import { CardTitle, FormattedIdentification, Link, TabText } from '../../components/text'
import moment from 'moment'
import * as React from 'react'
import { Box, Flex, Text } from 'rebass'
import { GetUserQueryHookResult, Identification } from '../../types/queries'

type User = GetUserQueryHookResult['data']['user']
type IdWishListItem = User['idWishList'][0]

const WishListItem: React.FC<{ item: IdWishListItem }> = (props) => {
  const { item } = props
  return (
    <Flex p={2} sx={{ minHeight: 'fit-content', borderBottom: 'standard' }}>
      <Box width={1/3}>
        <FormattedIdentification identification={item.identification as Identification}/>
      </Box>
      <Box width={1/6}>
        Added {moment(item.createdAt).format('M/D/YYYY')}
      </Box>
      <Box width={1/2}>
        {item.comment}
      </Box>
    </Flex>
  )
}

export const UserWishList: React.FC<{ user: User, ref?: any }> = React.forwardRef((props, ref) => {
  const { user } = props
  const { idWishList } = user
  const hasItems = !!idWishList.length

  const content = () => {
    if (!hasItems) {
      return (<Text width={1} textAlign="center">No Wish List IDs here yet!</Text>)
    } else {
      return idWishList.map(item => {
        return (
          <>
            <WishListItem key={item.id} item={item}/>
          </>
        )
      })
    }
  }
  return (
    <div ref={ref as any}>
      <Box sx={{ fontSize: [1, 2, 2] }}>
        {content()}
      </Box>
    </div>
  )
})
