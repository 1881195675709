import { Card } from 'components/Card'
import * as React from 'react'
import moment from 'moment'
import * as d3 from 'd3'
import { ImagePlaceholder } from 'components/ImagePlaceholder'
import { IsOwner } from 'components/IsOwner'
import { FlexRow, LabelColumn } from 'components/table'
import { CardTitle, FormattedIdentification, SectionTitle, Tag } from 'components/text'
import { Box, Button, Flex, Image, Text } from 'rebass'
import {
  GetCurrentUserQueryHookResult, UpdateItemMutationOptions,
} from 'types/queries'
import { EditItem } from 'pages/dashboard/edit-item'
import { Entry } from 'components/Entry'

type Plant = GetCurrentUserQueryHookResult['data']['currentUser']['items'][0]

const ItemDetails: React.FC<{ item: Plant }> = ({ item }) => {
  const {
    acquiredDate,
    identification,
    description,
    source,
    tags = [],
  } = item

  const tagColorScale = d3.scaleOrdinal(d3.schemePastel1)
  return (
    <Flex flexDirection="column" mx={-2}>
      <FlexRow>
        <LabelColumn>
          Identification
        </LabelColumn>
        <Flex width={3 / 4} alignItems="center" p={1}>
          {identification && (
            <FormattedIdentification identification={identification} />
          )}
        </Flex>
      </FlexRow>
      {description && (
        <FlexRow>
          <LabelColumn>
            Description
          </LabelColumn>
          <Flex width={3 / 4} alignItems="center" p={1}>
            {description}
          </Flex>
        </FlexRow>
      )}
      {acquiredDate && (
        <FlexRow>
          <LabelColumn>
            Date Acquired
          </LabelColumn>
          <Flex width={3 / 4} alignItems="center" p={1}>
            {moment(acquiredDate).format('MMMM YYYY')}
          </Flex>
        </FlexRow>
      )}
      {source && (
        <FlexRow>
          <LabelColumn>
            Source
          </LabelColumn>
          <Flex width={3 / 4} alignItems="center" p={1}>
            {source}
          </Flex>
        </FlexRow>
      )}
      {!!tags.length && (
        <FlexRow>
          <LabelColumn>
            Tags
          </LabelColumn>
          <Flex width={3 / 4} alignItems="center" p={1}>
            {tags.map(tag => {
              return (
                <Tag
                  key={tag.id}
                  color={tagColorScale(tag.id)}
                  tag={tag}
                />
              )
            })}
          </Flex>
        </FlexRow>
      )}
    </Flex>
  )
}

interface PlantDetailsProps {
  plant: Plant
  updateItem: (options: UpdateItemMutationOptions) => void
}
export const PlantDetails: React.FC<PlantDetailsProps> = (props) => {
  const [isEditing, setIsEditing] = React.useState(false)
  const { plant, updateItem } = props
  const {
    coverImage,
    user,
    entries,
  } = plant

  const noEntries = !entries?.length

  const detailsContent = () => {
    if (isEditing) {
      return (
        <EditItem
          item={plant}
          handleClose={() => setIsEditing(false)}
          updateItem={updateItem}
        />
      )
    }
    return (
      <Box width="100%" p={1}>
        <CardTitle
          renderContent={() => (
            <IsOwner ownerId={user.id}>
              <Button
                variant="text"
                onClick={() => setIsEditing(true)}
              >
                Edit
              </Button>
            </IsOwner>
          )}
        >
          Details
        </CardTitle>
        <ItemDetails
          item={plant}
        />
      </Box>
    )
  }
  return (
    <>
      {coverImage ? (
        <Image
          src={coverImage}
          maxHeight={'500px'}
          sx={{
            objectFit: 'cover',
            borderRadius: '2px',
            border: 'none',
            mb: 3
          }}
        />
      ) : <ImagePlaceholder width="100%" iconSize="200px"/>}
      {detailsContent()}
      {!isEditing && (
        <Box mb={4}>
          <SectionTitle>Entries</SectionTitle>
          {noEntries && (
            <Flex justifyContent="center" p={3}>
              <Text>No entries yet!</Text>
            </Flex>
          )}
          {!noEntries && entries.map(entry => (<Entry key={entry.id} entry={entry} />))}
        </Box>
      )}
    </>
  )
}