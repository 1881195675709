import * as React from 'react'
import { LoadingEllipsisText } from '../loading'
import { Box, Flex, Button, Text } from 'rebass'
import { Input, Textarea } from '@rebass/forms'
import { IdDropdown, NewIdentification } from '../../pages/dashboard/id-dropdown'
import { FlexRow, LabelColumn, ValueColumn } from '../table'
import { DateInputs } from '../Input/date-inputs'
import { EditIcon } from '../EditIcon'
import { Icon } from '../Icon'
import CreatableSelect from 'react-select/creatable'
import {
  Identification,
  useGetCurrentUserQuery,
} from '../../types/queries'
import { get } from 'lodash'
export { editItemReducer, editItemDefaultState } from './reducer'

export interface IEditItem {
  name: string
  description?: string
  acquiredDate?: Date
  originDate?: Date
  source?: string
  tags?: { id?: string, label: string }[]
  identification?: Identification | NewIdentification
  tempId?: string
  savedCoverImage?: string
  coverImage?: Blob
  waterFrequency?: number | string
  nextWater?: Date
  fertilizerFrequency?: number | string
  nextFertilizer?: Date
}

interface EditItemProps {
  displayErrors: boolean
  handleRemove?: () => void
  handleUpdateAttribute: (attr: string, value: any) => void
  isRemovable?: boolean
  item: IEditItem
}

const shouldComponentNotUpdate= (prevProps, nextProps) => {
  return prevProps.item === nextProps.item &&
    prevProps.displayErrors === nextProps.displayErrors
}

export const EditItem: React.FunctionComponent<EditItemProps> = React.memo((props) => {
  const { item, handleUpdateAttribute, displayErrors,
    isRemovable, handleRemove } = props
  const { data, loading } = useGetCurrentUserQuery()
  const [isExtendedView, setIsExtendedView] = React.useState(false)

  if (loading) {
    return <LoadingEllipsisText />
  }
  const customTags = get(data, 'currentUser.tags', [])
  return (
    <Box
      sx={{
        mx: -1,
        // borderBottom: 'standard',
        // '&:not(:last-of-type)': {
        //   mb: 2
        // },
        // '&:not(:first-child)': {
        //   borderTop: 'standard'
        // }
      }}
    >
      {isRemovable && (
        <Flex>
          <Icon
            name="x"
            size="14px"
            mx={3}
            my={2}
            onClick={handleRemove}
          >X</Icon>
        </Flex>
      )}
      <FlexRow>
        <LabelColumn required>
          Name
        </LabelColumn>
        <ValueColumn>
          <Input
            variant={displayErrors && !item.name ? 'errorInput' : 'input'}
            value={item.name}
            spellcheck="false"
            onChange={(e) => handleUpdateAttribute('name', e.target.value)}
          />
        </ValueColumn>
      </FlexRow>
      <FlexRow>
        <LabelColumn>
          Image
        </LabelColumn>
        <ValueColumn>
          <EditIcon
            handleImageUpdate={(blob) => handleUpdateAttribute('coverImage', blob)}
          />
        </ValueColumn>
      </FlexRow>
      <FlexRow>
        <LabelColumn required>
          Identification
        </LabelColumn>
        <ValueColumn>
          <IdDropdown
            displayErrors={displayErrors}
            idValue={item.identification}
            handleIdChange={(val?: Identification) => {
              handleUpdateAttribute('identification', val)
            }}
          />
        </ValueColumn>
      </FlexRow>
      <FlexRow>
        <LabelColumn>
          Description
        </LabelColumn>
        <Flex width={1}>
          <Textarea
            spellcheck="false"
            value={item.description}
            onChange={(e) => handleUpdateAttribute('description', e.target.value)}
          />
        </Flex>
      </FlexRow>
      <FlexRow>
        <LabelColumn>
          Tags
        </LabelColumn>
        <Flex width={1}>
          <CreatableSelect
            isMulti
            name="entry-items"
            value={item.tags}
            options={customTags.map(tag => ({ id: tag.id, label: tag.name }))}
            getOptionValue={opt => opt.id || opt.label}
            getOptionLabel={opt => opt.label}
            onChange={(value, actionType) => {
              handleUpdateAttribute('tags', value || [])}}
            styles={{
              container: () => ({ width: '100%', position: 'relative' }),
            }}
            placeholder="Add tags to organize and filter your plants"
            onCreateOption={(inputVal) => {
              handleUpdateAttribute('tags',[...item.tags, { label: inputVal }])
            }}
          />
        </Flex>
      </FlexRow>
      {isExtendedView && (
        <>
          <FlexRow>
            <LabelColumn>
              <Text color={!item.acquiredDate ? 'grey.3' : 'black'}>Acquired Date</Text>
            </LabelColumn>
            <Flex width={1}>
              <DateInputs
                monthYearOnly
                onChange={date => handleUpdateAttribute('acquiredDate', date)}
                date={item.acquiredDate}
                isClearable
              />
            </Flex>
          </FlexRow>
          <FlexRow>
            <LabelColumn>
              <Text color={!item.originDate ? 'grey.3' : 'black'}>Origin Date</Text>
            </LabelColumn>
            <Flex width={1}>
              <DateInputs
                monthYearOnly
                onChange={date => handleUpdateAttribute('originDate', date)}
                date={item.originDate}
                isClearable
              />
            </Flex>
          </FlexRow>
          <FlexRow>
            <LabelColumn>
              <Text color={!item.source ? 'grey.3' : 'black'}>Source</Text>
            </LabelColumn>
            <Flex width={1}>
              <Input
                value={item.source}
                spellcheck="false"
                onChange={(e) => handleUpdateAttribute('source', e.target.value)}
              />
            </Flex>
          </FlexRow>
          <FlexRow>
            <Flex pr={[0, 2, 2]} flexDirection="column" width={[1, .5, .5]}>
              <LabelColumn>
                <Text color={!item.waterFrequency ? 'grey.3' : 'black'}>Water Frequency (# days)</Text>
              </LabelColumn>
              <Flex width={1}>
                <Input
                  value={item.waterFrequency}
                  type="number"
                  onChange={(e) => handleUpdateAttribute('waterFrequency', e.target.value)}
                />
              </Flex>
            </Flex>
            <Flex flexDirection="column" width={[1, .5, .5]}>
              <LabelColumn>
                <Text color={!item.nextWater ? 'grey.3' : 'black'}>Next Water</Text>
              </LabelColumn>
              <Flex>
                <DateInputs
                  onChange={date => handleUpdateAttribute('nextWater', date)}
                  date={item.nextWater}
                  isClearable
                  isFuture
                />
              </Flex>
            </Flex>
          </FlexRow>
          <FlexRow>
            <Flex pr={[0, 2, 2]}  flexDirection="column" width={[1, .5, .5]}>
              <LabelColumn>
                <Text color={!item.fertilizerFrequency ? 'grey.3' : 'black'}>Fertilizer Frequency (# days)</Text>
              </LabelColumn>
              <Flex>
                <Input
                  value={item.fertilizerFrequency}
                  type="number"
                  onChange={(e) => handleUpdateAttribute('fertilizerFrequency', e.target.value)}
                />
              </Flex>
            </Flex>
            <Flex flexDirection="column" width={[1, .5, .5]}>
              <LabelColumn>
                <Text color={!item.nextFertilizer ? 'grey.3' : 'black'}>Next Fertilizer</Text>
              </LabelColumn>
              <Flex>
                <DateInputs
                  onChange={date => handleUpdateAttribute('nextFertilizer', date)}
                  date={item.nextFertilizer}
                  isClearable
                  isFuture
                />
              </Flex>
            </Flex>
          </FlexRow>
        </>
      )}
      {!isExtendedView && (
        <Button
          variant="text"
          onClick={() => setIsExtendedView(true)}
          sx={{ display: 'flex' }}
        >
          <Icon name="caret-down"/>
          Show more fields
        </Button>
      )}
    </Box>
  )
}, shouldComponentNotUpdate)

EditItem.defaultProps = {
  handleRemove: () => {},
  isRemovable: false
}
