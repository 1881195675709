import * as React from 'react'
import { Box, Flex } from 'rebass'
import { Button } from 'rebass'
import { EditItem, IEditItem } from '../../components/EditItem'

export type AddItemSingleState = IEditItem

interface AddItemSingleProps {
  handleSubmit: () => void
  handleCancel: () => void
  handleUpdate: (attribute: string, value: any) => void
  isSubmitting: boolean
  item: AddItemSingleState
}

export const AddItemSingle: React.FunctionComponent<AddItemSingleProps> = (props) => {
  const { item, handleSubmit, isSubmitting, handleUpdate, handleCancel } = props
  const [displayErrors, setDisplayErrors] = React.useState(false)

  const isSubmitDisabled = !item.name || !item.identification || isSubmitting ||
    (item.identification && item.identification.id === 'new')

  const onSubmit = () => {
    if (isSubmitDisabled) {
      setDisplayErrors(true)
    } else {
      handleSubmit()
    }
  }
  return (
    <Box>
      <EditItem
        item={item}
        handleUpdateAttribute={handleUpdate}
        displayErrors={displayErrors}
      />
      <Flex
        justifyContent="flex-end"
        alignItems="center"
        p={3}
      >
        <Button
          variant="cancel"
          onClick={handleCancel}
          mr={4}
        >Cancel</Button>
        <Button
          variant="primary"
          disabled={isSubmitting}
          onClick={onSubmit}
        >Save</Button>
      </Flex>
    </Box>
  )
}
