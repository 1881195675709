import * as React from 'react'
import { Box, Flex, Image, Text } from 'rebass'
import moment from 'moment'
import { Entry as IEntry, Action } from '../../types/queries'
import { ImageViewer } from '../ImageViewer'

const EntryAction: React.FC<{ action: Action }> = (props) => {
  const { action } = props
  return (
    <Text
      variant="actionTag"
    >
      {action.label}
    </Text>
  )
}

interface EntryProps {
  entry: IEntry
}
export const Entry: React.FunctionComponent<EntryProps> = (props) => {
  const { entry } = props
  const { date, actions, images, text } = entry
  const [isImageViewerOpen, setIsImageViewerOpen] = React.useState(false)
  const [imageIdx, setImageIdx] = React.useState(0)

  const handleImagePreviewClick = React.useCallback((idx) => {
    setImageIdx(idx)
    setIsImageViewerOpen(true)
  }, [])
  const handleImageIdxChange = React.useCallback((idx) => {
    setImageIdx(idx)
  }, [])

  const hasImages = !!(images && images.length)
  const hasTags = !!(actions && actions.length)
  const hasText = !!(text?.length)
  return (
    <Box
      sx={{
        borderTop: 'none',
        borderBottom: 'standard',
        py: 2,
      }}
    >
      <Text
        display="block"
        sx={{
          borderBottom: 'none',
          mx: -2,
          px: 3,
          py: 2,
        }}
      >
        {moment(date).format('MMMM Do, YYYY')}
      </Text>
      {hasTags && (
        <Flex
          px={2}
          py={3}
          flexWrap="wrap"
          alignItems="center"
        >
          {/*<Text fontWeight="bold">Tags: </Text>*/}
          {actions.map(tag => (
            <EntryAction key={tag.id} action={tag} />
          ))}
        </Flex>
      )}
      {hasImages && (
        <Flex p={2} flexWrap="wrap">
          {images.map((img, idx) => (
            <Box
              key={img.id}
              onClick={() => handleImagePreviewClick(idx)}
              sx={{
                width: '22%',
                pb: '22%',
                cursor: 'pointer',
                position: 'relative',
                overflow: 'hidden',
                background: `url(${img.source}) 50% 50% no-repeat`,
                backgroundSize: 'cover',
                m: 2,
              }}
            >
              <Image
                key={img.id}
                src={img.source}
                onClick={() => handleImagePreviewClick(idx)}
                sx={{
                  position: 'absolute',
                  p: 2,
                  objectFit: 'cover',
                  cursor: 'pointer',
                  display: 'none'
                }}
              />
            </Box>
          ))}
        </Flex>
      )}
      {hasText && <Box p={2} sx={{ whiteSpace: 'pre-line' }}>
        {text}
      </Box>}
      {isImageViewerOpen && (
        <ImageViewer
          images={images}
          imageIdx={imageIdx}
          handleImageIdxChange={handleImageIdxChange}
          handleClose={() => setIsImageViewerOpen(false)}
        />
      )}
    </Box>
  )
}
