import * as React from 'react';
import { Box } from 'rebass'
import { Redirect } from 'react-router-dom'
import { fetchApi } from '../../utils'
import { client } from '../../client'
import { LoadingEllipsisText } from '../loading'
import { resetAnalyticsUser } from 'analytics'

interface Props {
  className?: string
}

export class LogOut extends React.Component<Props> {
  componentDidMount() {
    fetchApi('/sign-out', {
      method: 'GET'
    }).then(res => {
      resetAnalyticsUser()
      client.resetStore().then(() => {
        window.location.replace('/login')
      })
    })
  }
  render() {
    return (
      <Box width={1}>
        <LoadingEllipsisText text="Logging out" />
      </Box>
    )
  }
}
