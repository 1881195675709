import * as React from 'react'
import Select from 'react-select'
import Fuse from 'fuse.js'
import { useTracking } from 'react-tracking'
import { Box, Text } from 'rebass'
import { sortBy } from 'lodash'
import { FormattedIdentification } from '../../components/text'
import { Card } from '../../components/Card'
import { AddIdentification } from './add-identification'
import {
  GetIdentificationsDocument,
  Identification,
  IdentificationInput,
  useAddIdentificationMutation,
  useGetIdentificationsQuery,
} from '../../types/queries'
import { ADD_IDENTIFICATION } from 'analytics/events'

const modalRootElement = document.getElementById('modal-root')

const Wrapper: React.FC<{ error: boolean, children: React.ReactChild }> = (props) => {
  return (
    <Box
      sx={{
        width: '100%',
        border: props.error ? 'error' : 'none'
      }}
    >
      {props.children}
    </Box>
  )
}

const fuseOptions = {
  shouldSort: false,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    'formatted',
    'commonNames',
  ]
}

export interface NewIdentification {
  id: 'new'
  genus: string
  species: string
  subspecies: string
  form: string
  subform: string
  variety: string
  subvariety: string
  cultivar: string
}
interface IdDropdownProps {
  idValue?: Identification | NewIdentification
  handleIdChange: (val?: Identification) => void
  displayErrors?: boolean
}

export const IdDropdown: React.FC<IdDropdownProps> = (props) => {
  const { idValue, handleIdChange, displayErrors } = props
  const { trackEvent } = useTracking()
  const [inputValue, setInputValue] = React.useState('')
  const [addIdentification] = useAddIdentificationMutation({
    refetchQueries: [{ query: GetIdentificationsDocument }]
  })
  const { data, loading } = useGetIdentificationsQuery()

  const handleInputChange = (val, type) => {
    if (type.action === 'input-change') {
      setInputValue(val)
    }
  }

  const handleCancelAddIdentification = () => {
    handleIdChange(null)
  }

  const getOptions = () => {
    const { identifications = [] } = data
    const sortedIdentifications = sortBy(identifications, 'formatted')
    const fuse = new Fuse(sortedIdentifications, fuseOptions)
    const searched = fuse.search(inputValue)
    const options: any = inputValue && inputValue.length ? searched : [...sortedIdentifications]
    options.push({ id: 'new' })
    return options
  }

  const isSubmittingIdentification = idValue && idValue.id === 'new'

  const handleSubmitIdentification = async (identification: IdentificationInput) => {
    const resultId = await addIdentification({
      variables: { identification },
    })
    trackEvent({
      event: ADD_IDENTIFICATION
    })
    handleIdChange(resultId.data.addIdentification as any)
  }
  const isError = !idValue || (idValue && idValue.id === 'new')

  if (isSubmittingIdentification) {
    return (
      <Wrapper error={displayErrors && isError}>
        <Card variant="well">
          <AddIdentification
            handleSubmitIdentification={handleSubmitIdentification}
            initialValues={idValue as NewIdentification}
            handleCancel={handleCancelAddIdentification}
          />
        </Card>
      </Wrapper>
    )
  }

  const options = loading ? [] : getOptions()
  return (
    <Wrapper error={displayErrors && isError}>
    <Select
      isMulti={false}
      isClearable
      isSearchable
      name="color"
      options={options}
      getOptionValue={opt => opt.id}
      getOptionLabel={opt => opt.id}
      formatOptionLabel={(option) => {
        if (option.id === 'new') {
          return <Text>Submit new identification</Text>
        } else if (option.id === 'multiple') {
          return <Text>Multiple</Text>
        }
        return <FormattedIdentification identification={option as Identification}/>
      }}
      value={idValue}
      onChange={handleIdChange}
      onInputChange={handleInputChange}
      filterOption={() => true}
      menuPortalTarget={modalRootElement}
      placeholder="Type to search existing identifications or add a new ID to our database"
      styles={{
        container: (styles) => ({
          ...styles,
          width: '100%',
          position: 'relative',
        }),
        menuPortal: (styles) => ({
          ...styles,
          zIndex: 15
        })
      }}
    />
    </Wrapper>
  )
}

IdDropdown.defaultProps = {
  displayErrors: false
}
