import { Search } from '../../components/Input'
import * as React from 'react'
import Select from 'react-select'
import { Box, Button, Flex, Image, Text } from 'rebass'
import { Icon } from '../../components/Icon'
import * as d3 from 'd3'
import { PlantCard } from '../../components/PlantCard'
import { Card } from '../../components/Card'
import { CardTitle, FormattedIdentification, Link, Tag, TabText } from '../../components/text'
import { ImagePlaceholder } from '../../components/ImagePlaceholder'
import { GetUserQueryHookResult, Identification } from '../../types/queries'
import { sortBy, uniqBy } from 'lodash'
import Fuse from 'fuse.js'
import { getFilteredItems, FilterManager, useFilterReducer } from '../../components/Filters'

type User = GetUserQueryHookResult['data']['user']
type Item = User['items'][0]

const TagColorContext = React.createContext<any>(() => {})

type DataColumn = {
  key: string
  label: string
  // getValue: (item: Item) => string,
  render: React.FC<{
    item: Item
    key: string
    width: number | string
  }>
  sortable: boolean
  width: number | string
}

const columns: DataColumn[] = [
  {
    key: 'img',
    label: '',
    width: '60px',
    sortable: false,
    render: ({ key, item, width }) => {
      return (
        <Flex
          key={key}
          p={1}
          height="60px"
          width={width}
          justifyContent="center"
          alignItems="center"
          px={2}
        >
          <Link to={`/u/${item.user.username}/items/${item.id}`}>
            {item.coverImage ? (
              <Image
                src={item.coverImage}
                sx={{
                  objectFit: 'cover',
                  borderRadius: '2px',
                  maxHeight: '50px',
                }}/>
            ) : (
              <ImagePlaceholder iconSize="36px" minHeight="50px" />
            )}
          </Link>
        </Flex>
      )
    }
  },
  {
    key: 'name',
    label: 'Name',
    width: 0.3,
    sortable: true,
    render: ({ key, item, width }) => (
      <Flex key={key} alignItems="center" width={width}>
        <Link to={`/u/${item.user.username}/items/${item.id}`}>
          {item.name}
        </Link>
      </Flex>
    )
  },
  {
    key: 'id',
    label: 'ID',
    width: 0.3,
    sortable: true,
    render: ({ key, item, width }) => (
      <Flex key={key} alignItems="center" width={width}>
        <FormattedIdentification identification={item.identification as Identification}/>
      </Flex>
    )
  },
  {
    key: 'tags',
    label: 'Tags',
    width: 0.4,
    sortable: false,
    render: ({ key, item, width }) => {
      const { tags = [] } = item
      return (
        <TagColorContext.Consumer key={key}>
          {tagScale => (
            <Flex
              justifyContent="center"
              alignItems="center"
              width={width}
              flexWrap="wrap"
            >
              {tags.map(tag => (
                <Tag key={tag.id} tag={tag} color={tagScale(tag.id)} />
              ))}
            </Flex>
          )}
        </TagColorContext.Consumer>
      )
    }
  },
]
interface CollectionListItemProps {
  item: Item
}
const CollectionListItem: React.FunctionComponent<CollectionListItemProps> = (props) => {
  const { item } = props
  return (
    <Flex
      sx={{
        borderBottom: 'standard',
        minHeight: 'fit-content',
        fontSize: [1, 2, 2]
      }}>
      {columns.map(col => {
        return (
          col.render({ key: col.key, item, width: col.width })
        )
      })}
    </Flex>
  )
}

const dateAddedSortBy = {
  value: 'added',
  label: 'Date Added',
  sortBy: (item) => item.createdAt
}

const sortByOptions = [
  {
    value: 'name',
    label: 'Name',
    sortBy: (item) => item.name
  },
  {
    value: 'identification',
    label: 'Identification',
    sortBy: (item) => item.identification?.formatted
  },
  dateAddedSortBy
]

const fuseOptions = {
  shouldSort: false,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    'identification.formatted',
    'name',
  ]
}

export const UserCollection: React.FC<{ user: User, ref?: any }> = React.forwardRef((props, ref) => {
  const [isListView, setIsListView] = React.useState(true)
  const [selectedSortBy, setSelectedSortBy] = React.useState(dateAddedSortBy)
  const [searchValue, setSearchValue] = React.useState('')
  const { filtered, actions } = useFilterReducer()
  const { user } = props
  const { items = [], tags = [] } = user
  const hasItems = !!items.length

  const tagColorScale = d3.scaleOrdinal(d3.schemePastel1)

  const allUserGenera = items.map(item => item.identification?.genus).filter(x => x)
  const sortedGenera = sortBy(uniqBy(allUserGenera, 'id'), 'name')
  const sortedTags = sortBy(tags, 'name')

  const content = () => {
    if (!hasItems) {
      return (
        <Flex width={1} alignItems="center" justifyContent="center" p={3}>
          <Text>No plants here yet!</Text>
        </Flex>
    )}

    const filteredItems = getFilteredItems(items, filtered)
    const sortedItems = selectedSortBy ? sortBy(filteredItems, selectedSortBy.sortBy) : filteredItems
    const fuse = new Fuse(sortedItems, fuseOptions)
    const visibleItems = searchValue ? fuse.search(searchValue) : sortedItems
    if (!visibleItems.length) {
      return (
        <Flex width={1} alignItems="center" justifyContent="center" p={3}>
          <Text>No matches</Text>
        </Flex>
      )}
    if (isListView) {
      return visibleItems.map(item => {
        return (
          <>
            <CollectionListItem key={item.id} item={item}/>
          </>
        )
      })
    } else {
      return (
        <Flex flexWrap="wrap">
          {visibleItems.map(item => {
            return (<PlantCard key={item.id} item={item}/>)
          })
          }
        </Flex>
      )
    }
  }
  return (
    <TagColorContext.Provider value={tagColorScale} >
      <Flex
        sx={{
          position: 'relative',
        }}>
        <Flex
          flexDirection="column"
          width={1}
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            p={2}
            minHeight="fit-content"
          >
            <Flex width={1/2} maxWidth="300px">
              <Search
                value={searchValue}
                handleChange={(val) => setSearchValue(val)}
                handleClear={() => setSearchValue('')}
                placeholder="Search by name or ID"
              />
            </Flex>
            <Flex alignItems="center">
              <Text pr={3} fontSize={[1, 1, 2]}>View:</Text>
              <Icon
                name="tiles"
                size="16px"
                variant={!isListView ? 'buttonDisabled' : 'button'}
                onClick={() => setIsListView(false)}
                m={2}
              />
              <Icon
                name="list"
                size="16px"
                variant={isListView ? 'buttonDisabled' : 'button'}
                onClick={() => setIsListView(true)}
                m={2}
              />
            </Flex>
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            p={2}
            minHeight="fit-content"
          >
            <Flex alignItems="center" width={1/2} maxWidth="300px">
              <Text pr={3} fontSize={[1, 1, 2]}>Filter:</Text>
              <FilterManager
                addFilter={actions.addFilter}
                removeFilter={actions.removeFilter}
                filtered={filtered}
                tags={sortedTags}
                genera={sortedGenera}
              />
            </Flex>
            <Flex alignItems="center" width={1/4}>
              <Text pr={3} fontSize={[1, 1, 2]}>Sort:</Text>
              <Select
                styles={{
                  container: (styles) => ({ ...styles, width: '100%' }),
                  control: (styles) => ({ ...styles, minHeight: 'fit-content', fontSize: '12px' }),
                  clearIndicator: (styles) => ({ ...styles, padding: 0 }),
                  dropdownIndicator: (styles) => ({ ...styles, padding: 0 }),
                  option: (styles) => ({ ...styles, fontSize: '12px' }),
                }}
                options={sortByOptions}
                onChange={setSelectedSortBy}
                value={selectedSortBy}
              />
            </Flex>
          </Flex>
          {content()}
        </Flex>
      </Flex>
    </TagColorContext.Provider>
  )
})
