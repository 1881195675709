import * as React from 'react'
import { Box, Flex, Button } from 'rebass'
import { useTracking } from 'react-tracking'
import moment from 'moment'
import { CardTitle } from  '../../components/text'
import { IEditItem } from '../../components/EditItem'
import { FlexRow } from '../../components/table'
import {
  useAddItemMutation,
  useAddItemsMutation,
} from '../../types/queries'
import { AddItemSingle } from './add-item-single'
import { AddItemBulk, AddItemBulkState } from './add-item-bulk'
import {
  editItemDefaultState,
  editItemReducer
} from '../../components/EditItem'
import {
  ADD_PLANT,
  ADD_PLANTS_BULK,
  TAB_VIEW,
} from 'analytics/events'

interface ResetStateAction {
  type: 'RESET_STATE'
}

interface UpdateBulkAttributeAction {
  type: 'UPDATE_ATTRIBUTE'
  attribute: string
  value: any
  tempId: string
}

interface RemoveItemAction {
  type: 'REMOVE_ITEM'
  tempId: string
}

interface SetParseResultsAction {
  type: 'SET_PARSE_RESULTS'
  results: IEditItem[]
}

type AddItemBulkAction = UpdateBulkAttributeAction | ResetStateAction | SetParseResultsAction
  | RemoveItemAction
const addItemBulkReducer = (state: AddItemBulkState, action: AddItemBulkAction): AddItemBulkState => {
  switch (action.type) {
    case 'SET_PARSE_RESULTS':
      return action.results
    case 'REMOVE_ITEM':
      return state.filter((item) => {
        return item.tempId !== action.tempId
      })
    case 'UPDATE_ATTRIBUTE':
      if (action.attribute === 'acquiredDate') {
        console.log('date change value', action.value)
      }
      return state.map((item) => {
        if (item.tempId !== action.tempId) {
          return item
        } else {
          return { ...item, [action.attribute]: action.value }
        }
      })
    case 'RESET_STATE':
      return []
    default:
      return state
  }
}

type AddItemView = 'single' | 'bulk'

interface AddItemProps {
  handleCancel: () => void
  onComplete?: () => void
}
export const AddItem: React.FunctionComponent<AddItemProps> = (props) => {
  const { handleCancel, onComplete = () => {} } = props
  const { trackEvent } = useTracking()
  const [refreshKey, setRefreshKey] = React.useState(0)
  const [view, setView] = React.useState<AddItemView>('single')
  const [statusMessage, setStatusMessage] = React.useState('')
  const [addItemSingleState, addItemSingleDispatch] = React.useReducer(editItemReducer, editItemDefaultState)
  const [addItemBulkState, addItemBulkDispatch] = React.useReducer(addItemBulkReducer, [])
  const isBulkAdd = view === 'bulk'

  const handleSwitchView = (newView: AddItemView) => {
    trackEvent({
      event: TAB_VIEW,
      label: newView === 'single' ? 'Add plant single' : 'Add plants bulk',
    })
    setView(newView)
  }

  const [addItem, { loading }] = useAddItemMutation({
    onCompleted: () => {
      onComplete()
      // setStatusMessage('Item added.')
      // addItemSingleDispatch({
      //   type: 'RESET_STATE'
      // })
      // setRefreshKey(refreshKey + 1)
    },
    refetchQueries: ['GetCurrentUser'],
    awaitRefetchQueries: true,
  })
  const [addItems, { loading: addItemsLoading }] = useAddItemsMutation({
    onCompleted: (data) => {
      onComplete()
      // setStatusMessage(`${data.addItems.length} items added.`)
      // addItemBulkDispatch({
      //   type: 'RESET_STATE'
      // })
      // setRefreshKey(refreshKey + 1)
    },
    refetchQueries: ['GetCurrentUser'],
    awaitRefetchQueries: true,
  })

  const handleSingleSubmit = async () => {
    trackEvent({
      event: ADD_PLANT,
    })
    await addItem({
      variables: {
        item: {
          name: addItemSingleState.name,
          description: addItemSingleState.description,
          acquiredDate: addItemSingleState.acquiredDate ? moment(addItemSingleState.acquiredDate).toISOString() : null,
          originDate: addItemSingleState.originDate ? moment(addItemSingleState.originDate).toISOString() : null,
          source: addItemSingleState.source,
          tags: addItemSingleState.tags.map(tag => ({ id: tag.id, name: tag.label })),
          identification: { id: addItemSingleState.identification.id },
          coverImage: addItemSingleState.coverImage,
          waterFrequency: addItemSingleState.waterFrequency ? parseInt(addItemSingleState.waterFrequency as string) : null,
          fertilizerFrequency: addItemSingleState.fertilizerFrequency ? parseInt(addItemSingleState.fertilizerFrequency as string) : null,
          nextWater: addItemSingleState.nextWater ? moment(addItemSingleState.nextWater).toISOString() : null,
          nextFertilizer: addItemSingleState.nextFertilizer ? moment(addItemSingleState.nextFertilizer).toISOString() : null,
        }
      },
    })
  }

  const handleBulkSubmit = async () => {
    const items = addItemBulkState.map(item => ({
      name: item.name,
      description: item.description,
      acquiredDate: item.acquiredDate ? moment(item.acquiredDate).toISOString() : null,
      originDate: item.originDate ? moment(item.originDate).toISOString() : null,
      source: item.source,
      tags: item.tags.map(tag => ({ id: tag.id, name: tag.label })),
      identification: { id: item.identification.id },
      coverImage: item.coverImage,
      waterFrequency: item.waterFrequency ? parseInt(item.waterFrequency as string) : null,
      fertilizerFrequency: item.fertilizerFrequency ? parseInt(item.fertilizerFrequency as string) : null,
      nextWater: item.nextWater ? moment(item.nextWater).toISOString() : null,
      nextFertilizer: item.nextFertilizer ? moment(item.nextFertilizer).toISOString() : null,
    }))
    trackEvent({
      event: ADD_PLANTS_BULK,
      plantCount: items.length,
    })
    await addItems({
      variables: {
        items
      },
    })
  }

  // const cardTitleRenderContent = () => {
  //   return (
  //     <Flex minHeight="100%" alignItems="center">
  //       <Icon
  //         name="plus-circle"
  //         size="lg"
  //         onClick={() => setIsExpanded(true)}
  //         sx={{
  //           cursor: 'pointer',
  //           color: 'blue.5',
  //           '&:hover': { color: 'blue.3'}}}
  //       />
  //     </Flex>
  //   )
  // }
  const isExpanded = true
  return (
    <>
      <Flex
        justifyContent="space-between"
      >
        <CardTitle>Add Plant(s)</CardTitle>
        {view === 'bulk' ? (
          <Button
            variant="text"
            onClick={() => handleSwitchView('single')}
          >Switch to Single Add</Button>)
          : (
          <Button
            variant="text"
            onClick={() => handleSwitchView('bulk')}
          >
            Switch to Bulk Add
          </Button>
        )}
      </Flex>
      <Box>
        {(loading || addItemsLoading) && (
          <FlexRow>Submitting......</FlexRow>
        )}
        {statusMessage && (
          <FlexRow>{statusMessage}</FlexRow>
        )}
        {isExpanded && !isBulkAdd && (
          <AddItemSingle
            key={refreshKey}
            item={addItemSingleState}
            handleUpdate={(attribute, value) => {
              addItemSingleDispatch({
                type: 'UPDATE_ATTRIBUTE',
                attribute,
                value,
              })
            }}
            handleSubmit={handleSingleSubmit}
            handleCancel={handleCancel}
            isSubmitting={loading}
          />
        )}
        {isExpanded && isBulkAdd && (
          <AddItemBulk
            key={refreshKey}
            items={addItemBulkState}
            handleCancel={handleCancel}
            handleRemoveItem={(tempId) => {
              addItemBulkDispatch({
                type: 'REMOVE_ITEM',
                tempId
              })
            }}
            handleUpdate={(attribute, value, tempId) => {
              addItemBulkDispatch({
                type: 'UPDATE_ATTRIBUTE',
                attribute,
                value,
                tempId
              })
            }}
            handleSubmit={handleBulkSubmit}
            isSubmitting={addItemsLoading}
            setParseResults={(results) => {
              addItemBulkDispatch({
                type: 'SET_PARSE_RESULTS',
                results,
              })
            }}
          />
        )}
      </Box>
    </>
  )
}
