import { Action } from '../types/queries'

export const WATERED_ACTION = {
  "abbreviation" : "W",
  "id" : "c3728046-124b-4f01-9f9c-a750a9ffb8f1",
  "label" : "Watered",
}
export const FERTILIZED_ACTION = {
  "abbreviation" : "F",
  "id" : "6c835ea0-e3a0-4390-b208-6841f8a4db1c",
  "label" : "Fertilized",
}
export const TREATED_ACTION = {
  "abbreviation" : "T",
  "id" : "d8bc385d-b465-45a3-aae1-3d4f10be976f",
  "label" : "Treated",
}
export const entryTagOptions: Action[] = [
  {
    "abbreviation" : "S",
    "id" : "687a4df7-f395-40dc-a75f-b1ff00064837",
    "label" : "Seeded",
  },
  FERTILIZED_ACTION,
  {
    "abbreviation" : "TP",
    "id" : "87298204-b3a9-4bfb-a641-f4aa7567c5ba",
    "label" : "Transplanted",
  },
  {
    "abbreviation" : "C",
    "id" : "981b4645-0938-4f7e-a40b-6569d6f181de",
    "label" : "Cut",
  },
  WATERED_ACTION,
  {
    "abbreviation" : "H",
    "id" : "db99c719-edf5-4f7a-92f4-a6c73c727c80",
    "label" : "Harvested",
  },
  {
    "abbreviation" : "RP",
    "id" : "f498a607-c8b2-49a7-b6ed-331fca9e1abd",
    "label" : "Repotted",
  },
  {
    "abbreviation" : "P",
    "id" : "df340ca2-3590-4532-96ef-d571371594b9",
    "label" : "Planted",
  },
  TREATED_ACTION,
  {
    "abbreviation" : "A",
    "id" : "b3d6261a-184d-4ac0-bebf-9d26a81da76c",
    "label" : "Acquired",
  }
]
