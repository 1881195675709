import * as React from 'react'
import { PAGE_VIEW } from './events'
import {
  useGetCurrentUserQuery,
  GetCurrentUserQueryHookResult,
} from '../types/queries'
const mixpanel = require('mixpanel-browser')
type CurrentUser = GetCurrentUserQueryHookResult['data']['currentUser']

const ignoreIps = ['::1', '127.0.0.1', '71.105.140.105', '192.168.1.153']

type TrackingData = {
  event: string
  [key: string]: any
}

export const dispatchTrackingData = (data: TrackingData): void => {
  const { event, ...restData } = data
  mixpanel.track(event, restData)
  if (event !== PAGE_VIEW) {
    const category = data.category || data.page
    if (!category) return
    (window as any).gtag('event', event, {
      event_category: category,
      event_label: data.label
    })
  }
}

export const processTrackingData = (ownTrackingData) => {
  return ownTrackingData.page ? { event: PAGE_VIEW } : null
}

const shouldIgnoreUser = (currentUser: CurrentUser): boolean => {
  return ignoreIps.includes(currentUser.ipAddress)
}

const setAnalyticsUser = (currentUser: CurrentUser) => {
  if (!currentUser) return

  const { id, email, username } = currentUser
    mixpanel.identify(id)

    mixpanel.register({
      email,
      username,
      environment: process.env.NODE_ENV,
      $ignore: shouldIgnoreUser(currentUser),
    })
    // (window as any).gtag('set', {
    //   userId: currentUser.id
    // })
    mixpanel.people.set({
      $email: email,
      username,
    })

  try {
    (window as any).gtag('set', {
      userId: currentUser.id
    })
  } catch (e) {
    console.log('errrrrrr', e)
  }
}

export const resetAnalyticsUser = () => {
  mixpanel.reset()
}

export const Analytics = () => {
  const [userInitialized, setUserInitialized] = React.useState(false)
  const { data } = useGetCurrentUserQuery()
  const currentUser = data?.currentUser

  React.useEffect(() => {
    if (!currentUser || userInitialized) return;
    setAnalyticsUser(currentUser)
    setUserInitialized(true)
  }, [currentUser, userInitialized])
  return <span/>
}
