import * as React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import {
  useGetCurrentUserQuery,
  useConfirmEmailMutation,
} from '../../types/queries'

export const ConfirmEmailPage: React.FC<any>  = () => {
  const { code } = useParams<any>()
  const [confirmEmail, { data: confirmEmailData, loading: confirmEmailLoading }] = useConfirmEmailMutation()
  const { data: currentUserData, loading: currentUserLoading } = useGetCurrentUserQuery({
    onCompleted: async () => {
      await confirmEmail({
        variables: {
          code
        },
      })
    }
  })
  if (!currentUserLoading && !(currentUserData && currentUserData.currentUser)) {
    return <Redirect to="/login"/>
  } else if (confirmEmailData && confirmEmailData.confirmEmail && confirmEmailData.confirmEmail.success) {
    return <Redirect to="/dashboard" />
  } else if (!confirmEmailLoading && !(confirmEmailData && confirmEmailData.confirmEmail)) {
    return (
      <div>
        <h2>Error confirming email</h2>
      </div>
    )
  }
  return (
    <div>
      <h2>Confirming email....</h2>
    </div>
  )
}
