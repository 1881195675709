import * as React from 'react';
import BaseModal from 'react-modal'

BaseModal.setAppElement('#modal-root')

const addModalOpenClass = () => {
  const bodyClasses = document.getElementsByTagName('body')[0].classList;
  bodyClasses.add('modal-open')
}

const removeModalOpenClass = () => {
  const bodyClasses = document.getElementsByTagName('body')[0].classList;
  bodyClasses.remove('modal-open')
}

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(240,248,255, 0.5)',
    zIndex: 10,
  },
}

interface ModalProps {
  handleClose: () => void
  isOpen: boolean
  children: React.ReactElement | React.ReactElement[]
}
export const Modal: React.FC<ModalProps> = (props) => {
  const { children, handleClose, isOpen } = props

  React.useEffect(() => {
    if (isOpen) {
      addModalOpenClass()
    } else {
      removeModalOpenClass()
    }
    return removeModalOpenClass
  }, [isOpen])
  return (
    <BaseModal
      onRequestClose={handleClose}
      isOpen={isOpen}
      style={modalStyles}
      className="modal"
    >
      {children}
    </BaseModal>
  )
}
