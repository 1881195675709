import * as React from 'react'
import { Flex, FlexProps } from 'rebass'
import { Icon, IconProps } from '../Icon'

// TODO: user placeholder

type ImagePlaceholderProps = FlexProps & {
  iconSize?: IconProps['size']
  iconName?: IconProps['name']
} & { css?: any }

export const ImagePlaceholder: React.FC<ImagePlaceholderProps> = (props) => {
  const { iconSize = '120px', iconName = 'seedling', sx, ...restProps } = props
  return (
    <Flex
      minHeight={'300px'}
      justifyContent="center"
      alignItems="center"
      sx={{
        borderRadius: 1,
        backgroundColor: 'white',
        ...sx,
      }}
      {...restProps}
    >
      <Icon
        name={iconName}
        size={iconSize}
        color="#f0f0f0"
        sx={{
          '&:hover': {
            color: '#f0f0f0'
          }
        }}
      />
    </Flex>
  )
}