import * as React from 'react'
import {
  useGetCurrentUserQuery,
  GetCurrentUserQueryHookResult,
} from '../../types/queries'

type CurrentUser = GetCurrentUserQueryHookResult['data']['currentUser']

interface LoggedInProps {
  children?: React.ReactChild | React.ReactChildren | null
  renderLoading?: React.FunctionComponent<{}>
  renderLoggedOut?: React.FunctionComponent<{}>
  render: React.FC<{ currentUser: CurrentUser }>
}

export const LoggedIn: React.FC<LoggedInProps> = (props) => {
  const { render, renderLoading, renderLoggedOut } = props
  const { data, loading } = useGetCurrentUserQuery()

  if (loading) {
    return renderLoading(props)
  } else if (!(data && data.currentUser)) {
    return renderLoggedOut(props)
  }

  return render({ currentUser: data.currentUser })

}

LoggedIn.defaultProps = {
  renderLoading: () => null,
  renderLoggedOut: () => null
}
