export const PAGE_VIEW = 'Page view'
export const TAB_VIEW = 'Tab view'
export const SIGN_UP = 'Sign up'
export const EDIT_PROFILE = 'Edit profile'

// Dashboard
export const ADD_PLANT = 'Add plant - single'
export const ADD_PLANTS_BULK = 'Add plants - bulk'
export const ADD_ENTRY = 'Add entry'
export const EDIT_PLANT = 'Edit plant'

// Wish List
export const ADD_WISH_LIST_ID = 'Add wish list ID'

// Plant List
export const SORT_PLANT_LIST = 'Sort plant list'
export const FILTER_PLANT_LIST = 'Filter plant list'
export const CHANGE_LIST_VIEW = 'Change list view'

export const ADD_IDENTIFICATION = 'Add identification'
