const greens = {
  primary: '#52cd00',
  1: '#cbf0b2',
  3: '#97e166',
  5: '#52cd00',
  7: '#398f00',
  9: '#205200',
}

const greys = {
  med: '#b3b3b3',
  1: '#f0f0f0',
  3: '#b3b3b3',
  5: '#7d7d7d',
  7: '#474747'
}

const blues = {
  1: '#F0F8FF',
  // 5: '#58B0FF',
  5: '#4f9ee5',
  7: '#0072d8',
  9: '#004a8c',
}

export const SMALL_BREAKPOINT = 640

export const theme = {
  breakpoints: [ `${SMALL_BREAKPOINT}px`, '832px', '1024px' ],

  colors: {
    green: greens,
    grey: greys,
    blue: blues,

  },

  fonts: {
    display: 'Overpass, sans-serif',
    body: 'Roboto, Helvetica, sans-serif',
    data: 'Inconsolata'
  },

  fontSizes: ['10px', '12px', '16px', '20px', '24px', '30px'],

  text: {
    h1: {
      fontSize: [4, 4, 5],
      fontFamily: 'display'
    },
    h2: {
      fontSize: 3,
      fontFamily: 'display'
    },
    h3: {
      fontSize: 2,
      fontFamily: 'body'
    },
    error: {
      fontFamily: 'body',
      color: 'red',
      px: 2,
      display: 'inline-block'
    },
    actionTag: {
      borderColor: 'grey.7',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: 1,
      fontSize: [1, 2],
      color: 'grey.7',
      mb: 1,
      mr: 2,
      px: 3,
      py: 2,
    }
  },

  shadows: {
    none: 'none',
    link: '1px 1px #58B0FF',
    card: '2px 2px #c2c2c2',
    left: '-7px 0px 7px rgba(0, 0, 0, 0.07)'
  },

  borders: {
    none: 'none',
    hairline: '0.5px solid black',
    standard: '1px solid lightgrey',
    focus: '1px solid black',
    error: '1px solid #ff4444',
    link: '1px solid #58B0FF',
    bold: '1px solid lightgrey'
  },

  cards: {
    primary: {
      borderWidth: '1px',
      borderRadius: '3px',
      borderStyle: 'solid',
      borderColor: 'lightgrey',
      backgroundColor: 'white',
      marginBottom: 5
    },
    secondary: {
      borderWidth: '1px',
      // boxShadow: 'card',
      borderRadius: '3px',
      borderStyle: 'solid',
      borderColor: 'lightgrey'
    },
    well: {
      backgroundColor: 'blue.1',
      borderWidth: '1px',
      borderRadius: '3px',
      borderStyle: 'solid',
      borderColor: 'lightgrey',
    }
  },

  buttons: {
    primary: {
      backgroundColor: 'blue.5',
      color: 'white',
      px: 3,
      py: 2,
      cursor: 'pointer',
      '&:hover:not(:disabled)': {
        backgroundColor: 'blue.7'
      },
      '&:disabled': {
        backgroundColor: 'grey.5',
        cursor: 'default'
      },
      fontSize: 1
    },
    cancel: {
      backgroundColor: 'white',
      borderColor: 'grey.3',
      borderWidth: '2px',
      borderStyle: 'solid',
      color: 'grey.5',
      px: 3,
      py: 2,
      cursor: 'pointer',
      fontSize: 1
    },
    text: {
      backgroundColor: 'transparent',
      borderWidth: 0,
      color: 'blue.5',
      cursor: 'pointer',
      '&:hover': {
        color: 'blue.7'
      }
    }
  },

  icons: {
    button: {
      color: 'blue.5',
      cursor: 'pointer',
      '&:hover': {
        color: 'blue.7'
      }
    },
    buttonDisabled: {
      color: 'grey.3',
      cursor: 'default',
    },
    cancelButton: {
      backgroundColor: 'white',
      color: 'grey.5',
      cursor: 'pointer',
      '&:hover': {
        color: 'grey.7'
      }
    },
  },

  borderWidths: [0, 0.5, 1, 2],

  radii: [0, 3, 5, 10, 99999],

  space: [0, 3, 5, 10, 15, 20],

  forms: {
    input: {
      backgroundColor: 'white',
      border: 'standard',
      borderRadius: 1,
      '&:focus': {
        border: 'focus'
      }
    },
    textarea: {
      backgroundColor: 'white',
      border: 'standard',
      borderRadius: 1,
      '&:focus': {
        border: 'focus'
      }
    },
    errorInput: {
      border: 'error'
    },
    searchInput: {
      borderRadius: 4,
      border: 'standard',
      fontSize: 1,
      p: 2
    },
    label: {
      borderColor: 'blue.5',
      borderWidth: ['1px', '2px'],
      borderStyle: 'solid',
      borderRadius: 1,
      color: 'blue.5',
      mb: 1,
      px: 3,
      py: 2,
      cursor: 'pointer',
      '&:hover:not(:disabled)': {
        borderColor: 'blue.7',
        color: 'blue.7',
      },
      '&:disabled': {
        backgroundColor: 'grey.5',
        cursor: 'default'
      },
      fontSize: [1, 2]
    },
    labelChecked: {
      borderColor: 'blue.5',
      borderWidth: ['1px', '2px'],
      borderStyle: 'solid',
      backgroundColor: 'blue.1',
      borderRadius: 1,
      color: 'blue.5',
      px: 3,
      py: 2,
      mb: 1,
      cursor: 'pointer',
      '&:hover:not(:disabled)': {
        borderColor: 'blue.7',
        color: 'blue.7',
      },
      '&:disabled': {
        backgroundColor: 'grey.5',
        cursor: 'default'
      },
      fontSize: [1, 2]
    },
  },

  links: {
    primary: {
      textDecoration: 'none',
      cursor: 'pointer',
      color: 'blue.7',
      display: 'inline-block',
      '& a:active, & a:visited': {
        color: 'blue.7'
      },
      '& a:hover': {
        color: 'blue.9'
      },
    },
    nav: {
      color: 'black',
      fontFamily: 'display',
    },
    navActive: {
      color: 'blue.9',
      fontFamily: 'display',
    },
    breadcrumb: {
      color: 'black',
      display: 'inline-block',
      fontFamily: 'display',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    tab: {
      fontFamily: 'display',
      cursor: 'pointer',
      display: 'inline-block',
      fontSize: [2, 2, 3],
      color: 'blue.7',
      '&:hover': {
        color: 'blue.5'
      },
      ml: 3,
      '&.selected': {
        cursor: 'default',
        color: 'blue.9',
        '&:hover': {
          color: 'blue.9'
        },
      }
    }
  }
}

export type Theme = typeof theme
