import React from 'react'
import { Box, Flex } from 'rebass'
import { useWindowWidth } from '@react-hook/window-size'
import { Card } from '../Card'
import { Icon } from '../Icon'
import { SMALL_BREAKPOINT } from '../../theme'
import { Modal } from '../Modal'

export const Content = (props) => (
  <Box
    display="inline-block"
    backgroundColor="white"
    width={[1]}
    {...props}
  />
)

export const PageContent = (props) => (
  <Box
    width={[1]}
    maxWidth={1600}
    {...props}
  />
)


export const TitleWrapper = (props) => {
  return (
    <Flex
      {...props}
      backgroundColor="white"
      justifyContent="center"
      alignItems="center"
      width={[1]}
      sx={{
        display: ['none', 'flex', 'flex']
      }}
    />
  )
}
export const MobileTitleWrapper = (props) => {
  return (
    <Flex
      {...props}
      backgroundColor="white"
      justifyContent="center"
      alignItems="center"
      width={[.5, .15]}
      maxWidth={['100%', '20%']}
      display={['flex', 'none', 'none']}
    />
  )
}

export const Nav = (props) => (
  <Box
    {...props}
  />
)
export const MobileNav = (props) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    display={['flex', 'none', 'none']}
    {...props}
    sx={{
      height: '100%',
      position: 'relative',}}
  />
)

export const InnerBar = (props) => {
  return (
    <Card
      width={[1, .2, .2]}
      fontSize={[1, 1, 2]}
      variant="well"
      display={['none', 'none', 'block']}
      pt={2}
      pb={5}
      sx={{
        border: 'none',
        maxWidth: '200px',
        maxHeight: '100%',
        overflowY: 'scroll'
      }}
      {...props}
    />
  )
}

interface PanelProps {
  isOpen?: boolean
  handleClose?: () => void
  children: React.ReactElement | React.ReactElement[]
}
export const Panel: React.FC<PanelProps> = (props) => {
  const { isOpen = false, handleClose, children, ...restProps } = props
  const windowWidth = useWindowWidth()

  const showAsModal = windowWidth < SMALL_BREAKPOINT

  if (isOpen && showAsModal) {
    return (
      <Modal isOpen={isOpen} handleClose={handleClose}>
        {children}
      </Modal>
    )
  }

  return (
    <Flex
      backgroundColor="white"
      fontSize={[1, 1, 2]}
      flexDirection="column"
      width={1}
      sx={{
        zIndex: 12,
        position: 'fixed',
        borderLeft: 'standard',
        right: 0,
        top: 0,
        bottom: 0,
        maxWidth: isOpen ? ['100vw', '40vw', '40vw'] : [0, 0, 0],
        minHeight: '100vh',
        overflowY: 'scroll',
        transition: 'max-width 300ms',
        boxShadow: 'left',
      }}
      {...restProps}
    >
      <Flex><Icon name="x" onClick={() => handleClose()}/></Flex>
      {isOpen && children}
    </Flex>
  )
}
