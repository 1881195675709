import * as React from 'react'
import { Flex, Text } from 'rebass'

export const LoadingEllipsisText: React.FC<{ text?: string }> = (props) => {
  const { text = 'Loading' } = props
  return (
    <Flex
      width="100%"
      justifyContent="center"
      p={3}
    >
      <Text fontFamily="display" className="loading">
        {text}
      </Text>
    </Flex>
  )
}
