import * as React from 'react'
import { Flex, Text, Button } from 'rebass'
import { Input } from '@rebass/forms'
import { Card } from '../../components/Card'
import {
  useGetIdentificationsQuery,
} from '../../types/queries'
import papa from 'papaparse'
import { EditItem, IEditItem } from '../../components/EditItem'
import { parseCsvResults } from './parse-bulk-csv'
import { Dropzone } from '../../components/Dropzone'

const templateSheetUrl = 'https://greenrie.s3.amazonaws.com/resources/Greenrie_Bulk_Import_Template.xlsx'

export type AddItemBulkState = IEditItem[]

interface AddItemBulkProps {
  handleRemoveItem: (tempId: string) => void
  handleCancel: () => void
  handleSubmit: () => void
  handleUpdate: (attribute: string, value: any, tempId: string) => void
  isSubmitting: boolean
  items: AddItemBulkState
  setParseResults: (results: IEditItem[]) => void
}

export const AddItemBulk: React.FunctionComponent<AddItemBulkProps> = (props) => {
  const [isParsing, setIsParsing] = React.useState(false)
  const [displayErrors, setDisplayErrors] = React.useState(false)
  const { data } = useGetIdentificationsQuery()
  const { handleSubmit, isSubmitting,
    handleUpdate, setParseResults, items, handleRemoveItem, handleCancel } = props
  const identifications = (data && data.identifications) || []

  const handleFileInputChange = (files: File[]) => {
    setIsParsing(true)
    papa.parse(files[0], {
      header: true,
      dynamicTyping: true,
      complete: (results) => {
        const parsedResults = parseCsvResults(results, identifications as any)
        setIsParsing(false)
        setParseResults(parsedResults)
      }
    })
  }

  const errorMessages = []
  const noItemsAdded = !items.length
  const allItemsHaveName = items.every(item => !!item.name)
  if (!allItemsHaveName) errorMessages.push('All plants must have Names.')
  const allItemsHaveIdentification = items.every(item => !!item.identification &&
    item.identification.id !== 'new')
  if (!allItemsHaveIdentification) errorMessages.push('All plants must have Identifications.')
  const isSubmitDisabled = !allItemsHaveName || !allItemsHaveIdentification || isSubmitting

  const onSubmit = () => {
    if (isSubmitDisabled) {
      setDisplayErrors(true)
    } else {
      handleSubmit()
    }
  }
  return (
    <>
      {noItemsAdded && (
        <Flex
          flexDirection="column"
          width={0.6}
          alignItems="center"
          mx="auto"
          sx={{ textAlign: 'center' }}
        >
          <Text variant="h2" mb={3}>Bulk Add Instructions</Text>
          <Text mb={3}>1. <Button variant="text" onClick={() => window.open(templateSheetUrl, '_blank')}>Download our
            template (.xlsx)</Button></Text>
          <Flex
            flexDirection="column"
            alignItems="center"
            mb={3}
          >
            <Text>2. Fill out template in your spreadsheet editor of choice, adding one row per plant.</Text>
            <Text color="grey.5"><b>Genus</b> and <b>Name</b> are required, but all other columns are optional.</Text>
            <Text color="grey.5">Please <b>do not</b> alter the column headers while editing!</Text>
          </Flex>
          <Text mb={3}>3. Export or download your edited spreadsheet as a CSV file</Text>
          <Flex
            flexDirection="column"
            alignItems="center"
            mb={3}
          >
            <Text>4. Upload your CSV below!</Text>
            <Text color="grey.5">You'll have a chance to review your plants, make edits, and add images before
              saving</Text>
          </Flex>
          <Flex p={4} justifyContent="center">
            <Dropzone
              onDrop={(acceptedFiles) => handleFileInputChange(acceptedFiles)}
              accept=".csv"
              text="Drap and drop CSV here or click to select file"
            />
          </Flex>
        </Flex>
      )}
      <Flex justifyContent="center" p={2}>
        {isParsing && 'Loading and parsing CSV...'}
      </Flex>
      {!!items.length && (
        <Text m={2}>{`Review ${items.length} parsed plants:`}</Text>
      )}
      {items.map((item) => (
        <Card>
          <EditItem
            key={item.tempId}
            item={item}
            handleUpdateAttribute={(attr, value) => handleUpdate(attr, value, item.tempId)}
            displayErrors={displayErrors}
            isRemovable={true}
            handleRemove={() => {
              handleRemoveItem(item.tempId)
            }}
          />
        </Card>
      ))}
      <Flex
        justifyContent="flex-end"
        alignItems="center"
        p={3}
      >
        {displayErrors && !!errorMessages.length && (
          <Text variant="error">{errorMessages.join(' ')}</Text>
        )}
        <Button
          variant="cancel"
          onClick={handleCancel}
          mr={3}
        >Cancel</Button>
        <Button
          variant="primary"
          disabled={isSubmitting || noItemsAdded}
          onClick={onSubmit}
        >Save</Button>
      </Flex>
    </>
  )
}
