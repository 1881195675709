import * as React from 'react'
import { Flex, Text } from 'rebass'
import { Checkbox } from '@rebass/forms'
import Select from 'react-select'
import { useTracking } from 'react-tracking'
import {
  Genus,
  Item,
  Tag as TagType,
} from '../../types/queries'
import { FILTER_PLANT_LIST } from 'analytics/events'

type FilterableKey = 'tags' | 'genera'
type Filtered = { [key in FilterableKey]: string[] }

interface AddFilter {
  type: 'ADD_FILTER'
  key: FilterableKey
  id: string
}
interface RemoveFilter {
  type: 'REMOVE_FILTER'
  key: FilterableKey
  id: string
}
interface RemoveAllFilters {
  type: 'REMOVE_ALL_FILTERS'
}

type FilterAction = AddFilter | RemoveFilter | RemoveAllFilters
const filterReducer = (state: Filtered, action: FilterAction): Filtered => {
  const { type } = action
  if (type === 'ADD_FILTER') {
    const { key, id } = action as AddFilter
    return {
      ...state,
      [key]: [...state[key], id]
    }
  } else if (type === 'REMOVE_FILTER') {
    const { key, id } = action as RemoveFilter
    return {
      ...state,
      [key]: state[key].filter(filterId => filterId !== id)
    }
  } else if (type === 'REMOVE_ALL_FILTERS') {
    return { tags: [], genera: [] }
  }
  return state
}

interface UseFilterReducerResult {
  filtered: Filtered
  actions: {
    addFilter: (key: FilterableKey, id: string) => void
    removeFilter: (key: FilterableKey, id: string) => void
    removeAllFilters: () => void
  }
}

export const useFilterReducer = (): UseFilterReducerResult => {
  const [state, dispatch] = React.useReducer(filterReducer, {
    genera: [],
    tags: [],
  })
  const addFilter = React.useCallback((filterableKey, filteredId) => {
    return dispatch({
      type: 'ADD_FILTER',
      key: filterableKey,
      id: filteredId
    })
  }, [dispatch])
  const removeFilter = React.useCallback((filterableKey, filteredId) => {
    return dispatch({
      type: 'REMOVE_FILTER',
      key: filterableKey,
      id: filteredId
    })
  }, [dispatch])
  const removeAllFilters = React.useCallback(() => {
    return dispatch({
      type: 'REMOVE_ALL_FILTERS',
    })
  }, [dispatch])

  return {
    filtered: state,
    actions: { addFilter, removeFilter, removeAllFilters }
  }
}

export const getFilteredItems = (items: Item[], filters: Filtered) => {
  let visibleItems = items
  if (filters.tags.length) {
    visibleItems = visibleItems.filter(item => {
      return filters.tags.every(filterTagId => {
        return item.tags.some(itemTag => {
          return itemTag.id === filterTagId
        })
      })
    })
  }
  if (filters.genera.length) {
    visibleItems = visibleItems.filter(item => {
      const itemGenus = item.identification?.genus
      return itemGenus && filters.genera.some(genusId => {
        return genusId === itemGenus.id
      })
    })
  }
  return visibleItems
}

interface FilterManagerProps {
  genera?: Genus[]
  tags?: TagType[]
  addFilter: (filterableKey: FilterableKey, filteredId: string) => void
  removeFilter: (filterableKey: FilterableKey, filteredId: string) => void
  removeAllFilters?: () => void
  filtered: Filtered
}

export const FilterManagerI: React.FC<FilterManagerProps> = (props) => {
  const { tags = [], genera = [], filtered, addFilter, removeFilter } = props
  const { tags: filteredTagIds = [], genera: filteredGenusIds = [] } = filtered
  return (
    <>
      <Text variant="h4" m={2}>Filters</Text>
      <Text sx={{ display: 'block', m: 2 }}>Tags</Text>
      {tags.map(tag => {
        const isSelected = filteredTagIds.includes(tag.id)
        return (
          <Flex key={tag.id} alignItems="center" minHeight="fit-content">
            <Flex
              width="50px"
              onClick={isSelected ? () => removeFilter('tags', tag.id) : () => addFilter('tags', tag.id)}
              justifyContent="center"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
            >
              <Checkbox
                checked={isSelected}
              />
            </Flex>
            <Text>
              {tag.name}
            </Text>
          </Flex>
        )
      })}
      <Text sx={{ display: 'block', m: 2 }}>Genera</Text>
      {genera.map(genus => {
        const isSelected = filteredGenusIds.includes(genus.id)
        return (
          <Flex key={genus.id} alignItems="center" minHeight="fit-content">
            <Flex
              width="50px"
              onClick={isSelected ? () => removeFilter('genera', genus.id) : () => addFilter('genera', genus.id)}
              justifyContent="center"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
            >
              <Checkbox
                checked={isSelected}
              />
            </Flex>
            <Text>
              {genus.name}
            </Text>
          </Flex>
        )
      })}
    </>
  )
}

export const FilterManager: React.FC<FilterManagerProps> = (props) => {
  const { tags = [], genera = [], filtered, addFilter, removeFilter, removeAllFilters = () => {} } = props
  const { tags: filteredTagIds = [], genera: filteredGenusIds = [] } = filtered
  const { trackEvent } = useTracking()

  const filteredTags = filteredTagIds.map(id => tags.find(tag => tag.id === id))
  const filteredGenera = filteredGenusIds.map(id => genera.find(genus => genus.id === id))

  const selected: Array<TagType | Genus> = [...filteredTags, ...filteredGenera]

  const handleChange = ({ action, option, removedValue }) => {
    if (action === 'select-option') {
      const filterKey = option.__typename === 'Tag' ? 'tags' : 'genera'
      trackEvent({
        event: FILTER_PLANT_LIST,
        label: `${option.__typename}: ${option.name}`,
      })
      return addFilter(filterKey, option.id)
    } else if (action === 'remove-value') {
      const filterKey = removedValue.__typename === 'Tag' ? 'tags' : 'genera'
      return removeFilter(filterKey, removedValue.id)
    } else if (action === 'clear') {
      return removeAllFilters()
    }
  }
  return (
    <Select
      isMulti
      name="filter-select"
      value={selected}
      options={[{ label: 'Tags', options: tags}, { label: 'Genera', options: genera }]}
      getOptionValue={opt => opt.id}
      getOptionLabel={opt => opt.name}
      onChange={(value, actionType) => {
        console.log(value, actionType)
        handleChange(actionType as any)
      }}
      placeholder="Select Tags or Genera..."
      styles={{
        container: (styles) => ({ ...styles, width: '100%' }),
        menu: (styles) => ({ ...styles }),
        control: (styles) => ({ ...styles, minHeight: 'fit-content', fontSize: '12px' }),
        clearIndicator: (styles) => ({ ...styles, padding: 0 }),
        dropdownIndicator: (styles) => ({ ...styles, padding: 0 }),
        option: (styles) => ({ ...styles, fontSize: '12px' }),
      }}
    />
  )
}
