import * as React from 'react'
import { FlexProps } from 'rebass'
import { Flex } from 'rebass'

export const FlexRow: React.FunctionComponent<FlexProps & { css?: any }> = (props) => {
  return (
    <Flex
      px={3}
      {...props}
      sx={{
        flexWrap: 'wrap',
        '&:not(:last-child)': {
          marginBottom: 3
        }
      }}
    />
  )
}

type LabelColumnProps = {
  required?: boolean
} & FlexProps
export const LabelColumn: React.FunctionComponent<LabelColumnProps & { css?: any }> = ({ required, ...restProps}) => {
  return (
    <Flex
      {...restProps}
      width={[1]}
      alignItems="center"
      justifyContent={["flex-start"]}
      sx={{
        // borderRight: ['none', 'standard'],
        padding: 1,
        textAlign: 'right',
        '&:before': {
          content: required ? '"\\002A"' : '""',
          color: 'red',
          padding: 1
        }
      }}
    />
  )
}
LabelColumn.defaultProps = {
  required: false
}

export const ValueColumn: React.FunctionComponent<FlexProps & { css?: any }> = (props) => {
  return (
    <Flex
      {...props}
      width={[1]}
    />
  )
}
